import React, { Component } from 'react';
import classNames from 'classnames';
import fetch from 'cross-fetch';

import Cloak from './../Common/Cloak';

const API_UPLOAD_CERT = '/TaxExemptCertificate/UploadCert';

class CertUpload extends Component {

  constructor(props) {
    super(props);
    this.fileFieldRef = React.createRef();
    this.state = {
      isLoading: false,
      fileIsTooBig: false,
      jobName: props.jobName ? props.jobName : '',
    }
  }


  onJobNameChange = (e) => {
    this.setState({
      jobName: e.target.value,
    })
  }

  onUploadButtonClick = () => {
    const {
      uploadCallback,            
    } = this.props;

    const {
      jobName,
    } = this.state;

    let files = this.fileFieldRef.current.files;

    if (files.length) {
      this.setState({
        isLoading: true,
      })
      const body = new FormData();
      body.append('CertFile', files[0]);
      body.append('jobName', jobName);

      fetch(API_UPLOAD_CERT, {
        method: 'POST',
        body
      }).then(res => res.status == 200 ? res.json() : null).then((data) => {
        if (typeof uploadCallback === 'function') {
          uploadCallback(data.Success, data.RecordId, data.Message, jobName);
        }
        this.setState({
          isLoading: false,
        });
      });

    } else {
      return;
    }

  }

  onFilesChange = (e) => {
    const files = this.fileFieldRef.current.files;
    if (files && files.length) {
      this.setState({
        fileIsTooBig: files[0].size > 30000000
      })
    } else {
      this.setState({
        fileIsTooBig: false,
      })
    }
  }

  render() {

    const {
      labels = {},
      success,
      message,
    } = this.props;

    const messageClass = classNames('checkout-tax-cert-selection-form__upload-message', {
      'checkout-tax-cert-selection-form__upload-message--error': !success,
    });

    const customMessage = this.state.isLoading ? 'Uploading...' : this.state.fileIsTooBig ? 'File too large. Please upload a certificate that is smaller than 30 mb' : '';

    return (
      <Cloak active={this.state.isLoading}>
        {message ? (
          <p className={messageClass}>{customMessage ? customMessage : message}</p>
        ) : null}
        
        <div className="checkout-tax-cert-selection-form__upload-form">
          <input 
            className="checkout-tax-cert-selection-form__upload-file-input"
            placeholder={labels.fileInputPlaceholder}
            type="file" 
            accept=".pdf"
            onChange={this.onFilesChange}
            ref={this.fileFieldRef}/>
          <button 
            disabled={this.state.fileIsTooBig}
            className="checkout-tax-cert-selection-form__upload-submit-button"
            onClick={this.state.fileIsTooBig ? () => {} : this.onUploadButtonClick}
          >{labels.uploadButton}</button>
          <input 
            value={this.state.jobName} 
            onChange={this.onJobNameChange} 
            placeholder="Job name"
            className="checkout-tax-cert-selection-form__job-name-input"/>
        </div>
      </Cloak>
    );
  }
}

export default CertUpload;