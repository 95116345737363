import React, { Component } from 'react';
import RadioGroup from './../Common/RadioGroup';
import RadioButton from './../Common/RadioButton';
import Select, { Option, SelectPropTypes } from 'rc-select';

import {
  commonGet,
} from '../../apiHelper';

import CertUpload from './CertUpload';

const NONE_OPTION = 'none';
const UPLOAD_OPTION = 'upload';
const SELECT_OPTION = 'select';
const API_GET_CERT_LIST = '/TaxExemptCertificate/GetCertList';

const CertUploaded = ({
  jobName,
  onUploadNewCertButtonClick,
  message,
}) => (
  <div>
    <p>{message}</p>
    <p>Job Name: {jobName}</p>
    <button onClick={onUploadNewCertButtonClick}>Upload another certificate</button>
  </div>
)

class CheckoutTaxCertSelectionForm extends Component {
  constructor(props) {
    super(props);

    const {
      initialData = {},
    } = this.props;

    this.state = {
      selectedOption: initialData.selectedOption ? initialData.selectedOption : NONE_OPTION,
      selectedCert: initialData.selectedCert ?  initialData.selectedCert : '',
      uploadedCertJobName: initialData.uploadedCertJobName ?  initialData.uploadedCertJobName : '',
      uploadedCertRequest: initialData.uploadedCertRequest ?  initialData.uploadedCertRequest : '',
      uploadNewCertState: false,
      certList: [],
      continueButton: null,
    }


    if (typeof document != 'undefined' ) {
      this.updateSelectedInput();
      this.updateOptionInput();
      this.updateRequestInput();      
    }

  }

  componentDidMount = () => {
    this.loadCertList();

    if (document) {
      const continueButton = document.getElementById('checkoutSavePaymentButton');
      this.setState({
        continueButton,
      });
    }
  }

  updateContinueButton = () => {
    const {
      selectedOption,
      selectedCert,
      uploadedCertRequest,
      uploadNewCertState,
      continueButton,
    } = this.state;

    if (continueButton) {
      let disabled = false;
      switch(selectedOption) {
        case UPLOAD_OPTION:
          disabled = !uploadedCertRequest;
          break;
        case SELECT_OPTION:
          disabled = !selectedCert;
          break;
        case NONE_OPTION:
          disabled = false;
          break;
        default: 
          break;
      }

      continueButton.disabled = disabled;
    } else {
      console.warn('Tax Cert Form: Save payment button is missed.')
    }
  }
  
  loadCertList = () => {
    commonGet(API_GET_CERT_LIST).then((data) => {      
      this.setState(data);
    });
  }

  onOptionChange = (value) => {
    this.setState({
      selectedOption: value,
      selectedCert: value === NONE_OPTION ? '' : this.state.selectedCert,
    }, () => {
      this.updateOptionInput();
      this.updateContinueButton();
    });
  }

  onCertSelect = (selectedCert) => {
    this.setState({
      selectedCert
    }, () => {
      this.updateSelectedInput();
      this.updateContinueButton();
    });
  }

  updateOptionInput = () => {
    const certOptionField = document.getElementById('TaxCertificateOption');

    if (certOptionField) {
      certOptionField.value = this.state.selectedOption;
    }
  }

  updateSelectedInput = (clear) => {
    const selectedCertField = document.getElementById('TaxCertificateId');

    if (selectedCertField) {
      selectedCertField.value = clear ? '' : this.state.selectedCert;
    }
  }

  onFileUploaded = (uploadStatus, id, uploadMessage, jobName) => {

    console.log(uploadStatus, id, uploadMessage, jobName);
    this.setState({
      uploadedCertJobName: jobName,
      uploadedCertRequest: id,
      uploadMessage,
      uploadStatus,
    }, () => {
      this.updateRequestInput();
      this.updateContinueButton();
      console.log(this.state);
    });
  }

  updateRequestInput = () => {
    const uploadedCertField = document.getElementById('TaxCertificateRequestId');
    const jobNameField = document.getElementById('TaxCertificateJobName');

    if (uploadedCertField) {
      uploadedCertField.value = this.state.uploadedCertRequest;
    }
    if (jobNameField) {
      jobNameField.value = this.state.uploadedCertJobName;
    }

  }

  renderActions = () => {

    const {
      labels = {},
    } = this.props;

    switch(this.state.selectedOption) {
      case UPLOAD_OPTION:
        return !this.state.uploadedCertRequest || this.state.uploadNewCertState ? (
          <CertUpload
            jobName={this.state.uploadedCertJobName}
            uploadCallback={this.onFileUploaded}
            message={this.state.uploadMessage ? this.state.uploadMessage : '.PDF Format Only'}
            success={this.state.uploadStatus}
            labels={labels}
          />
        ) : (
          <CertUploaded
            message={this.state.uploadMessage}
            jobName={this.state.uploadedCertJobName}
            onUploadNewCertButtonClick={() => {
              this.setState({
                uploadNewCertState: true,
              })
            }}
          />
        );
      case SELECT_OPTION:
        return this.state.certList.length ? (
            <Select
            value={this.state.selectedCert ? this.state.selectedCert : undefined }
            onChange={this.onCertSelect}
            optionFilterProp="children"
            optionLabelProp="children"
            placeholder={labels.selectPlaceholder}
            showSearch={false}
            showArrow={true} >
            {this.state.certList.map((o, i) => (
              <Option key={i} value={o.TaxExemptCertificateId}>{o.JobName}</Option>
            ))}
          </Select>
          ) : null;
      default:
        return null;
    }
  }
  
  render() {

    const {
      labels = {},
    } = this.props;

    return (
      <div className="checkout-tax-cert-selection-form">
        <div className="checkout-tax-cert-selection-form__column">
          <RadioGroup 
            onChange={this.onOptionChange}
            marginLess>
            <RadioButton 
              value={NONE_OPTION}
              selected={this.state.selectedOption === NONE_OPTION}
              >{labels.noCertRadio}</RadioButton>
            <RadioButton 
              value={UPLOAD_OPTION}
              selected={this.state.selectedOption === UPLOAD_OPTION}
              >{labels.uploadCertRadio}</RadioButton>
            <RadioButton 
              value={SELECT_OPTION}
              selected={this.state.selectedOption === SELECT_OPTION}
              >{labels.selectCertRadio}</RadioButton>
          </RadioGroup>
        </div>
        <div className="checkout-tax-cert-selection-form__column">
          {this.renderActions()}
        </div>
      </div>
    );
  }
}

export default CheckoutTaxCertSelectionForm;