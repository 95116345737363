import React, {Fragment} from 'react';

const StyleInfo = ({
  ImageUrl, 
  DisplayName,
  IsTBDColor,
}) => (
  <div className="cart-sku-content__style">
    <h4 className="cart-sku-content__subheading">Style</h4>
    {IsTBDColor ? (
      <p>TBD</p>
    ) : (
      <Fragment>
        <p>{DisplayName}</p>
        <div className="cart-sku-content__color-image">
            <img src={`${ImageUrl}?width=125&height=125&mode=crop`} alt="style"/>
        </div>
      </Fragment>
    )}
  </div>
);

export default StyleInfo;