import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import InproProvider from './../../InproProvider';

import Part from './Part';
import EstimateFreightForm from './Form';

import {
  estimateFreight,
  // selectFreightOption,
  // selectFreightSubOption,
} from '../../../actions/quoteDetailsPageActionCreators';

class QuoteDetailsShippingMethod extends Component {

  constructor(props) {
    super(props);
  }

  onEstimateFreightSubmit = (zip) => {
    const {
      quote,
      estimateFreight,
      } = this.props;            

    estimateFreight(quote.QuoteId, zip);
  }

  render() {

    const {
      quote,
      labels,
      freight,
      selectOption,
      estimateFreight,
      selectFreightSubOption,
    } = this.props;

    return (
      <Fragment>
        <h4 className="estimate-freight__header">{labels.estimateFreightHeader}</h4>
        <p className="estimate-freight__description">{labels.estimateFreightDescription}</p>
        <EstimateFreightForm onFormSubmit={this.onEstimateFreightSubmit} labels={labels}/>
        <ul className="checkout-split-shipping">
          {freight.map((p, i) => (
            <Part 
              key={i} 
              partIndex={i}
              {...p} 
              // onOptionSelect={selectOption}
              // onSubOptionCheck={selectFreightSubOption}
              />
          ))}
        </ul>
      </Fragment>
    );

  }

}

const mapProps = (state) => {
  return {
    quote: state.quoteDetailsPage.quote,
    freight: state.quoteDetailsPage.freight,
    settings: state.quoteDetailsPage.settings,
  };
}

const mapDispatch = (dispatch) => {
  return {
    estimateFreight: (quoteId, zip) => dispatch(estimateFreight(quoteId, zip)),
    // selectOption: (partIndex, optionIndex) => dispatch(selectFreightOption(partIndex, optionIndex)),
    // selectFreightSubOption: (partIndex, optionIndex, subOptionIndex) => dispatch(selectFreightSubOption(partIndex, optionIndex, subOptionIndex)),
  };
}

QuoteDetailsShippingMethod = connect(mapProps, mapDispatch)(QuoteDetailsShippingMethod);

export default (props) => (
  <InproProvider>
    <QuoteDetailsShippingMethod {...props}/>
  </InproProvider>
);