import React, { Component } from 'react';
import Order from './Order';
import TableHead from './TableHead';

class OrderHistoryOrders extends Component {
  render() {

    const {
      orders,
      sortBy,
      sortDirectionDown,
      onHeadingClick,
      labels,
      isInternational,
    } = this.props;

      const sortMethod = sortBy === 'PurchaseOrder' || sortBy === 'ConsolidateOrder' ? function (a, b) {

          var aValue = "";
          var bValue = "";
          if (a[sortBy]) {
              aValue = a[sortBy].toUpperCase(); // ignore upper and lowercase
          }
          if (b[sortBy]) {
              bValue = b[sortBy].toUpperCase(); // ignore upper and lowercase
          }

          if (sortBy === 'ConsolidateOrder') {
              if (a['ConsolidateNumber']) {
                  aValue = a['ConsolidateNumber'].toUpperCase(); // ignore upper and lowercase
              }
              if (b['ConsolidateNumber']) {
                  bValue = b['ConsolidateNumber'].toUpperCase(); // ignore upper and lowercase
              }
              
             
          }
           
         
      if (aValue > bValue) {
        return sortDirectionDown ? -1 : 1;
      }
      if (aValue < bValue) {
        return sortDirectionDown ? 1 : -1;
      }
    
      // names must be equal
      return 0;
    } : (a, b) => {

      return sortDirectionDown ? b[sortBy] - a[sortBy] : a[sortBy] - b[sortBy];
    };

    const sortedOrders = orders.sort(sortMethod);

    return (
      <div>
        <table className="order-history-table" style={{width: '100%'}}>
          <TableHead 
            sortBy={sortBy} 
            sortDirectionDown={sortDirectionDown}
            onHeadingClick={onHeadingClick}
            isInternational={isInternational}
            {...labels}/>
          <tbody>
            {sortedOrders.map((o, i) => (
              <Order
                isInternational={isInternational}
                key={i}
                {...o}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default OrderHistoryOrders;
