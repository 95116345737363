import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchResultsListWrapper extends Component {
  render () {

    const {
      heading,
      children,
    } = this.props;

    return (
      <div className="search-results__wrapper">
        <div className="search-result__header">
          {heading.map((h, i) => (<span key={i}>{h}</span>))}            
        </div>
        <div className="search-results__list">
          {children}
        </div>
      </div>
    );
  }
}

SearchResultsListWrapper.propTypes = {
  heading: PropTypes.arrayOf(PropTypes.string),
};

export default SearchResultsListWrapper;