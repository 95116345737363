import React from 'react';
import RadioGroup from '../Common/RadioGroup';
import RadioButton from '../Common/RadioButton';

export default ({
  options,
  selectedValue,
  onSelect,
}) => (
  <RadioGroup horizontal onChange={onSelect}>
    {options.map((o, i) =>(
      <RadioButton 
        key={i}
        selected={o.Value === selectedValue}
        value={o.Value}        
        >{o.Text}</RadioButton>
    ))}
  </RadioGroup>
);