import fetch from 'cross-fetch';
import React, { Component } from 'react';
import Wrapper from '../../Common/SearchResults/Wrapper';
import Stats from '../../Common/SearchResults/Stats';
import ListWrapper from '../../Common/SearchResults/ListWrapper';
import GroupedDocument from '../../Common/SearchResults/GroupedDocument';
import LoadMoreButton from '../../Common/SearchResults/LoadMoreButton';

class Document extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
        }
    }

    onLoadMore = (e) => {
        e.preventDefault();

        this.setState({
            page: this.state.page + 1
        });
    }

    render() {
        let labels = this.props.labels;
        let total = this.props.Total;
        let documents = this.props.Documents.slice(0, 10 * this.state.page);

        return (
            <Wrapper>
                <Stats total={total} count={documents.length} />
                <ListWrapper heading={[labels.product, labels.category, labels.document]}>
                    {
                        documents.map((doc, i) => (
                            <GroupedDocument
                                key={i}
                                hideProduct
                                hideCategory
                                {...doc} />
                        ))}
                </ListWrapper>
                {
                    documents.length < total ? (
                        <LoadMoreButton onClickHandler={this.onLoadMore} />
                    ) : null
                }
            </Wrapper >


        );
    }
}

export default Document;