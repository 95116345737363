import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { 
//  } from './../../../actions/productPageActionCreators';

import InproProvider from './../../InproProvider';

class QuoteDetailsSummary extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    const {
      quote,
      labels,
    } = this.props;

    return (
      <div className="quote-details-order-summary">
        <h3>{labels.orderSummary}</h3>
        <p>Freight: ${quote ? quote.Freight.toFixed(2) : null}</p>
        <p>Tax: ${quote ? quote.TotalTax.toFixed(2) : null}</p>
        <p>{labels.quoteSubtotal}: ${quote ? quote.Total.toFixed(2) : null}</p>
      </div>
    );

  }

}

const mapProps = (state) => {
  return {
    quote: state.quoteDetailsPage.quote,
    settings: state.quoteDetailsPage.settings,
  };
}

const mapDispatch = (dispatch) => {
  return {

  };
}

QuoteDetailsSummary = connect(mapProps, mapDispatch)(QuoteDetailsSummary);

export default (props) => (
  <InproProvider>
    <QuoteDetailsSummary {...props}/>
  </InproProvider>
);