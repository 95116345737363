import React, { Component, Fragment } from 'react';
import Popup from 'reactjs-popup';
import { formatMoney, fillTemplate } from './../../../utils';

class NewFooter extends Component {

    constructor(props) {

        super(props);

        this.state = {
            projectName: '',
            zipCode: '',
            marketSegment: ''

        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeZipCode = this.handleChangeZipCode.bind(this);
        this.handleChangeMarketSegment = this.handleChangeMarketSegment.bind(this);
    }

    onResetButtonClick = (e) => {
        e.preventDefault();

        this.props.onReset();
    }

    onAddToCartClick = (e) => {
        e.preventDefault();

        this.props.onAddToCart('ShoppingCart', this.props.labels.addTo.cart);
    }

    addedToCartTemplate = 'Products were added to the quote. Click here to see quote.';
    addedToNewWishlistTemplate = 'Quote {lastCartName} Created';

    getProperties = (steps) => {
        if (steps) {

            var string = "";

            steps.forEach(function (item, index) {

                var num = index + 1;
                if (item != null) {

                    //Get the option that is selected if it is a dropdown
                    var selectedOption = "";
                    if (item.Options != null && item.Title !== "Color") {

                        if (item.Options.length > 1) {
                            var found = item.Options.find(element => element.Selected);
                            if (found != null) {
                                selectedOption = found.Label;
                            }

                            if (string) {
                                //string = string + "&" + item.Title + "=" + selectedOption;
                                string = string + "&prop" + num + "=" + selectedOption;
                            }
                            else {
                                //string = "&" + item.Title + "=" + selectedOption;
                                string = "&prop" + num + "=" + selectedOption;
                            }

                        }


                    }


                    
                }
            });

            return string;
        }
        else {
            return "";
        }
    }


    onCreateQuoteClick = (e) => {
        e.preventDefault();

        const {
            projectName,
            zipCode,
            marketSegment
        } = this.state;

        const {
            marketSegments
        } = this.props;

        const quoteId = "";

        this.props.onAddToQuote(quoteId, projectName, zipCode, marketSegment);

        this.setState({ projectName: '' });
        this.setState({ zipCode: '' });
        this.setState({ marketSegment: '' });
    }


    handleChange(event) {
        this.setState({ projectName: event.target.value });
    }
    handleChangeZipCode(event) {
        this.setState({ zipCode: event.target.value });
    }
    handleChangeMarketSegment(event) {
        this.setState({ marketSegment: event.target.value });
    }

    render() {

        const {
            labels,
            quoteList,
            totalPrice,
            lastCartName,
            hasItemsInCart,
            showAddToQuote,
            showAddToCartNotice,
            isNewWishlist,
            showAddToCartWarning,
            onAddToQuote,
            onCloseNotice,
            onCloseWarning,
            allowedToAdd,
            isDiscontinued,
            selectedVariantItem,
            steps,
            selectedColorPaletteName,
            selectedColorName,
            selectedColorId,
            selectedColorCode,
            isAvailableForPurchase,
            isGuestUser,
            internetPartNumberSymbol,
            activeQuoteId,
            activeQuoteProjectName,
            marketSegments
        } = this.props;


        const {
            projectName,
            zipCode,
            marketSegment
        } = this.state;



        const requestAQuoteLinkAdditional = this.getProperties(steps);

        const skuName = selectedVariantItem ? selectedVariantItem.Code : "";
        const finalSkuName = selectedVariantItem && selectedVariantItem.ParentSKU ? selectedVariantItem.ParentSKU : skuName;

        const colorCodeOrID = selectedVariantItem && selectedColorName && selectedColorId ? selectedColorId : "";
        const finalColorCodeOrID = selectedVariantItem && selectedColorName && selectedColorCode ? selectedColorCode : colorCodeOrID;

        const quoteURL = "/quote-details-page/?quoteId=" + lastCartName;

        const hideRequestQuoteButton = !isGuestUser && isAvailableForPurchase;

        return (
            <Fragment>

                <div className="product__footer--configure">
                    <div className="product__footer-right">
                        <div className="product__footer-total">

                            {labels.loginReminder && isAvailableForPurchase ? (<p className="weight--bold product__loginReminder">Please <a href="/sign-in">log in</a> to get your account pricing</p>) : (<p></p>)}



                            {labels.invernotyIsLive ? (<p className="text--danger">{labels.invernotyIsLive}</p>) : null}


                        </div>
                        <div className="speedShop__buttons">

                            <div className="cartItemGroup__loader loader loader--inline">{labels.addingLoader}</div>

                            {
                                isAvailableForPurchase ? (

                                    <div>
                                        <button
                                        type="addToCart"
                                        className="button button--solid product__addToCartButton2"
                                        disabled={!hasItemsInCart || !allowedToAdd || !selectedVariantItem.isSKUAvailableForPurchase}
                                        onClick={this.onAddToCartClick}>{labels.addTo.prefix} {labels.addTo.cart}</button>

                                        
                                    </div>

                                ): null
                            }


                            {
                                !hideRequestQuoteButton && !isDiscontinued && labels.requestAQuote && labels.requestAQuoteLink ? (
                                    <a href={selectedVariantItem ? labels.requestAQuoteLink + "&partnumber=" + selectedVariantItem.Code +
                                        (selectedColorName && finalColorCodeOrID ? '&internetpartnumber=' + finalSkuName + internetPartNumberSymbol + finalColorCodeOrID : '')
                                        + "&quantity=" + selectedVariantItem.quantity + (selectedColorPaletteName && selectedColorName ? '&palette=' + selectedColorPaletteName : '') +
                                        (selectedColorName ? '&color=' + selectedColorName : '') + requestAQuoteLinkAdditional
                                        : labels.requestAQuoteLink} className="button primary product__RequestQuote">{labels.requestAQuote}</a>
                                ) : null
                            }

                            {showAddToQuote && (

                                <div className="inputGroup inputGroup--compact">

                                   

                                    
                                    {activeQuoteId && activeQuoteProjectName ? (

                                        <button
                                            data-cart-name="ShoppingCart"
                                            className={"button " + 'product__createNewQuote__options'}
                                            disabled={!hasItemsInCart || !allowedToAdd || !selectedVariantItem.isSKUAvailableForPurchase}

                                            onClick={e => {
                                                e.preventDefault();
                                                onAddToQuote(activeQuoteId);
                                            }}

                                        >Add To {activeQuoteProjectName}</button>

                                    ):
                                        <button
                                            data-cart-name="ShoppingCart"
                                    className={"hoverButton button " + 'product__createNewQuote__options'}
                                    disabled={!hasItemsInCart || !allowedToAdd || !selectedVariantItem.isSKUAvailableForPurchase}

                                        >Add To Quote</button>}


                                    




                                   
                                        <Fragment>
                                            <button
                                                className="inputGroup--last dropdownTrigger--click padX--small button product__caret"
                                                title="More options">
                                                <i className="fa fa-caret-down"></i>
                                            </button>

                                            <div className="dropdown" id="dropdown-1" aria-expanded="false" aria-hidden="true">

                                                <Popup trigger={

                                                    <button
                                                        data-cart-name="ShoppingCart"
                                                        className={"button " +  'product__createNewQuote__options' }
                                                        disabled={!hasItemsInCart || !allowedToAdd || !selectedVariantItem.isSKUAvailableForPurchase}

                                                    >{labels.createNewQuote}</button>

                                                } modal>

                                                    {close => (
                                                        <div className="quoteModal">

                                                            <div className="quoteModelClose" onClick={close}>
                                                                x close
                                                            </div>

                                                            <form onSubmit={(event) => { this.onCreateQuoteClick(event); close(); }}>
                                                                <label>Enter Project Name</label>
                                                                <br />
                                                                <input required type="text" value={projectName} onChange={this.handleChange} />
                                                                <br />
                                                                <br />
                                                                <label>Project Zip Code</label>
                                                                <br />
                                                                <input required type="text" value={zipCode} onChange={this.handleChangeZipCode} />
                                                                <br />
                                                                <br />
                                                                {marketSegments ? (
                                                                    <div>
                                                                        <label>Enter Market Segment</label>
                                                                        <br />
                                                                        <select value={marketSegment} onChange={this.handleChangeMarketSegment}>

                                                                            <option value="">Please select Market Segment</option>

                                                                            {marketSegments.map((q) => (
                                                                                <option value={q.Text}>{q.Text}</option>
                                                                            ))}
                                                                        </select>

                                                                        <br />
                                                                        <br />
                                                                    </div>
                                                                ) : null}
                                                                <input type="submit" value="Add New Quote" />
                                                            </form>

                                                        </div>

                                                    )}
                                                </Popup>

                                            {quoteList.length ? (
                                                quoteList.map((q) => (
                                                    <button className= "product__addToExisting"
                                                        key={q.QuoteNumber}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            onAddToQuote(q.QuoteId);
                                                        }}
                                                        disabled={!hasItemsInCart || !allowedToAdd || !selectedVariantItem.isSKUAvailableForPurchase}
                                                    >{labels.addTo.prefix} {q.ProjectName ? q.ProjectName : q.QuoteDisplayNumber}</button>
                                                ))
                                            ) : null}
                                            </div>

                                        </Fragment>
                                  


                                </div>
                            )}


                        </div>
                    </div>

                    {labels.ifVariantOutOfStockHeader || labels.ifVariantOutOfStockBody ? (

                        <div className="product__footer-left">
                            <p><b>{labels.ifVariantOutOfStockHeader}</b><br />{labels.ifVariantOutOfStockBody}</p>
                        </div>

                    ) : null}

                </div>
                {showAddToCartNotice ? (
                    <div
                        
                        className="notice notice--success notice--label">

                            <a href={quoteURL}>

                                <div className="notice__label">{isNewWishlist ? fillTemplate(this.addedToNewWishlistTemplate, { lastCartName }) : fillTemplate(this.addedToCartTemplate, { lastCartName })}</div>

                            </a>

                            <span onClick={onCloseNotice} className="notice__delete">+</span>
                        
                    </div>
                ) : null}
                {showAddToCartWarning ? (
                    <div
                        onClick={onCloseWarning}
                        className="notice notice--warning notice--label">
                        <div className="notice__label">Something went wrong</div><span className="notice__delete">+</span>
                    </div>
                ) : null}
            </Fragment>
        );

    }
}

export default NewFooter;