import React, { Component } from 'react';

import PriceGroup from './PriceGroup';
import { fillTemplate } from './../../../utils';

class Row extends Component {

    onQtyChange = (e) => {
        e.preventDefault();

        const quantity = this.calcQuantity(e.currentTarget.value);

        const {
            Code,
            updateVariant,
        } = this.props;

        updateVariant({
            quantity,
            Code,
        })

    }

    calcQuantity(quantity) {

        const qty = parseInt(quantity);

        const {
            isColorSelected,
            HasNoColor,
            MaxQuantity,
        } = this.props;

        const maxQuantity = MaxQuantity ? MaxQuantity : 9999;

        const maxAvailableForPurchaseQuantity = maxQuantity;

        return isNaN(qty) || qty < this.props.MinQuantity ? this.props.MinQuantity : qty > maxAvailableForPurchaseQuantity ? maxAvailableForPurchaseQuantity : qty;
    }

    render() {
        const {
            Code,
            Specs,
            Price,
            SetupFees,
            SetupFeeMaxQuantity,
            quantity,
            LeadTimeDays,
            Quantity,//in stock
            leadTimeLabel,
            MinQuantity,
            MaxQuantity,
            HasNoColor,
            isColorSelected,
            PriceBreaks
        } = this.props;

        const qtyBreakResults = PriceBreaks.filter(p => p.Quantity <= quantity).sort((a, b) => a.Price - b.Price);
        const checkPrice = qtyBreakResults[0] ? qtyBreakResults[0].Price : Price;
        const price = checkPrice || Price || 0;
        const maxQuantity = MaxQuantity ? MaxQuantity : 9999;
        const showSetupFees = SetupFees && quantity && quantity <= SetupFeeMaxQuantity;
        const setUpFees = showSetupFees ? SetupFees : 0;
        const priceToShow = quantity ? parseFloat(price.toFixed(2)) * quantity + parseFloat(setUpFees.toFixed(2)) : 0;
        
        return (

            <div className="product__PriceSection">
            

            <div className="speedShopProductSection">


                <div className="speedShopProductColumn">

                    <label htmlFor={"quantity-" + Code}>{"Quantity"}</label>

                    <input
                        id={"quantity-" + Code}
                        type="text"
                        name="Quantity"
                        value={quantity > 0 ? quantity : ''}
                        min={MinQuantity}
                        max={maxQuantity}
                        placeholder="0"
                        step="1"
                        className="width--input-narrow"
                        onChange={this.onQtyChange} />
                </div>

                <div className="speedShopProductColumn speedShopProduct__cell--availability1">

                   <label className="product__AvailableInventoryLabel">Available</label>

                    <div>
                            {isColorSelected || HasNoColor ? <span className="product__AvailableInventory">{this.props.Quantity}</span> : (
                            <span className="product__SelectColorLabel">Select color</span>
                        )}
                    </div>
                </div>






                <div className="speedShopProductColumn speedShopProduct__cell--price">

                    <label>Price</label>

                    {price ? (
                        <PriceGroup price={price} />
                    ) : (<div className = "emptyPrice">–</div>)}
                </div>

                <div className="speedShopProductColumn speedShopProduct__cell--price">

                    <label>Setup Fee</label>

                    {showSetupFees ? (
                        <PriceGroup price={SetupFees} />
                    ) : (<div className="emptyPrice">–</div>)}
                </div>
                <div className="speedShopProductColumn speedShopProduct__cell--price">
                    <label>Subtotal</label>
                    <PriceGroup price={priceToShow.toFixed(2)} />
                </div>

                </div>

                {
                    leadTimeLabel ? (

                        <div className="product__LeadTimeLabel text--danger">
                            <div>
                                {(isColorSelected || HasNoColor) && quantity > Quantity ? fillTemplate(leadTimeLabel, { days: LeadTimeDays === 0 ? "N/A" : LeadTimeDays }) : ''}
                            </div>
                        </div>

                    ) : null
                }

            </div>
        );
    }
}

export default Row;