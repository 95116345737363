import React, { useState, Fragment } from 'react';

import StockInfo from './StockInfo';
import StyleInfo from './StyleInfo';
import RemoveButton from './RemoveButton';
import AddToCartButton from './AddToCartButton';

const Variant = ({
    Code,
    Specs,
    Size,
    Color,
    Price,
    SubTotal,
    InStock,
    Quantity,
    SetupFees,
    LineItemId,
    HasNoColor,
    IsComponent,
    MinQuantity,
    MaxQuantity,
    LeadTimeDays,
    SetupFeeMaxQuantity,
    onDeleteItemClick,
    onAddItemToCartClick,
    onItemQuantityUpdateClick,
    readonly,
    showSetupFees,
    availableForOrder,
    labels,
}) => {

    const [qty, setQty] = useState(Quantity);

    const colorCode = Color && Color.Code ? Color.Code : null;

    const onQuantityUpdateClick = (e) => {
        e.preventDefault();
        onItemQuantityUpdateClick(Code, colorCode, qty);
    };

    const onQtyChange = (e) => {
        setQty(e.target.value);
    };

    const setupFees = qty <= SetupFeeMaxQuantity ? SetupFees : 0.00;

    return (
        <div className="cart-sku-content">

            <div className="cart-sku-content__inner-wrapper">

                <div className="cart-sku-content__column cart-sku-content__column--item-number">
                    <div className="cart-sku-content__heading cart-sku-content__heading--item-number">Item number: {Code}</div>
                    {Size && (
                        <div className="cart-sku-content__specs">
                            <h4 className="cart-sku-content__subheading">Size</h4>
                            <p>{Size}</p>
                        </div>
                    )}
                    {Color && Color.Code || (Color.IsTBDColor && !HasNoColor) ? <StyleInfo {...Color} /> : null}
                </div>

                <div className="cart-sku-content__column">
                    <div className="cart-sku-content__heading">Price: </div>
                    <div className="cart-sku-content__price">${Price.toFixed(2)}</div>
                    {showSetupFees ? (<p className="cart-sku-content__additional-price-info">Setup Fee<span>${setupFees}</span></p>) : null}
                </div>

                <div className="cart-sku-content__column">
                    <div className="cart-sku-content__heading">Quantity: </div>

                    {readonly ? (
                        <span>{Quantity}</span>
                    ) : (
                        <Fragment>
                            <input
                                type="number"
                                value={qty}
                                min="0"
                                max="9999"
                                onChange={onQtyChange} />

                            <button
                                className="lineItem__update button--link"
                                onClick={onQuantityUpdateClick}>Update</button>
                        </Fragment>
                    )}

                    <StockInfo inStock={InStock} leadTimeDays={LeadTimeDays === 0 ? "N/A" : LeadTimeDays} qty={qty} labels={labels} />
                </div>

                <div className="cart-sku-content__column">
                    <div className="cart-sku-content__heading">Subtotal: </div>
                    <div className="cart-sku-content__price">${SubTotal.toFixed(2)}</div>
                </div>

            </div>
            {readonly ? null : (
                <div className="cart-sku-content__action-row">
                    <RemoveButton lineItemId={LineItemId} onDeleteClick={onDeleteItemClick} />
                    <AddToCartButton
                        lineItemId={LineItemId}
                        quantity={Quantity}
                        onAddClick={onAddItemToCartClick}
                        disabled={!availableForOrder} />
                </div>
            )}
        </div>
    );
};

export default Variant;