import React from 'react';

const HeadCell = ({children}) => (
  <th className="my-quotes-table__head-cell">{children}</th>
)

const Thead = ({
  labels
}) => (
  <thead className="my-quotes-table__head">
    <tr>
      <HeadCell>{labels.quoteNumber}</HeadCell>
      <HeadCell>{labels.projectName}</HeadCell>
      <HeadCell>{labels.status}</HeadCell>
      <HeadCell>{labels.lastUpdate}</HeadCell>
      <HeadCell>{labels.totalText}</HeadCell> 
      <HeadCell>{labels.actions}</HeadCell>
    </tr>
  </thead>
);

export default Thead;