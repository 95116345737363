import React, { useState } from 'react';

const EstimateFreightForm = ({onFormSubmit, labels}) => {
  const [zip, setZip] = useState('');

  const onZipChange = (e) => {
    setZip(e.target.value);
  }

  return (
    <form className="estimate-freight__form">
      <input 
        type="text" 
        value={zip} 
        onChange={onZipChange} 
        placeholder={labels.estimateFreightPlaceholder} />
      <button onClick={e => {
        e.preventDefault();
        onFormSubmit(zip);
      }}>{labels.estimateFreightButton}</button>
    </form>
  )
}

export default EstimateFreightForm;