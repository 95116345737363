import {
  SET_MY_QUOTES_PAGE_ITEMS,
  UPDATE_MY_QUOTES_PAGE_SETTINGS,
} from './types';

import quotesApiService from '../services/quotesApiService';

export const setList = (list) => ({
  type: SET_MY_QUOTES_PAGE_ITEMS,
  list,
})

export const getList = () => async (dispatch, getState) => {
  dispatch(updateSettings({
    isLoading: true,
  }));
  const list = await quotesApiService.getQuotes();
  dispatch(updateSettings({
    isLoading: false,
  }));
  if (list) {
    dispatch(setList(list));
  }
}

export const duplicateQuote = (quoteId, projectName) => (dispatch, getState) => {
  dispatch(updateSettings({
    isLoading: true,
  }));
    quotesApiService.duplicateQuote(quoteId, projectName).then(() => {
        window.WOW.MiniQuoteCart.refresh();
    dispatch(getList());
  });
}

export const createQuote = (projectName, zipCode, marketSegment) => (dispatch, getState) => {
    dispatch(updateSettings({
    isLoading: true,
  }));
    quotesApiService.createQuote(projectName, zipCode, marketSegment).then((data) => {
      //window.WOW.MiniQuoteCart.refresh();
   //dispatch(getList());
        window.location.href = "/quote-details-page/?quoteId=" + data.Success.Item2;
  });
}

export const deleteQuote = (quoteNumber) => (dispatch, getState) => {
  dispatch(updateSettings({
    isLoading: true,
  }));
  quotesApiService.deleteQuote(quoteNumber).then(() => {
      window.WOW.MiniQuoteCart.refresh();
  dispatch(getList());
  });
}

export const sendQuoteViaEmail = (quoteId, { to, cc, note }) => () => {

  quotesApiService.sendQuoteViaEmail(quoteId, to, cc, note);
}

export const updateSettings = (settings) => ({
  type: UPDATE_MY_QUOTES_PAGE_SETTINGS,
  settings,
});

export const showDeleteModal = (deleteModalQuoteNumber) => updateSettings({
  showDeleteModal: true,
  deleteModalQuoteNumber,
});

export const hideDeleteModal = () => updateSettings({
  showDeleteModal: false,
  deleteModalQuoteNumber: null,
});

export const showEmailModal = (emailModalQuoteNumber) => updateSettings({
  showEmailModal: true,
  emailModalQuoteNumber,
});

export const hideEmailModal = () => updateSettings({
  showEmailModal: false,
  emailModalQuoteNumber: null,
});

export const purchaseQuote = (quoteNumber) => async (dispatch, getState) => {
    dispatch(updateSettings({
        isLoading: true,
    }));

    const data = await quotesApiService.purchaseQuote(quoteNumber);

    //showNotification(data);
    //window.WOW.MiniCart.refresh();
    //dispatch(updateSettings({
    //  isLoading: false,
    //}));

    window.location.href = "/quote-checkout/";
}