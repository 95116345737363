import React from 'react';

export default ({
  title,
  number,
  children,
  singleOption,
}) => (
    <div className={singleOption ? 'numbered-container single-numbered-container' + number : 'numbered-container numbered-container' + number}>
    <div className="numbered-container__header">
      <div className="numbered-container__number">{number}</div>
      <h3 className="numbered-container__name">{title}</h3>
    </div>
    
    <div className="numbered-container__content">
      {children}
    </div>
  </div>
);