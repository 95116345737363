import React, { Component } from 'react';
import { connect } from 'react-redux';

import Cloak from '../../Common/Cloak';
import CartGroupContent from '../../Common/CartGroupContent';
import QuoteVariant from '../../Common/CartGroupContent/QuoteVariant';

import { 
  getQuote,
  setQuote,
  removeItem,
  addItemToCart,
  updateItemQuantity,
} from './../../../actions/quoteDetailsPageActionCreators';

import InproProvider from './../../InproProvider';

class QuoteDetailsList extends Component {

  constructor(props) {
    super(props);

    const {
      initialData = {},
      setQuote,
    } = this.props;

      setQuote(initialData.quote ? initialData.quote : undefined);

      this.getQuoteMethod = this.getQuoteMethod.bind(this);

    }

    componentDidMount() {


        document.body.addEventListener('quoteDetailsAdd', this.getQuoteMethod);

    }

    componentWillUnmount() {


        document.body.removeEventListener('quoteDetailsAdd', this.getQuoteMethod);

    }

    getQuoteMethod = () => {

        const {
            
            getQuote,
        } = this.props;
     
        console.log("Updating Quote");

        getQuote();
    }

  onItemQuantityUpdateClick = (variantCode, colorCode, quantity, variantId) => {
    const {
      quote, updateItemQuantity,
    } = this.props;
    updateItemQuantity(quote.QuoteNumber, variantCode, colorCode, quantity, quote.QuoteId, variantId);
  }
  
  onItemDeleteClick = (lineItemId) => {
    const {
      quote, removeItem,
    } = this.props;
    removeItem(quote.QuoteNumber, lineItemId, quote.QuoteId);
  }
  
  onAddItemToCartClick = (lineItemId, quantity) => {
    const {
      quote,
      addItemToCart,
    } = this.props;
    addItemToCart(quote.QuoteNumber, lineItemId, quantity);
  }

  render() {

    const {
      products,
      settings,
      labels,
        quote
    } = this.props;

      const isReadonly = !quote || quote.IsExpired || quote.IsLocked;

      const length = quote && quote.QuoteProductList ? quote.QuoteProductList.length : 0;
      const showPricing = quote && quote.Format == "Quote_w_Pricing";
    return (
      <div className="width--layout">
        <Cloak active={settings.isLoading}>
                {quote ? (
            <ul>
                        {quote.QuoteProductList.map((p,i) => (
                <QuoteVariant
                        showSetupFees
                        key={p.Code}
                        {...p}
                        readonly={isReadonly}
                        labels={labels}
                        availableForOrder={true}
                        onDeleteItemClick={this.onItemDeleteClick}
                        onAddItemToCartClick={this.onAddItemToCartClick}
                        onItemQuantityUpdateClick={this.onItemQuantityUpdateClick}
                        lastItem={i + 1 === length}
                        showPricing={showPricing}
                    />

            ))}
            </ul>
          ) : (
            <p>{labels.emptyList}</p>
          )}
        </Cloak>
      </div>
    );

  }

}

const mapProps = (state) => {
  return {
    products: state.quoteDetailsPage.list,
    quote: state.quoteDetailsPage.quote,
    settings: state.quoteDetailsPage.settings,
  };
}

const mapDispatch = (dispatch) => {
  return {
    setQuote: (quote) => dispatch(setQuote(quote)),
    getQuote: (quoteNumber) => dispatch(getQuote(quoteNumber)),
    removeItem: (quoteNumber, variantCode, quoteId) => dispatch(removeItem(quoteNumber, variantCode, quoteId)),
      addItemToCart: (variantCode, colorCode, quantity, price) => dispatch(addItemToCart(variantCode, colorCode, quantity, price)),
      updateItemQuantity: (quoteNumber, variantCode, colorCode, quantity, quoteId, variantId) => dispatch(updateItemQuantity(quoteNumber, variantCode, colorCode, quantity, quoteId, variantId)),
  };
}

QuoteDetailsList = connect(mapProps, mapDispatch)(QuoteDetailsList);

export default (props) => (
  <InproProvider>
    <QuoteDetailsList {...props}/>
  </InproProvider>
);