import React, { Component, useState, Fragment } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class Row extends Component {

    constructor(props) {
        super(props);
    

        this.state = {

            dupProjectName: ''

        }

        this.handleDupChange = this.handleDupChange.bind(this);
    }

    onDuplicateQuoteClick = (e) => {
        e.preventDefault();

        const {
            duplicate,
            QuoteId
        } = this.props;

        const {
            dupProjectName
        } = this.state;

        duplicate(QuoteId, dupProjectName);

        this.setState({ dupProjectName: '' });
    }

    onPurchaseQuoteClick = (e) => {
        e.preventDefault();

        const {
            QuoteId,
            purchaseQuote,
        } = this.props;

        purchaseQuote(QuoteId);
    }

    onViewQuoteClick = (e) => {
        e.preventDefault();

        const {
            detailsPageUrl,
            QuoteId
        } = this.props;

        //window.location.href = detailsPageUrl + "?quoteId=" + QuoteId;
        window.open(detailsPageUrl + "?quoteId=" + QuoteId, "_blank");
    }

    onViewOrderClick = (e) => {
        e.preventDefault();

        const {
            OrderNumber
        } = this.props;

        //window.location.href = detailsPageUrl + "?quoteId=" + QuoteId;
        window.open("/account/order-history/order-history-details/?orderNumber=" + OrderNumber, "_blank");
    }

    handleDupChange(event) {
        this.setState({ dupProjectName: event.target.value });
    }
    render() {
        const {
            labels,
            IsActive,
            IsExpired,
            IsLocked,
            LastUpdate,
            QuoteNumber,
            ProjectName,
            ExpiredDate,
            detailsPageUrl,
            printPageUrl,
            openEmailModal,
            openDeleteModal,
            duplicate,
            QuoteDisplayNumber,
            QuoteId,
            Status,
            IsEcommerceQuote,
            LastModified,
            LastModifiedText,
            Total,
            OrderNumber,
            HasInvalidColor            
        } = this.props;

        const {
            dupProjectName
        } = this.state;

        const Cell = ({ children, label }) => (
            <td className="my-quotes-table__cell">
                <div className="my-quotes-table__cell__inner-wrapper">
                    {label && (
                        <span className="my-quotes-table__cell__mobile-label">{label}: </span>
                    )}
                    {children}
                </div>
            </td>
        );

        const disableOrderButton = OrderNumber != null ? false : true;
        
        return (

            <tr className="my-quotes-table__row">
                <Cell label={labels.quoteNumber}><a href={`${detailsPageUrl}?quoteId=${QuoteId}`} target="_Blank">{QuoteDisplayNumber}</a></Cell>
                <Cell label={labels.projectName}>{ProjectName}</Cell>
                <Cell label={labels.status}>
                    <p className="my-quotes-table__row__status my-quotes-table__row__status--active">{Status}</p>
                </Cell>
                <Cell label={labels.lastUpdate}>{LastModifiedText}</Cell>
                <Cell label={labels.totalText}>${Total.toFixed(2)}</Cell>

                <td className="my-quotes-table__cell">
                    <div className="my-quotes-table__cell__inner-wrapper">

                        {Status == "Purchased" ? (<button
                            className='my-quotes-table__cell__action button--solid bold-text my-quotes-purchase-button'
                            disabled={disableOrderButton}
                            onClick={(e) => {

                                this.onViewOrderClick(e);
                            }}>View Order Information</button>) : 
                            
                            
                            

                            (IsExpired || HasInvalidColor) ? (<button
                                className='my-quotes-table__cell__action button--solid bold-text my-quotes-purchase-button'

                                onClick={(e) => {

                                    this.onViewQuoteClick(e);
                                }}>View Quote</button>) :


                                <button
                                    className='my-quotes-table__cell__action button--solid bold-text my-quotes-purchase-button'

                                    onClick={(e) => {

                                        this.onPurchaseQuoteClick(e);
                                    }}>{labels.purchaseQuote}</button>

                            
                            
                            
                            }

                       
           


                        <a
                            className={`button my-quotes-table__cell__action ${IsExpired ? 'button--disabled' : ''}`}
                            href={IsExpired ? '#' : `${printPageUrl}?quoteId=${QuoteId}`}
                            onClick={IsExpired ? (e) => { e.preventDefault(); } : () => {/*blank. default is taking control*/ }}
                            target="_Blank"
                        >{labels.print}</a>
                        <button
                            className='my-quotes-table__cell__action'
                            disabled={IsExpired}
                            onClick={IsExpired ? (e) => {
                                e.preventDefault();
                            } : (e) => {
                                e.preventDefault();
                                openEmailModal(QuoteId);
                            }}>{labels.email}</button>



                        <Popup trigger={<button className="my-quotes-table__cell__action" disabled={!IsEcommerceQuote}>{labels.duplicate}</button>} modal>

                            {close => (
                                <div className="quoteModal">

                                    <div className="quoteModelClose" onClick={close}>
                                        x close
                                    </div>

                                    <div className="quoteModelText">Enter Project Name</div>

                                    <input type="text" value={dupProjectName} onChange={this.handleDupChange} />

                                    <br />
                                    <br />

                                    <button onClick={(event) => { this.onDuplicateQuoteClick(event); close(); }}>Duplicate Quote</button>

                                </div>

                            )}
                        </Popup>


                    </div>
                </td>

               
            </tr>

        )
    }

}







export default Row;