import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import InproProvider from './../InproProvider';

import {
  initSeminarList,
  updateSeminarPageListItem,
} from '../../actions/seminarPageActionCreators';

class SelectedSeminars extends Component {

  onSeminarRemove = (seminar) => {
    const {
      updateListItem,
    } = this.props;

    updateListItem(seminar, {
      selected: false,
    });
  }

  render() {
    const {
      list,
      labels,
    } = this.props;

    const itemsToShow = list.filter(s => s.selected);

    return (

      <section className="width--layout">
        {itemsToShow.length ? (
          <Fragment>
            <p className="seminar-page__text">{labels.selectedSeminars}:</p>
            <div className="seminar-page__selected-seminars-list">
              {itemsToShow.map((s, i) =>
              <div className="seminar-page__selected-seminars-list-item" key={i}>
              <div className="seminar-page__selected-seminars-list-item__image-wrapper" style={{backgroundImage: "url(" + s.image + ")"}}>
                <img src={s.image} className="seminar-page__selected-seminars-list-item__image"/>
              </div>
              <span className="seminar-page__selected-seminars-list-item__title">{s.title}</span>
              <button
                className="seminar-page__selected-seminars-list-item__remove-button"
                onClick={e => {
                  e.preventDefault();
                  this.onSeminarRemove(s);
                }}>{labels.removeButton}</button>
              </div>
              )}
            </div>
          </Fragment>
        ) : null}

      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.seminarPage.list,
    settings: state.seminarPage.settings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    initList: (list) => dispatch(initSeminarList(list)),
    updateListItem: (item, props) => dispatch(updateSeminarPageListItem(item, props)),
  }
}

SelectedSeminars = connect(mapStateToProps, mapDispatchToProps)(SelectedSeminars)

export default (props) => (
  <InproProvider>
    <SelectedSeminars {...props}/>
  </InproProvider>
);
