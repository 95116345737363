import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import Magnifier from "../../shared/MagnifierImage.js";
import styled from "styled-components";

const slidesToShow = 5;

class Assets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      translateValue: 0,
      controlsWidth: null,
    }

  }

  componentDidMount() {
    window.onresize = this.onWindowResize;

    if (typeof document !== 'undefined') {
      this.onWindowResize();//serverside fallback
    }
  }

  switchImage = (currentImageIndex) => {

    const {
      onCurrentAssetChange,
    } = this.props;

    if (onCurrentAssetChange) {
      onCurrentAssetChange(currentImageIndex);
    }

      var youtubeVideos = document.getElementsByClassName("productVideosiFrame");
      for (var i = 0; i < youtubeVideos.length; i++) {
          youtubeVideos.item(i).contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
      }

  }

  next = (e) => {
    e.preventDefault();

    const {
      assets,
    } = this.props;

    this.setState(prevState => ({
      translateValue: prevState.translateValue !== -(this.slideWidth() * (assets.length - slidesToShow)) ? prevState.translateValue + -(this.slideWidth()) : prevState.translateValue,
    }));
  }

  prev = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      translateValue: prevState.translateValue ? prevState.translateValue + this.slideWidth() : prevState.translateValue,
    }));
  }

  slideWidth = () => {
    return this.state.controlsWidth / slidesToShow;
  }

  controlsWidth = () => {
    if (typeof document === 'undefined') {
      //serverside fallback
      return 0;
    }
    const controls = document.querySelector('.product-page-assets__controls');
    return controls ? controls.clientWidth : 0
  }

  onWindowResize = (e) => {
    const controlsWidth = this.controlsWidth();
    this.setState({
      translateValue: 0,
      controlsWidth,
    })
  }

  render() {

    const {
      assets,
      showNames,
      showTitles,
      currentAssetIndex,
      orderSampleClickFunction,
      isCurrentAssetDis
    } = this.props;

    const hasImages = assets && assets.length;
    const currentImageIndex = currentAssetIndex || (hasImages ? 0 : null);
    const Container = styled.div`
      display: flex;
    `;

    return (
      <div className="product-page-assets">
        {hasImages ? (
          <Fragment>
            
            <div className="product-page-assets__current-image-holder">
              {assets.map((asset, i) => (
                <div
                  className={classNames("product-page-assets__current-image-wrapper", {
                      "product-page-assets__current-image-wrapper--active": currentImageIndex === i,
                  }, { "mouse-cursor-pointer": showNames && !isCurrentAssetDis, }
                      )}
                      onClick={showNames && !isCurrentAssetDis ? orderSampleClickFunction : undefined}    
                  key={i}>
                      {showNames ? (
                          <div className="product-page-assets__current-image-wrapper__variant-detail">

                              <div className="product-page-assets__name">
                                  <p>{asset.Name}<br />{asset.ColorId}</p>

                                  {!isCurrentAssetDis ? (

                                      <button className="button--solid">Order Sample</button>

                                  ): null

                                  }

                                  
                              </div>
                      
                    </div>
                      ) : null}


                      {!showNames && !showTitles ? (
                          <div className="product-page-assets__current-image-wrapper__variant-detail">

                              <div className="product-page-assets__name">
                                  <p>{asset.Name}<br />{asset.ColorId}</p>
                              </div>

                          </div>
                      ) : null}


                  {showTitles && asset.Image.Title && !asset.Image.YoutubeVideoUrl ? (
                    <div className="product-page-assets__current-image-wrapper__variant-detail">
                      <span>{asset.Image.Title}</span>
                    </div>
                  ) :null}

                      {!asset.Image.YoutubeVideoUrl ? (

                         

                                <Container>
                                    <Magnifier photo={asset.Image.ImageURL+"?width=500&height=500&mode=crop"} photoAlt = {asset.Image.AltText} />
                                </Container>

                      ) : (
                              <div className="full-video">
                                  <div className="responsive-embed">
                                      <iframe className="productVideosiFrame" frameborder="0" allowFullScreen src={asset.Image.YoutubeVideoUrl}>

                                      </iframe>
                                  </div>
                              </div>
                           )}  




                  
                </div>
              ))}
            </div>
          
            {assets.length > 1 ? (
              <div className="product-page-assets__controls" >
                <div className="product-page-assets__thumb-list-wrapper" style={{
                  height: this.slideWidth()
                }}>
                  <ul 
                    className="product-page-assets__thumb-list"
                    style={{
                      transform: `translateX(${this.state.translateValue}px)`,
                      transition: 'transform ease-out 0.45s',
                    }}>
                    {assets.map((asset, i) => (
                      <li 
                        className="product-page-assets__thumb"  
                        style={{
                          width: this.slideWidth()
                        }}                      
                        key={i}
                        onClick={(e) => {
                          e.preventDefault();
                          this.switchImage(i)
                        }}>
                        <img src={`${asset.Image.ImageURL}?width=125&height=125&mode=crop`} alt={asset.Image.AltText} className="product-page-assets__thumb-img" loading="lazy" />
                      </li>
                    ))}
                  </ul>
                </div> 
                {assets.length > slidesToShow ? (
                  <Fragment>
                    <button 
                      onClick={this.next} 
                      className="product-page-assets__control product-page-assets__control--next"
                      aria-label = "Next Image"
                      style={{
                        top: this.slideWidth() / 2 - 10,
                      }}/>
                    <button 
                      onClick={this.prev} 
                      className="product-page-assets__control product-page-assets__control--prev"
                      aria-label = "Previous Image"
                      style={{
                        top: this.slideWidth() / 2 - 10,
                      }}/>
                  </Fragment>
                ) : null}
              </div>
             
            ) : null}

          </Fragment>
        ) : null}        
      </div>
    );
  }
}

export default Assets;