import React from 'react';

const Cell = ({children}) => <td className="order-history-table__cell">{children}</td>;

const Order = ({
  OrderNumber,
  PurchaseOrder,
  ConsolidateNumber,
  ShippingDate,
  ShipToName,
  Invoice,
  OrderTotal,
  MOT,
  DetailsLink,
  isInternational,
}) => (
  <tr className="order-history-table__row">
    <Cell><span data-header="Order #"><a href={DetailsLink} target="_Blank">{OrderNumber}</a></span></Cell>
    <Cell><span data-header="Purchase Order">{PurchaseOrder}</span></Cell>
    <Cell><span data-header="Consol. No">{ConsolidateNumber}</span></Cell>
    <Cell><span data-header={isInternational ?"Ex-Factory Date" : "Shipping"}>{ShippingDate}</span></Cell>
    {!isInternational ? (<Cell><span data-header="Ship To Name">{ShipToName}</span></Cell>) : null}    
    <Cell><span data-header="Invoice">{Invoice}</span></Cell>
    <Cell><span data-header="Order Total">{OrderTotal}</span></Cell>
    {isInternational ? (<Cell><span data-header="MOT">{MOT}</span></Cell>) : null}    
  </tr>
)

export default Order;
