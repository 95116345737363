import {
  SET_PRODUCT_PAGE_VARIANTS_LIST,
} from './../../actions/types';

export default (state = null, action) => {
  switch(action.type) {
    case SET_PRODUCT_PAGE_VARIANTS_LIST:
      return action.list;
    default:
      return state;
  }
};