import React from 'react';

export default ({
    Categories,
    DocumentName,
    ProductCodeList,
    FileUrl,
    FileExt,
    hideProduct,
    hideCategory,
    GroupedResources

}) => (
        <div className="search-result__item">
            {hideProduct || <p data-header="product">{ProductCodeList}</p>}
            {hideCategory || <p data-header="category">{Categories}</p>}
            <p data-header="document">{DocumentName}</p>
            <div className="extension-link__link-group">

                {GroupedResources && GroupedResources.map((p, i) => (
                    <a key={i} className="extension-link__link" target="_blank" href={p.FileUrl}>{p.FileExt}</a>                    
                ))}            

            </div>
        </div>
    );