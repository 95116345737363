import React from 'react';

export default ({
  features,
}) => (
  <ul className="product-page-details__feature-list">
    {features.map((f, i) => (
      <li className="product-page-details__feature" key={i}>
        <span className="product-page-details__feature-link">{f}</span>
      </li>
    ))}    
  </ul>
)