
export const addFabricToRecentlyViewed = (code) => (dispatch, getState) => {
    let recentlyViewed = getCookie("recentlyViewedFabrics");
    recentlyViewed = recentlyViewed != null ? recentlyViewed.split(",") : [];

    let indexOfCode = recentlyViewed.indexOf(code);
    if (indexOfCode >= 0) { recentlyViewed.splice(indexOfCode, 1); }
    recentlyViewed.unshift(code);
    if (recentlyViewed.length > 10) { recentlyViewed.pop(); }
    let expiresAt = new Date();
    expiresAt.setTime(expiresAt.getTime() + (14 * 60*60*24*1000));
    
    document.cookie = "recentlyViewedFabrics" + "=" + recentlyViewed.join(',') + ";" + "expires=" + expiresAt.toUTCString() + ";path=/;sameSite=strict";
}

const getCookie = (cookieName) => {
    let docCookie = decodeURIComponent(document.cookie);
    let start = docCookie.indexOf(cookieName);
    if (start >= 0) { 
        let end = docCookie.indexOf(';', start);
        if (end < 0) { end = docCookie.length; }
        return docCookie.substring(docCookie.indexOf("=", start)+1, end);
    }

    return null;
}