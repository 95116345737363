import {
  commonGet,
  commonPost,
} from '../apiHelper';

const apiMethods = {
  getQuote: '/QuotesService/GetQuote',
  getQuotes: '/QuotesService/GetQuotes',
  deleteQuote: '/QuotesService/DeleteQuote',
  createQuote: '/QuotesService/CreateQuote',
    renameProject: '/QuotesService/RenameProject',
  editShippingAddress: '/QuotesService/EditShippingAddress',
  editMarketSegment: '/QuotesService/EditMarketSegment',
    purchaseQuote: '/QuotesService/PurchaseQuote',
    setActiveQuote: '/QuotesService/SetActiveQuote',
  duplicateQuote: '/QuotesService/DuplicateQuote',
  estimateFreight: '/QuotesService/EstimateFreight',
  sendQuoteViaEmail: '/QuotesService/SendQuoteViaEmail',
  removeItem: '/QuotesService/RemoveItem',
  addItemToCart: '/QuotesService/AddQuoteItemToCart',
  addCartToQuote: '/QuotesService/AddCartToQuote',
    updateItemQuantity: '/QuotesService/UpdateQuantity',
    updateQuoteList: '/QuotesService/UpdateQuoteList',
    addItemsToQuote: '/QuotesService/AddProductsToCRMQuote',
}

export const getQuotes = () => commonGet(apiMethods.getQuotes);

export const getQuote = (quoteId) => commonGet(apiMethods.getQuote, {quoteId});

export const duplicateQuote = (quoteId, projectName) => commonPost(apiMethods.duplicateQuote, { quoteId, projectName });

export const deleteQuote = (quoteNumber) => commonPost(apiMethods.deleteQuote, { quoteNumber });

export const addCartToQuote = (quoteId, projectName, zipCode, marketSegment) => commonPost(apiMethods.addCartToQuote, { quoteId, projectName, zipCode, marketSegment });

export const createQuote = (projectName, zipCode, marketSegment) => commonPost(apiMethods.createQuote, { projectName, zipCode, marketSegment });

export const purchaseQuote = (quoteNumber) => commonPost(apiMethods.purchaseQuote, { quoteNumber });

export const setActiveQuote = (quoteId, quoteProjectName) => commonPost(apiMethods.setActiveQuote, { quoteId, quoteProjectName });

export const updateQuoteList = () => commonPost(apiMethods.updateQuoteList);

export const addItemsToQuote = (quoteId, projectName, QuoteProductList, zipCode, marketSegment) => commonPost(apiMethods.addItemsToQuote, { quoteId, projectName, QuoteProductList, zipCode, marketSegment });

export const addItemToCart = (quoteNumber, lineItemId, quantity) => {
  return commonPost(apiMethods.addItemToCart, {quoteNumber, lineItemId, quantity});
};

  
export const removeItem = (quoteNumber, lineItemId, quoteId) => commonPost(apiMethods.removeItem, {
  quoteNumber, 
    lineItemId,
  quoteId,
});

export const updateItemQuantity = (quoteNumber, variantCode, colorCode, quantity, quoteId, variantId) => commonPost(apiMethods.updateItemQuantity, {
  quoteNumber, 
  variantCode, 
  colorCode,
    quantity,
    quoteId,
  variantId,
});

export const renameProject = (quoteId, name) => commonPost(apiMethods.renameProject, {
  quoteId, 
  name,
});

export const editShippingAddress = (quoteId, shipToLine1, shipToLine2, shipToCity, shipToState, shipToZip, shipToCountry) => commonPost(apiMethods.editShippingAddress, { quoteId, shipToLine1, shipToLine2, shipToCity, shipToState, shipToZip, shipToCountry });

export const editMarketSegment = (quoteId, marketSegment) => commonPost(apiMethods.editMarketSegment, {
    quoteId,
    marketSegment,
});

export const sendQuoteViaEmail = (quoteId, to, cc, note, freight) => commonPost(apiMethods.sendQuoteViaEmail, {
  quoteId,
  to,
  cc,
  note,
  freight,
});

export const estimateFreight = (quoteId, zipCode) => commonGet(apiMethods.estimateFreight, {
  quoteId,
  zipCode,
});

export default {
  getQuote,
  getQuotes,
  removeItem,
  createQuote,
  deleteQuote,
  renameProject,
  editShippingAddress,
  editMarketSegment,
  addItemToCart,
  purchaseQuote,
  setActiveQuote,
  duplicateQuote,
  estimateFreight,
  sendQuoteViaEmail,
  updateItemQuantity,
}