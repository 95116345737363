import React from 'react';

const Color = ({
  Label,
  ColorId,
  Image,
  Selected,
    onClick,
  Value,
}) => (
  <div onClick={onClick} selected={Selected} className={`color-step-item ${Selected ? 'color-step-item--active ' : ' '}product-color-step__item`}>
    <div className="color-step-item__image-wrapper">
      <img src={`${Image.ImageURL}?width=200&height=200&mode=crop`} alt={Label} loading="lazy"/>
    </div>
        <span className="color-step-item__name" id={Value}>
            <img loading="lazy" src={`${Image.ImageURL}?width=200&height=200&mode=crop`} alt={Label} />
            {Label}
            <br/>
            {ColorId}
        </span>
        <i className="fa fa-check" aria-hidden="true"></i>
  </div>
);

export default Color;