import {
  UPDATE_MY_QUOTES_PAGE_SETTINGS,
} from './../../actions/types';

const defaultState = {
  isLoading: false,
  showDeleteModal: false,
  deleteModalQuoteNumber: '',
  showEmailModal: false,
  emailModalQuoteNumber: '',
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case UPDATE_MY_QUOTES_PAGE_SETTINGS:
      return Object.assign({}, state, action.settings);
    default:
      return state;
  }
}