import React, { Component } from 'react';
import Select, { Option } from 'rc-select';

class Filter extends Component {

  render() {

    const { 
      label,
      options,
      onChange,
      allowClear,
      selectedOption,
    } = this.props;

    return (
      <div className="search-filters__item">
          <label className="search-filters__label">{label}</label>
          <Select
            value={selectedOption}
            onChange={onChange}
            allowClear={true}
            optionFilterProp="children"
            optionLabelProp="children"
            placeholder="please select"
            showSearch={false}
            showArrow={true}
          >
            {options.map((o, i) => (
              <Option key={i} value={o.value}>{o.label}</Option>
            ))}
          </Select>
      </div>
    );
  }
}

export default Filter;