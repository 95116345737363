import React, { Component, Fragment } from 'react';
import Popup from 'reactjs-popup';
import { formatMoney, fillTemplate } from './../../../utils';

class Footer extends Component {

    constructor(props) {

        super(props);

        this.state = {
            projectName: '',
            zipCode: '',
            marketSegment: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeZipCode = this.handleChangeZipCode.bind(this);
        this.handleChangeMarketSegment = this.handleChangeMarketSegment.bind(this);

    }

  onResetButtonClick = (e) => {
    e.preventDefault();
    
    this.props.onReset();
  }

  onAddToCartClick = (e) => {
    e.preventDefault();

    this.props.onAddToCart('ShoppingCart', this.props.labels.addTo.cart);
  }

  addedToCartTemplate = 'Products were added to the quote. Click here to see quote.';
  addedToNewWishlistTemplate = 'Quote {lastCartName} Created';


    onCreateQuoteClick = (e) => {
        e.preventDefault();

        const {
            projectName,
            zipCode,
            marketSegment
        } = this.state;

        const {
            marketSegments
        } = this.props;

        const quoteId = "";

        this.props.onAddToQuote(quoteId, projectName, zipCode, marketSegment);

        this.setState({ projectName: '' });
        this.setState({ zipCode: '' });
        this.setState({ marketSegment: '' });
    }


    handleChange(event) {
        this.setState({ projectName: event.target.value });
    }
    handleChangeZipCode(event) {
        this.setState({ zipCode: event.target.value });
    }
    handleChangeMarketSegment(event) {
        this.setState({ marketSegment: event.target.value });
    }

  render() {

    const {
      labels,
      quoteList,
      totalPrice,
      lastCartName,
      hasItemsInCart,
      showAddToQuote,
      showAddToCartNotice,
      isNewWishlist,
      showAddToCartWarning,
      onAddToQuote,
      onCloseNotice,
      onCloseWarning,
        allowedToAdd,
        activeQuoteId,
        activeQuoteProjectName,
        marketSegments,
        isAvailableForPurchase
    } = this.props;

      const {
          projectName,
          zipCode,
          marketSegment
      } = this.state;


      const quoteURL = "/quote-details-page/?quoteId=" + lastCartName;

    return (
      <Fragment>

        <div className="product__footer--configure">
          <div className="product__footer-right">
            <div className="product__footer-total">

                        {labels.loginReminder ? (<p className="weight--bold align--right product__loginReminder">Please <a href="/sign-in">log in</a> to get your account pricing</p>) : (<p></p>)}

                        

                        {labels.invernotyIsLive ? (<p className="text--danger align--right">{labels.invernotyIsLive}</p>): null}

                
            </div>
            <div className="speedShop__buttons align--right">

              <div className="cartItemGroup__loader loader loader--inline">{labels.addingLoader}</div>

                        {
                            isAvailableForPurchase ? (
                              <button 
                                type="addToCart" 
                                className="button button--solid product__addToCartButton"
                                disabled={!hasItemsInCart || !allowedToAdd}
                                onClick={this.onAddToCartClick}>{labels.addTo.prefix} {labels.addTo.cart}</button>
                            ) : null
                        }
             

              {showAddToQuote && (

                <div className="inputGroup inputGroup--compact">

                                {activeQuoteId && activeQuoteProjectName ? (

                                    <button
                                        data-cart-name="ShoppingCart"
                                        className={"button " + (quoteList.length ? 'product__createNewQuote__options' : 'product__createNewQuote')}
                                        disabled={!hasItemsInCart || !allowedToAdd}

                                        onClick={e => {
                                            e.preventDefault();
                                            onAddToQuote(activeQuoteId);
                                        }}

                                    >Add To {activeQuoteProjectName}</button>

                                ) :
                                    <button
                                        data-cart-name="ShoppingCart"
                                        className={"hoverButton button " + (quoteList.length ? 'product__createNewQuote__options' : 'product__createNewQuote')}
                                        disabled={!hasItemsInCart || !allowedToAdd}

                                    >Add To Quote</button>}



                   
                      <Fragment>
                        <button 
                          className="inputGroup--last dropdownTrigger--click padX--small button" 
                          title="More options">
                            <i className="fa fa-caret-down"></i>
                        </button>

                        <div className="dropdown" id="dropdown-1" aria-expanded="false" aria-hidden="true">

                                <Popup trigger={

                                    <button
                                        data-cart-name="ShoppingCart"
                                        className="button"
                                        disabled={!hasItemsInCart || !allowedToAdd}

                                    >{labels.createNewQuote}</button>

                                } modal>

                                    {close => (
                                        <div className="quoteModal">

                                            <div className="quoteModelClose" onClick={close}>
                                                x close
                                            </div>

                                            <form onSubmit={(event) => { this.onCreateQuoteClick(event); close(); }}>
                                                <label>Enter Project Name</label>
                                                <br />
                                                <input required type="text" value={projectName} onChange={this.handleChange} />
                                                <br />
                                                <br />
                                                <label>Project Zip Code</label>
                                                <br />
                                                <input required type="text" value={zipCode} onChange={this.handleChangeZipCode} />
                                                <br />
                                                <br />
                                                {marketSegments ? (
                                                    <div>
                                                        <label>Enter Market Segment</label>
                                                        <br />
                                                        <select value={marketSegment} onChange={this.handleChangeMarketSegment}>

                                                            <option value="">Please select Market Segment</option>

                                                            {marketSegments.map((q) => (
                                                                <option value={q.Text}>{q.Text}</option>
                                                            ))}
                                                        </select>

                                                        <br />
                                                        <br />
                                                    </div>
                                                ) : null}
                                                <input type="submit" value="Add New Quote" />
                                            </form>

                                        </div>

                                    )}
                                </Popup>                        

                        {quoteList.length ? (
                              quoteList.map((q) => (
                                <button 
                                  key={q.QuoteNumber}
                                  onClick={e => {
                                    e.preventDefault();
                                    onAddToQuote(q.QuoteId);
                                  }}
                                  disabled={!hasItemsInCart || !allowedToAdd}
                                  >{labels.addTo.prefix} {q.ProjectName ? q.ProjectName : q.QuoteDisplayNumber}</button>
                              ))
                         ) : null}
                        </div>

                      </Fragment>
                    


                </div>
              )}


            </div>
          </div>

                {labels.ifVariantOutOfStockHeader || labels.ifVariantOutOfStockBody ? (
                    
                    <div className="product__footer-left">
                        <p><b>{labels.ifVariantOutOfStockHeader}</b><br />{labels.ifVariantOutOfStockBody}</p>
                    </div>

                ) : null}      

        </div>
            {showAddToCartNotice ? (
                <div
                    
                    className="notice notice--success notice--label">

                        <a href={quoteURL}>

                        <div className="notice__label">{isNewWishlist ? fillTemplate(this.addedToNewWishlistTemplate, { lastCartName }) : fillTemplate(this.addedToCartTemplate, { lastCartName })}</div>

                        </a>
                        <span onClick={onCloseNotice} className="notice__delete">+</span>
                </div>
            ) : null}
            {showAddToCartWarning ? (
                <div
                    onClick={onCloseWarning}
                    className="notice notice--warning notice--label">
                    <div className="notice__label">Something went wrong</div><span className="notice__delete">+</span>
                </div>
            ) : null}
      </Fragment>      
    );

  }
}

export default Footer;