import React, { Component } from 'react';
import { connect } from 'react-redux';
import InproProvider from './../InproProvider';

import {
  initSeminarList,
  updateSeminarPageListItem,
  updateSettings,
} from '../../actions/seminarPageActionCreators';

class SeminarList extends Component {

  constructor(props) {
    super(props);

    const {
      initList,
      updateSettings,
      initialData = {},
    } = this.props;

    updateSettings({
      formAnchor: initialData.formAnchor,
      tabsContextId: initialData.tabsContextId,
    });
    initList(initialData.seminars);

  }

  onSeminarSelect = (seminar) => {
    const {
      updateListItem,
    } = this.props;

    updateListItem(seminar, {
      selected: true,
    });


  }

  render() {
    const {
      list,
      labels,
      initialData,
    } = this.props;

    const seminarsToShow = list.length ? list : initialData.seminars;
    const isReduxLoaded = list.length;

      const itemsThatAreSelected = seminarsToShow.filter(s => s.selected);
      const itemsThatAreSelectedLength = itemsThatAreSelected ? itemsThatAreSelected.length : 0;

      const anyItemsSelected = itemsThatAreSelectedLength == 0 ? false : true;

      const isRequestCatalog = initialData.isRequestCatalog;

    return (
      <section className="seminar-page__list width--layout">
        {seminarsToShow.map((seminar, i) => (
          <article key={i} className="seminar-page__list-item">
            <div className="seminar-page__list-item__image-wrapper">
              <div className="seminar-page__list-item__image-inner" style={{backgroundImage: "url(" + seminar.image + ")"}}>
                <img src={seminar.image} alt="seminar image"/>
              </div>
            </div>
            <div className="seminar-page__list-item__content">
              <h3 className="seminar-page__list-item__header">{seminar.title}</h3>
              <div className="seminar-page__list-item__description" dangerouslySetInnerHTML={{__html: seminar.description}}/>
              {isReduxLoaded ? (
                <button
                className={"button "+ seminar.buttonText.toLowerCase().replace(" ","-")}
                disabled={isRequestCatalog ? seminar.selected : anyItemsSelected}
                onClick={seminar.selected ? (e) => {
                  e.preventDefault();
                } : (e) => {
                  e.preventDefault();
                  this.onSeminarSelect(seminar);
                }}>{seminar.buttonText}</button>
              ) : null}
            </div>
          </article>
        ))}
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.seminarPage.list,
    settings: state.seminarPage.settings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    initList: (list) => dispatch(initSeminarList(list)),
    updateListItem: (item, props, cb) => dispatch(updateSeminarPageListItem(item, props, cb)),
    updateSettings: (settings) => dispatch(updateSettings(settings)),
  }
}

SeminarList = connect(mapStateToProps, mapDispatchToProps)(SeminarList)

export default (props) => (
  <InproProvider>
    <SeminarList {...props}/>
  </InproProvider>
);
