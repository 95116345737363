import React, { Component } from 'react';
import DateField from '../Common/DateField/';

class OrderHistorySearchForm extends Component {

  state = {
    isFocused: false,
  }

  onButtonClick = (e) => {
    e.preventDefault();

    this.props.onButtonClick();
  }

  onQueryChange = (e) => {
    this.props.onQueryChange(e.currentTarget.value);
  }

  onKeyPress = (event) => {
    if(event.key == 'Enter'){
      this.props.onButtonClick();
    }
  }

  render() {

    const {
      query,
      labels,
      endDate,
      startDate,
      onEndDateChange,
      onStartDateChange,
      onButtonClick,
      onDateButtonClick,
    } = this.props;

    return (
      <div className="order-history-search-form">
        <div className="order-history-search-form__field-group">
          <label className="order-history-search-form__date-field-label">
            <span class="order-history-search-form__date-field-text">{labels.startDate}:</span>
            <DateField
              className="order-history-search-form__date-field" selected={startDate}
              onChange={onStartDateChange}/>
          </label>
          <label className="order-history-search-form__date-field-label">
            <span class="order-history-search-form__date-field-text">{labels.endDate}:</span>
            <DateField
              className="order-history-search-form__date-field"
              selected={endDate}
              onChange={onEndDateChange}/>
          </label>
          <button
            className="order-history-search-form__search-by-date-range-button"
            onClick={onDateButtonClick}>Go</button>
        </div>
        <div className="order-history-search-form__field-group">
          <label className="order-history-search-form__query-field-label">{labels.queryPlaceholder}</label>
          <input
            className="order-history-search-form__query-field"
            type="text"
            id="orderSearchQueryInput"
            value={query}
            onChange={this.onQueryChange}
            placeholder={labels.queryPlaceholder}
            onKeyPress={this.onKeyPress}/>
          <button
            className="order-history-search-form__search-by-query-button" onClick={onButtonClick}>Go</button>
        </div>
      </div>
    );
  }
}

export default OrderHistorySearchForm;
