import React from 'react';
import classNames from 'classnames';

export const ModalForm = ({children, onSubmit}) => (
  <form className="inpro-modal-form" onSubmit={onSubmit}>
    {children}
  </form>
);

export const ModalFormFieldset = ({children}) => (
  <div className="inpro-modal-form__fieldset">
    {children}
  </div>
);

export const ModalFormItemLabel = ({children, labelFor}) => (
  <label className="inpro-modal-form__label" htmlFor={labelFor}>
    {children}
  </label>
);

export const ModalFormItem = ({
  wide,
  required,
  children,
}) => {
  const classes = classNames('inpro-modal-form__item', {
    'inpro-modal-form__item--required': required,
    'inpro-modal-form__item--wide': wide,
  })

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

export default ModalForm;
