import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

import initStore from './init/initStore';
import ProductPageHeader from './components/ProductPage/Header/';
import NewProductPageHeader from './components/ProductPage/Header/NewHeader.js';
import ProductPageConfiguration from './components/ProductPage/Configuration';
import DocumentsList from './components/ProductPage/DocumentsList/';
import Document from './components/ProductPage/DocumentsList/document.js';
import ColorAndFabricSearch from './components/ColorAndFabricSearch/';
import DocumentSearchPage from './components/DocumentSearchPage';
import OrderHistoryPage from './components/OrderHistoryPage';
import SeminarList from './components/SeminarPage/SeminarList';
import CheckoutShippingMethods from './components/CheckoutShippingMethods/';
import SelectedSeminars from './components/SeminarPage/SelectedSeminars';
import CheckoutTaxCertSelectionForm from './components/CheckoutTaxCertSelectionForm';
import MyQuotesPage from './components/MyQuotesPage';
import QuoteDetailsList from './components/QuoteDetailsPage/QuoteDetailsList';
import QuoteDetailsSummary from './components/QuoteDetailsPage/QuoteDetailsSummary';
import QuoteDetailsActions from './components/QuoteDetailsPage/QuoteDetailsActions';
import QuoteDetailsShippingMethod from './components/QuoteDetailsPage/QuoteDetailsShippingMethod';
import QuoteDetailsHeader from './components/QuoteDetailsPage/QuoteDetailsHeader';
import QuotePrintPage from './components/QuotePrintPage';
import AddCartToQuote from './components/AddCartToQuote';
import OrderCancel from './components/OrderCancel';
import Gallery from './components/shared/Gallery.js';
import QuickOrderToQuote from './components/QuickOrderToQuote';

import './index.scss';
import './shims/polyfills';

global.React          = React;
global.ReactDOM       = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

window['inproStore']  = global['inproStore'] = initStore();

window['ProductPageHeader'] = global['ProductPageHeader'] = ProductPageHeader;
window['NewProductPageHeader'] = global['NewProductPageHeader'] = NewProductPageHeader;
window['ProductPageConfiguration'] = global['ProductPageConfiguration'] = ProductPageConfiguration;
window['DocumentsList'] = global['DocumentsList'] = DocumentsList;
window['Document'] = global['Document'] = Document;
window['ColorAndFabricSearch'] = global['ColorAndFabricSearch'] = ColorAndFabricSearch;
window['DocumentSearchPage'] = global['DocumentSearchPage'] = DocumentSearchPage;
window['OrderHistoryPage'] = global['OrderHistoryPage'] = OrderHistoryPage;
window['SeminarList'] = global['SeminarList'] = SeminarList;
window['SelectedSeminars'] = global['SelectedSeminars'] = SelectedSeminars;
window['CheckoutShippingMethods'] = global['CheckoutShippingMethods'] = CheckoutShippingMethods;
window['CheckoutTaxCertSelectionForm'] = global['CheckoutTaxCertSelectionForm'] = CheckoutTaxCertSelectionForm;

// Quotes
window['MyQuotesPage'] = global['MyQuotesPage'] = MyQuotesPage;
window['QuoteDetailsList'] = global['QuoteDetailsList'] = QuoteDetailsList;
window['QuoteDetailsSummary'] = global['QuoteDetailsSummary'] = QuoteDetailsSummary;
window['QuoteDetailsActions'] = global['QuoteDetailsActions'] = QuoteDetailsActions;
window['QuoteDetailsShippingMethod'] = global['QuoteDetailsShippingMethod'] = QuoteDetailsShippingMethod;
window['QuoteDetailsHeader'] = global['QuoteDetailsHeader'] = QuoteDetailsHeader;
window['QuotePrintPage'] = global['QuotePrintPage'] = QuotePrintPage;
window['AddCartToQuote'] = global['AddCartToQuote'] = AddCartToQuote;
window['OrderCancel'] = global['OrderCancel'] = OrderCancel;
window['QuickOrderToQuote'] = global['QuickOrderToQuote'] = QuickOrderToQuote;

window['Gallery'] = global['Gallery'] = Gallery;