import {
  SET_QUOTE_DETAILS_PAGE_QUOTE,
} from '../../actions/types';

export default (state = null, action) => {
  switch(action.type) {
    case SET_QUOTE_DETAILS_PAGE_QUOTE:
      return Object.assign({}, state, action.quote);
    default:
      return state;
  }
}