import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class SearchQuery extends Component {

  state = {
    isFocused: false,
  }

  onBlur = (e) => {
    this.setState({
      isFocused: false,
    })
  }

  onFocus = (e) => {
    this.setState({
      isFocused: true,
    })
  }

  onButtonClick = (e) => {
    e.preventDefault();

    this.props.onButtonClick();
  }

  onChange = (e) => {
    this.props.onQueryChange(e.currentTarget.value);
  }

  onKeyPress = (event) => {
    if(event.key == 'Enter'){
      this.props.onButtonClick();
    }
  }

  render() {

    const {
      isFocused,
    } = this.state;

    const {
      query,
      placeholder,
      buttonLabel,
      label,
      wideLabel,
    } = this.props;

    const groupClasses = classNames('form-group', {
      'focused': isFocused,      
    });

    const containerClasses = classNames('search-form', {
      'search-form--wide-label': wideLabel,
    })

    return (
      <div className={containerClasses}>
        <div className="search-form__group-wrapper">        
          <div className={groupClasses}>
            <input 
              type="text" 
              id="searchQueryInput" 
              value={query}
              onChange={this.onChange}
              placeholder={placeholder}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              onKeyPress={this.onKeyPress}/>
            <label htmlFor="searchQueryInput">{label}</label>
          </div>
          <button className="button button--white" onClick={this.onButtonClick}>{buttonLabel}</button>
        </div>
      </div>
    );    
  }
}

SearchQuery.propTypes = {
  query: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  wideLabel: PropTypes.bool,
  onButtonClick: PropTypes.func,
  onQueryChange: PropTypes.func,
};

SearchQuery.defaultProps = {
  query: '',
  placeholder: 'Enter keywords, product name, sku or category',
  label: 'looking for a specific item?',
  buttonLabel: 'Search',
  wideLabel: false,
  onButtonClick: () => {},
  onQueryChange: () => {},
};

export default SearchQuery;