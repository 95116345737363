import React, { Component } from 'react';
import { connect } from 'react-redux';

import InproProvider from './../InproProvider';
import Cloak from '../Common/Cloak';
import OrderTable from './OrderTable';
import SearchForm from './SearchForm';

import {
  setOrders,
  searchByQuery,
  updateSettings,
  searchByDefault,
  searchByDateRange,
} from '../../actions/orderHistoryPageActionCreators';

class OrderHistoryPage extends Component {

  constructor(props) {
    super(props);

    const {
      initialData = {},
      setOrders,
    } = this.props;

    this.state = ({
      isInternational: initialData.isInternational
    })

    setOrders(initialData.orders ? initialData.orders : []);

  }

  onQueryChange = (query) => {
    const {
      updateSettings,
    } = this.props;

    updateSettings({
      query,
    });
  }
  onStartDateChange = (startDate) => {
    const {
      updateSettings,
    } = this.props;

    updateSettings({
      startDate,
    });
  }

  onEndDateChange = (endDate) => {
    const {
      updateSettings,
    } = this.props;

    updateSettings({
      endDate,
    });
  }

  onSearch = () => {
    const {
      searchByQuery,
    } = this.props;

    searchByQuery();
  }

  onDateSearch = () => {
    const {
      searchByDateRange,
    } = this.props;

    searchByDateRange();
  }

  onResetClick = () => {
    const {
      searchByDefault,
    } = this.props;

    searchByDefault();
  }

  onSortClick = (sortBy, sortDirectionDown) => {
    const {
      updateSettings,
    } = this.props;

    updateSettings({
      sortBy,
      sortDirectionDown,
    });
  }

  render() {
    const {
      orders,
      labels,
      settings: {
        query,
        endDate,
        startDate,
        isLoading,
        dirty,
        sortBy,
        sortDirectionDown,
      },
      updateSettings,
    } = this.props;

    return (
      <section className="">
        <SearchForm
          labels={labels}
          query={query}
          startDate={startDate}
          endDate={endDate}
          onQueryChange={this.onQueryChange}
          onStartDateChange={this.onStartDateChange}
          onEndDateChange={this.onEndDateChange}
          onButtonClick={this.onSearch}
          onDateButtonClick={this.onDateSearch}/>

        <Cloak active={isLoading}>
          {orders.length ? (
            <OrderTable 
              orders={orders} 
              labels={labels} 
              sortBy={sortBy}
              sortDirectionDown={sortDirectionDown}
              onHeadingClick={this.onSortClick}
              isInternational={this.state.isInternational}/>
          ) : null}
        </Cloak>
        {dirty ? (<button className="order-history-search-form__reset-button" onClick={this.onResetClick}>Reset form</button>) : null}
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orderHistoryPage.list,
    settings: state.orderHistoryPage.settings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrders: (list) => dispatch(setOrders(list)),
    searchByQuery: (query) => dispatch(searchByQuery(query)),
    searchByDefault: () => dispatch(searchByDefault()),
    searchByDateRange: () => dispatch(searchByDateRange()),
    updateSettings: (settings) => dispatch(updateSettings(settings)),
  }
}


OrderHistoryPage = connect(mapStateToProps, mapDispatchToProps)(OrderHistoryPage);

export default (props) => (
  <InproProvider>
    <OrderHistoryPage {...props}/>
  </InproProvider>
);
