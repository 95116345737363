import React, { Component } from 'react';


class Checkbox extends Component {
  constructor(props) {
    super(props);
  }

  onChange = (e) => {
    const {
      onChange,
    } = this.props;

    onChange(e.currentTarget.value);
  }

  render() {

    const {
      name,
      value,
      checked,
      children,
    } = this.props;

    return (
      <div className="inpro-checkbox">
        <input 
          className="inpro-checkbox__field" 
          type="checkbox" 
          id={name}
          value={value}
          checked={checked}
          onChange={this.onChange}/>
        <label 
          className="inpro-checkbox__label" 
          htmlFor={name}>{children}</label>
      </div>
    );
  }
}

Checkbox.defaultProps = {
  onChange: () => {},
};

export default Checkbox;