import React from "react";
import styled from "styled-components";


const IMAGE_DIM = {
    w: 500,
    h: 500
};

const BOX_DIM = {
    w: 300,
    h: 300
};

const MAGNIFIED_DIM = {
    w: 500,
    h: 500
};

const Relative = styled.div`
  position: relative;
`;

const Photo = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  object-fit: cover;
  cursor: crosshair;
`;

const Box = styled.div.attrs(props => ({
    style: {
        top: props.top,
        bottom: props.bottom,
        left: props.left,
        right: props.right
    }
}))`
  position: absolute;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  /* transform: translate3d(-50%, -50%, 0); */
`;

const MagnifiedOuter = styled.div`
  position: absolute;
  left: 105%;
  top: 0;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  z-index:950; 
`;

// const MagnifiedImage = styled.div`
//   width: ${props => props.width}px;
//   height: ${props => props.height}px;

//   background-image: url(${props => props.src});
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   transform: translate3d(
//     ${props => props.left * -1}%,
//     ${props => props.top * -1}%,
//     0
//   );
// `;

// const Component = styled.div.attrs({
//   style: ({ background }) => ({
//     background,
//   }),
// })`width: 100%;`
// Functions as object - form attrs({}) keys are now deprecated and will be removed in a future version of styled - components.
// Switch to the new attrs(props => ({})) syntax instead for easier and more powerful composition.The attrs key in question is "style" on component "styled.img".
const MagnifiedImage = styled.img.attrs(props => ({
    style: {
        transform: `translate3d(${props.left * -1}%, ${props.top * -1}%, 0)`
    }
}))`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  object-fit: cover;
`;

const Magnified = props => {
    //IMAGE_DIM.w : BOX_DIM.w === BG_W : MAGNIFIED_DIM.w
    // BG_W = IMAGE_DIM.w * MAGNIFIED_DIM.w / BOX_DIM.w
    const { src, boxCenter } = props;
    const BG_DIM = {
        w: IMAGE_DIM.w * (MAGNIFIED_DIM.w / BOX_DIM.w),
        h: IMAGE_DIM.h * (MAGNIFIED_DIM.h / BOX_DIM.h)
    };

    // ((boxCenter.x - IMAGE_DIM.w / 2) / IMAGE_DIM.w) * 100

    return (
        <MagnifiedOuter className="magnifiedImageContainer" width={MAGNIFIED_DIM.w} height={MAGNIFIED_DIM.h}>
            <MagnifiedImage
                src={src}
                width={BG_DIM.w}
                height={BG_DIM.h}
                top={((boxCenter.y - BOX_DIM.h / 2) / IMAGE_DIM.h) * 100}
                left={((boxCenter.x - BOX_DIM.w / 2) / IMAGE_DIM.w) * 100}
            />
        </MagnifiedOuter>
    );
};

export default function Magnifier(props) {
    const [coordinates, setCoordinates] = React.useState(null);

    const {
        photo,
        photoAlt
        } = props

    function handleMouseMove(e) {
        const bounds = e.target.getBoundingClientRect();
        const left = e.clientX - bounds.left;
        const top = e.clientY - bounds.top;

        // If better perf is needed, consider using ref instead of state
        setCoordinates({
            x: Math.min(Math.max(left, BOX_DIM.w / 2), bounds.width - BOX_DIM.w / 2),
            y: Math.min(Math.max(top, BOX_DIM.h / 2), bounds.height - BOX_DIM.h / 2)
        });
    }

    function handleMouseLeave() {
        setCoordinates(null);
    }

    return (
        <Relative>
            <Photo className="magnifierProductImage"
                src={photo}
                alt={photoAlt }
                width={IMAGE_DIM.w}
                height={IMAGE_DIM.h}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
            />
            {coordinates !== null && (
                <Box
                    width={BOX_DIM.w}
                    height={BOX_DIM.h}
                    top={`${coordinates.y - BOX_DIM.h / 2}px`}
                    left={`${coordinates.x - BOX_DIM.w / 2}px`}
                />
            )}

            {coordinates !== null && (
                <Magnified src={photo} boxCenter={coordinates} />
            )}
        </Relative>
    );
}
