import React, { Component, Fragment } from 'react';
import Popup from 'reactjs-popup';
import Cloak from './../Common/Cloak';

import {
    addCartToQuote,
    updateQuoteList,
} from '../../services/quotesApiService';

class AddCartToQuote extends Component {
  constructor(props) {
      super(props);

      this.state = {
          projectName: '',
          updatedQuoteList: [],
          showAddToCartNotice: false,
          showTheSpinner: false,
          lastCartName: '',
          zipCode: '',
          marketSegment: ''
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleChangeZipCode = this.handleChangeZipCode.bind(this);
      this.handleChangeMarketSegment = this.handleChangeMarketSegment.bind(this);
     
  }

    addToQuote = (quoteId, projectName, zipCode, marketSegment) => {

        //const {
            //lastCartName
        //} = this.state;
     
        this.setState({ showTheSpinner: true });


        addCartToQuote(quoteId, projectName, zipCode, marketSegment).then(data => {

            if (data) {

                //if (data.Success.Item2) {
                    //this.setState({ lastCartName: data.Success.Item2 });
                //}

                if (data.Success.Item1) {

                    if (data.Success.Item2) {
                        window.location.href = window.location.pathname + "?" + window.$.param({
                            quoteId: data.Success.Item2
                        });
                    }

                }

                //Update Mini Quote Hover
                //window.WOW.MiniQuoteCart.refresh();

                //Update Quote List
                //updateQuoteList().then(data2 => {

                    //if (data2) {

                        //this.setState({ updatedQuoteList: data2 });
                    //}

                //});

                //this.setState({ showAddToCartNotice: true });

            }

            //this.setState({ showTheSpinner: false });

            //For Errors if (!data || !data.Success) {
                //window.notificationService.show(data.Message, true, true);
                //return;
            //}




        });

      

    };

    onCreateQuoteClick = (e) => {
        e.preventDefault();

      


        const {
            projectName,
            zipCode,
            marketSegment
        } = this.state;

        const {
            marketSegments
        } = this.props.initialData;

        const quoteId = "";

   

        this.addToQuote(quoteId, projectName, zipCode, marketSegment);

        this.setState({ projectName: '' });
        this.setState({ zipCode: '' });
        this.setState({ marketSegment: '' });
    }


    onCloseNotice = () => {
        
        this.setState({ showAddToCartNotice: false });
    }


    handleChange(event) {
        this.setState({ projectName: event.target.value });
    }
    handleChangeZipCode(event) {
        this.setState({ zipCode: event.target.value });
    }
    handleChangeMarketSegment(event) {
        this.setState({ marketSegment: event.target.value });
    }





  render() {

    const {
      initialData = {},
        labels = {},
    } = this.props;

      const {
          projectName,
          updatedQuoteList,
          showAddToCartNotice,
          showTheSpinner,
          lastCartName,
          zipCode,
          marketSegment
      } = this.state;

      const quoteList = updatedQuoteList.length ? updatedQuoteList : initialData.QuoteList;

      const marketSegments = initialData.marketSegments;

      const quoteURL = "/quote-details-page/?quoteId=" + lastCartName;

      const message = 'Products were added to the quote. Click here to see quote.';

      return (
         
              
          <Cloak active={showTheSpinner} showSpinner>

      <div className="inputGroup inputGroup--compact">




            <Popup trigger={

                <button
                    
                    className="button shoppingCart-CreateNewQuote"
                    

                >{labels.createNewQuote}</button>

            } modal>

                {close => (
                    <div className="quoteModal">

                        <div className="quoteModelClose" onClick={close}>
                            x close
                        </div>

                              <form onSubmit={(event) => { this.onCreateQuoteClick(event); close(); }}>
                                  <label>Enter Project Name</label>
                                  <br />
                                  <input required type="text" value={projectName} onChange={this.handleChange} />
                                  <br />
                                  <br />
                                  <label>Project Zip Code</label>
                                  <br />
                                  <input required type="text" value={zipCode} onChange={this.handleChangeZipCode} />
                                  <br />
                                  <br />
                                  {marketSegments ? (
                                      <div>
                                          <label>Enter Market Segment</label>
                                          <br />
                                          <select value={marketSegment} onChange={this.handleChangeMarketSegment}>

                                              <option value="">Please select Market Segment</option>

                                              {marketSegments.map((q) => (
                                                  <option value={q.Text}>{q.Text}</option>
                                              ))}
                                          </select>
                                          <br />
                                          <br />
                                      </div>
                                  ) : null}
                                  <input type="submit" value="Create New Quote" />
                              </form>

                    </div>

                )}
            </Popup>




          {quoteList.length ? (
            <Fragment>
              <button 
                className="inputGroup--last dropdownTrigger--click padX--small button shoppingCart-AddToQuote" 
                title="More options">
                  <i className="fa fa-caret-down"></i>
              </button>

              <div className="dropdown" id="dropdown-1" aria-expanded="false" aria-hidden="true">

                {quoteList.map((q) => (
                  <button 
                    key={q.QuoteId}
                    onClick={e => {
                      e.preventDefault();
                      this.addToQuote(q.QuoteId, "");
                    }}
                    >{labels.addTo.prefix} {q.ProjectName ? q.ProjectName : q.QuoteDisplayNumber}</button>
                ))}

              </div>

            </Fragment>
          ) : null}


                 

              </div>


              {showAddToCartNotice ? (
                  <div

                      className="notice notice--success notice--label">

                      <a href={quoteURL}>

                          <div className="notice__label">{message}</div>

                      </a>

                      <span onClick={this.onCloseNotice} className="notice__delete">+</span>

                  </div>
              ) : null}

             

          </Cloak>
    );
  }
}

export default AddCartToQuote;