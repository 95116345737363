import React from "react";
import DatePicker from "react-datepicker";

class Example extends React.Component {
  render() {
    return (
      <DatePicker 
        dateFormat="MM/dd/yyyy"
        shouldCloseOnSelect={true}
        selected={this.props.selected}
        onChange={this.props.onChange}/>
    );
  }
}


export default Example;