import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';

ReactModal.setAppElement('body');

class Modal extends Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    const {
      size,
      isOpen,
      children, 
      onClose,
    } = this.props;

    const classes = classNames('inpro-modal-dialog', `inpro-modal-dialog--${size}`);

    return (
      <ReactModal 
        isOpen={isOpen} 
        className={classes}
        overlayClassName="inpro-modal-overlay"
        onRequestClose={onClose}
        >
        {children}
      </ReactModal>
    );
  }
}

Modal.defaultProps = {
  size: 'xs',
}

Modal.propTypes = {
  size: PropTypes.oneOf(['xs', 'md', 'lg']),
}

export default Modal;
