import fetch from 'cross-fetch';

import {
  UPDATE_DOCUMENTS_SEARCH_PAGE_SETTINGS,
  SET_DOCUMENTS_SEARCH_PAGE_DOCUMENTS,
} from './types';

export const setDocumentList = (list = []) => ({
  type: SET_DOCUMENTS_SEARCH_PAGE_DOCUMENTS,
  list,
});

export const appendDocumentsToList = (listToAppend = []) => {
  return (dispatch, getState) => {
    const {
      documentSearchPage: {
        list,
      }
    } = getState();

    dispatch(setDocumentList([
      ...list,
      ...listToAppend,
    ]));
  }
};

export const updateSettings = (settings = {}) => ({
  type: UPDATE_DOCUMENTS_SEARCH_PAGE_SETTINGS,
  settings,
});

export const search = (loadMore = false) => {
  return (dispatch, getState) => {
    const {
      documentSearchPage: {
        list,
        settings: {
          page,
          query,
          mainCategorySelectedOption,
          subCategorySelectedOptions,
          productTypesSelectedOptions,
          documentTypeSelectedOptions,
          languageTypeSelectedOptions,
          installationInstructionsOnly,
          BIMObjectsOnly,
        }
      }
    } = getState();

    const recordsSkip = loadMore ? page * 10 : 0;

    const subCategories = subCategorySelectedOptions || [];
    const productTypes = productTypesSelectedOptions || [];
      var documentTypes = documentTypeSelectedOptions || [];

      if (installationInstructionsOnly) {
          documentTypes = ["Installation Instructions"];
      }
      if (BIMObjectsOnly) {
          documentTypes = ["BIM"];
      }
    const languages = languageTypeSelectedOptions || [];

    const body = {
      searchQuery: query,
      recordsSkip,
      isRefresh: false,
      isProductRelated: false,
      mainCategory: mainCategorySelectedOption,
      documentTypes,
      subCategories,
      productTypes,
      languages,
    };

    dispatch(updateSettings({
      isLoading: true,
    }));

    fetch('/SearchDocument/GetSearchResults/', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then(res => res.status === 200 ? res.json() : null).then(data => {
      if (data) {
        if (loadMore) {
          dispatch(appendDocumentsToList(data.Documents));
        } else {
          dispatch(setDocumentList(data.Documents));
        }
          
        dispatch(updateSettings({
          total: data.TotalMatching,
          documentTypeList: data.DocumentTypeSelectList,
          documentTypeSelectedOptions: updateSelectedOptions(data.DocumentTypeSelectList, documentTypeSelectedOptions),
          subCategoryList: data.SubCategorySelectlist,
          subCategorySelectedOptions: updateSelectedOptions(data.SubCategorySelectlist, subCategorySelectedOptions),

            productTypesList: data.ProductTypesSelectlist,
            productTypesSelectedOptions: updateSelectedOptions(data.ProductTypesSelectlist, productTypesSelectedOptions),

          languageTypeList: data.LanguageTypeSelectlist,
          languageTypeSelectedOptions: updateSelectedOptions(data.LanguageTypeSelectlist, languageTypeSelectedOptions),
          page: loadMore ? page + 1 : 1,
          dirty: true,
        }));
      }
      dispatch(updateSettings({
        isLoading: false,
      }));
    })
  }
}

const updateSelectedOptions = (options, selectedOptions) => {
  if (!selectedOptions) {
    //preventing null reference exeption. (react-select empty state)
    return [];
  }

    if (typeof selectedOptions === 'string' || selectedOptions instanceof String) {
        return selectedOptions.split('?').filter(s => options.find(o => o.Value === s));
    }

  return selectedOptions.filter(s => options.find(o => o.Value === s));
}