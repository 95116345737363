import React from 'react';

const Table = ({
  children
}) => (
  <table className="my-quotes-table">
    {children}
  </table>
);

export default Table;