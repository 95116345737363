import React, { Component } from 'react';
import { connect } from 'react-redux';

import { 
  setProductConfigSteps,
  setProductVariantsList,
  updateProductPageVariant,
  updateProductPageSettings,
  setProductConfigColorStep, 
  setProductConfigPaletteStep,
  resetProductPageVariantList,
  addProductPageVariantsToCart,
  addProductPageVariantsToQuote,
  selectProductConfigStepOption,
  selectProductConfigColorStepOption,
  selectProductConfigPaletteStepOption,
 } from './../../../actions/productPageActionCreators';

import InproProvider from './../../InproProvider';
import Step from './Step';
import ColorStep from './ColorStep';
import ShopProductTable from './../../Common/ShopProductTable';
import Cloak from './../../Common/Cloak';
import NumberedContainer from './../../Common/NumberedContainer/';

class Configuration extends Component {

  constructor(props) {
    super(props);

    const {
      Steps,
      Variants,
      ColorStep,
      PaletteStep,
      MinimumPurchaseAmount,
      tableLabels,
      QuoteList,
        isGuestUser,
        isAvailableForPurchase,
        isPortal,
        MarketSegments,
        activeQuoteId,
        activeQuoteProjectName,
    } = props.initialConfigurationData;

    this.state = {
      initialSteps: Steps,
      initialVariants: Variants,
      initialColorStep: ColorStep,
      initialPaletteStep: PaletteStep,
      quoteList: QuoteList ? QuoteList : [],
      marketSegments: MarketSegments ? MarketSegments : [],
      minimumPurchaseAmount: MinimumPurchaseAmount,//TODO: move to store if needed. TBD.
      tableLabels,
      isGuestUser,
        isPortal,
        activeQuoteId,
        activeQuoteProjectName,
        isAvailableForPurchase,
    };
  }

  componentDidMount() {
    const {
      steps,
      variants,
      colorStep,
      paletteStep,
      setSteps,
      setVariants,
      setColorStep,
      setPaletteStep,
      initialConfigurationData,
    } = this.props;

    if (!colorStep && initialConfigurationData) {
      const {
        ColorStep,
      } = initialConfigurationData;
      
      setColorStep(ColorStep || null);
    }

    if (!steps && initialConfigurationData) {
      const {
        Steps,
      } = initialConfigurationData;
      
      setSteps(Steps || []);
    }

    if (!paletteStep && initialConfigurationData) {
      const {
        PaletteStep,
      } = initialConfigurationData;
      
      setPaletteStep(PaletteStep || null);
    }

    if (!variants && initialConfigurationData) {
      const {
        Variants,
      } = initialConfigurationData;
      
      setVariants(Variants || []);
    }

  }

  checkIsLoading = () => {
    const { 
      settings: {
        isColorStepLoading,
        isVariantsLoading,
      },
    } = this.props;

    return isColorStepLoading || isVariantsLoading;

  }

  onOptionChange = (step, value) => {
    const { selectStepOption } = this.props;
    if (!this.checkIsLoading()) selectStepOption(step, value);
  }

    onColorSelect = (value, imageVar, ColorAndSKUFilter, name) => {
      const { selectColor, initialConfigurationData } = this.props;
      if (!this.checkIsLoading()) selectColor(value, initialConfigurationData.showStockAvailability);
  }

  onPaletteSelect = (name, value) => {
    const { selectPalette } = this.props;
    if (!this.checkIsLoading()) selectPalette(value);
  }
    onVariantSelect = (variant) => {
        const { selectVariant, resetVariants } = this.props;
        if (!this.checkIsLoading()) resetVariants(variant);

        if (!this.checkIsLoading()) selectVariant(variant);

    }

  onCloseNotice = () => {
    const settings = {
      showAddToCartNotice: false,
      lastCartName: '',
    };

    const {
      updateSettings,
    } = this.props;

    updateSettings(settings);
  }

  onCloseWarning = () => {
    const settings = {
      showAddToCartWarning: false,
    };

    const {
      updateSettings,
    } = this.props;

    updateSettings(settings);
  }

  onAddToCart = (cartName, cartLabel) => {
    const {
      updateSettings,
      addVariantsToCart,
    } = this.props;

    addVariantsToCart(cartName, (data) => {
      if (data) {
        updateSettings({
          showAddToCartNotice: true,
          lastCartName: cartLabel,
        });
      } else {
        updateSettings({
          showAddToCartWarning: true,
          lastCartName: cartLabel,
        });
      }
    });
  }

  render() {

    const {
      steps,
      settings,
      variants,
      colorStep,      
      paletteStep,
      resetVariants,
      updateVariant,
      addVariantsToQuote,
    } = this.props;

    const {
      quoteList,
        isGuestUser,
        isAvailableForPurchase,
      isPortal,
        marketSegments,
        activeQuoteId,
        activeQuoteProjectName,
    } = this.state;

    const stepsToRender = steps || this.state.initialSteps || [];
    const colorStepToRender = colorStep || this.state.initialColorStep || null;
    const paletteStepToRender = paletteStep || this.state.initialPaletteStep || null;
    const variantsStepToRender = variants || this.state.initialVariants || [];
    const hasSelectedColor = colorStepToRender && colorStepToRender.Options ? !!colorStepToRender.Options.find(o => o.Selected) : false;
    
    const paletteStepNumber = paletteStepToRender ? stepsToRender.length + 1 : stepsToRender.length;
    const colorStepNumber = colorStepToRender ? paletteStepNumber + 1 : paletteStepNumber;
    const variantsStepNumber = colorStepNumber + 1;

      const selectedColorPalette = paletteStepToRender && paletteStepToRender.Options && paletteStepToRender.Options.length ? paletteStepToRender.Options.find(o => o.Selected) : null;
      const selectedColorPaletteName = selectedColorPalette ? selectedColorPalette.Label : [];

      const selectedVariant = variantsStepToRender && variantsStepToRender.length ? variantsStepToRender.find(o => o.Selected) : null;

      const singleVariant = variantsStepToRender && variantsStepToRender.length  ? variantsStepToRender[0] : null;

      const selectedVariantItem = selectedVariant ? selectedVariant : singleVariant;

      if (selectedVariantItem != null) {
          if (selectedVariantItem.quantity == 0) {
              selectedVariantItem.quantity = 1;
          }
      }

      const finalQuoteList = settings.updatedQuoteList.length ? settings.updatedQuoteList : quoteList;

    return (
      <div className="width--layout--configuration">

        {stepsToRender.map((step, i) => (

            
                step.Options.length > 1 ? (

                    <Step
                        number={i + 1}
                        key={step.Name}
                        {...step}
                        onChange={this.onOptionChange}
                        selectedOption={step.Options.find(o => o.Selected) ? step.Options.find(o => o.Selected).Label : []} />



                ): null
            


        ))}

        {paletteStepToRender ? (

                paletteStepToRender.Options.length > 1 ? (


                    <Step
                        number={paletteStepNumber}
                        key={paletteStepToRender.Name}
                        {...paletteStepToRender}
                        onChange={this.onPaletteSelect}
                        selectedOption={selectedColorPaletteName} />


                ): null

        ) : null}

        {colorStepToRender ? (
          <ColorStep 
            number={colorStepNumber} 
            step={colorStepToRender} 
            onChange={this.onColorSelect} 
            isLoading={settings.isColorStepLoading}/>
        ): null}
        
        {variantsStepToRender.length ? (
          <NumberedContainer number={variantsStepNumber} title={this.state.tableLabels.variantsStepTitle}>
            <Cloak active={settings.isVariantsLoading} showSpinner>
              <ShopProductTable 
                list={variantsStepToRender}
                labels={this.state.tableLabels}
                minimumPurchaseAmount={this.state.minimumPurchaseAmount}
                isColorSelected={hasSelectedColor}
                onReset={resetVariants}
                onAddToCart={this.onAddToCart}
                onAddToQuote={addVariantsToQuote}
                updateVariant={updateVariant}
                onCloseWarning={this.onCloseWarning}
                onCloseNotice={this.onCloseNotice}
                showAddToCartNotice={settings.showAddToCartNotice}
                showAddToCartWarning={settings.showAddToCartWarning}
                lastCartName={settings.lastCartName}
                isNewWishlist={settings.isNewWishlist}
                quoteList={finalQuoteList}
                showAddToQuote={!isGuestUser && !isPortal}
                steps={[...stepsToRender, paletteStepToRender, colorStepToRender]}
                onChange={this.onVariantSelect}
                selectedVariantItem = {selectedVariantItem}
                marketSegments = {marketSegments}
                activeQuoteId={activeQuoteId}
                activeQuoteProjectName={activeQuoteProjectName}
                isAvailableForPurchase={isAvailableForPurchase}
                />
            </Cloak>
          </NumberedContainer>
        ) : null}

      </div>
    );

  }

}

const mapProps = (state) => {
  return {
    settings: state.productPage.settings,
    steps: state.productPage.config.steps,
    colorStep: state.productPage.config.colorStep,
    paletteStep: state.productPage.config.paletteStep,
    variants: state.productPage.variants,
  };
}

const mapDispatch = (dispatch) => {
  return {
    setSteps: (list) => dispatch(setProductConfigSteps(list)),
    setColorStep: (step) => dispatch(setProductConfigColorStep(step)),
    setPaletteStep: (step) => dispatch(setProductConfigPaletteStep(step)),
    setVariants: (list) => dispatch(setProductVariantsList(list)),
    selectColor: (value, showStockAvailability) => dispatch(selectProductConfigColorStepOption(value, showStockAvailability)),
    selectPalette: (value) => dispatch(selectProductConfigPaletteStepOption(value)),
    selectStepOption: (step, value) => dispatch(selectProductConfigStepOption(step, value)),
    updateVariant: (variant) => dispatch(updateProductPageVariant(variant)),
    resetVariants: () => dispatch(resetProductPageVariantList()),
    addVariantsToCart: (cartName, cb) => dispatch(addProductPageVariantsToCart(cartName, cb)),
    addVariantsToQuote: (quoteId, projectName, zipCode, marketSegment) => dispatch(addProductPageVariantsToQuote(quoteId, projectName, zipCode, marketSegment)),
    updateSettings: (settings) => dispatch(updateProductPageSettings(settings)),
    selectVariant: (variant) => dispatch(updateProductPageVariant(variant)),
  };
}

Configuration = connect(mapProps, mapDispatch)(Configuration);

export default (props) => (
  <InproProvider>
    <Configuration {...props}/>
  </InproProvider>
);