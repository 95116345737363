import {
  SET_PRODUCT_PAGE_DATA,
  UPDATE_PRODUCT_PAGE_ASSETS,
} from './../../actions/types';

const defaultState = {
  Name: '',
  AssociatedAssets: [],
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_PRODUCT_PAGE_DATA:
      return Object.assign({}, action.data);
    case UPDATE_PRODUCT_PAGE_ASSETS:
      return Object.assign({}, state, {
        AssociatedAssets: action.assets,
      });
    default:
      return state;
  }
}