import React from 'react';

export default ({
  ResourceName,
  ProductName,
  DocsList
}) => (
  <div className="search-result__item">
    <p data-header="product">{ProductName}</p>
    <p data-header="document">{ResourceName}</p>
    <div data-header="document files" className="extension-link__link-group">
      {DocsList.map((d, i) => (
        <a key={i} className="extension-link__link" target="_blank" href={d.Url}>{d.Title}</a>
      ))}
      
    </div>
  </div>
);