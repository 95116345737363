import React, { Fragment, useState } from 'react';
import Modal from '../Common/Modal/Modal';
import ModalActions from '../Common/Modal/ModalActions';

import {
  cancelOrder,
} from '../../services/offlineOrdersApiService';

const OrderCancel = ({
  labels = {},
  initialData = {},
}) => {
  const [showModal, setShowModal] = useState(false);

  const onModalBtnClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  }

  const onModalClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowModal(false);
  }

  const onConfirmClick = async (e) => {
    setShowModal(false);
    if (initialData.orderNumber && initialData.po) {
      const data = await cancelOrder(initialData.orderNumber, initialData.po);
      window.notificationService.show(data.Message, !data.Success, true);      
    } else {      
      window.notificationService.show("Cancel wasn't cancelled. Please contact us.", true, true);
    }
  }

  return (
    <Fragment>
      <button onClick={onModalBtnClick} className="button button--link">{labels.cancelOrderButton}</button>
      <Modal isOpen={showModal} onClose={onModalClose}>
        <div>{labels.cancelOrderModalText}</div>
        <ModalActions>
          <button className="button" onClick={onConfirmClick}>{labels.cancelOrderConfirmButton}</button>
          <button className="button" onClick={onModalClose}>{labels.cancelOrderCancelButton}</button>
        </ModalActions>
      </Modal>
    </Fragment>
  )
}

export default OrderCancel;