import React, { Component, Fragment } from 'react';
import RadioButton from '../../Common/RadioButton';
import Checkbox from '../../Common/Checkbox';

class Part extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    selectedOptionId: null,
  }

  // onOptionSelect = (optionIndex) => {
  //   const { 
  //     onOptionSelect,
  //     partIndex
  //   } = this.props;

  //   onOptionSelect(partIndex, optionIndex);
  // }

  // onSubOptionCheck = (optionIndex, subOptionIndex) => {

  //   const {
  //     partIndex,
  //     onSubOptionCheck,
  //   } = this.props;

  //   onSubOptionCheck(partIndex, optionIndex, subOptionIndex);
  // }

  render() {

    const {
      partIndex,
      PartName,
      Variants,
      ShippingOptions,
    } = this.props;

    return (
      <li className="checkout-split-shipping__item">
        <h4 className="checkout-split-shipping__item__name">{PartName}</h4>
        <ul className="checkout-split-shipping__item__variant-list">
          {Variants && Variants.length ? Variants.map((v, i) => (
            <li 
              key={i}
              className="checkout-split-shipping__item__variant">{v}</li>
            )) : null}
        </ul>
        <dl className="estimate-freight__prices">
          
          {ShippingOptions.map((o, oi) => {

            return (
              <Fragment key={oi}>
                <dt>{o.DisplayName}</dt>
                <dd>${o.Price}</dd>

              </Fragment>
            );
          })}
        </dl>
      </li>
    );
  }
}

export default Part;