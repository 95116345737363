import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fillTemplate } from '../../../utils';


class SearchResultsStats extends Component {
  render() {
    const {
      total,
      count,
      query,
      totalStringTemplate,
      statsStringTemplate,
    } = this.props;

    return (
      <div className="search-results-stats">
        <p className="search-results__total">{fillTemplate(totalStringTemplate, { total, query, count, })}</p>
        <div className="search-results__index">
          {fillTemplate(statsStringTemplate, { total, query, count, })}
        </div>
      </div>
    );
  }
} 

SearchResultsStats.propTypes = {
  total: PropTypes.number,
  count: PropTypes.number,
  query: PropTypes.string,
  totalStringTemplate: PropTypes.string,
  statsStringTemplate: PropTypes.string,
}

SearchResultsStats.defaultProps = {
  total: 0,
  count: 0,
  query: '',
  totalStringTemplate: '{total} items found',
  statsStringTemplate: '{count} of {total} showing.',
}

export default SearchResultsStats;