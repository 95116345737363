import React from 'react';

const SearchResultWrapper = ({
  children,
}) => (
  <div className="search-results">
    <div className="width--layout">
      {children}
    </div>
  </div>
);

export default SearchResultWrapper;