import {
  SET_QUOTE_DETAILS_PAGE_ESTIMATED_FREIGHT,
} from '../../actions/types';

export default (state = [], action) => {
  switch(action.type){
    case SET_QUOTE_DETAILS_PAGE_ESTIMATED_FREIGHT:
      return action.list;
    default:
      return state;
  }

};