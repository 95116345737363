import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import InproProvider from './../InproProvider';

import Wrapper from '../Common/SearchResults/Wrapper';
import Stats from '../Common/SearchResults/Stats';
import ListWrapper from '../Common/SearchResults/ListWrapper';
import LoadMoreButton from '../Common/SearchResults/LoadMoreButton';
import SearchQuery from '../Common/SearchQuery';
import DocumentFull from '../Common/SearchResults/DocumentFull';
import MainCategorySelection from './MainCategorySelection';
import Filter from './Filter';
import Cloak from '../Common/Cloak';

import {
  search,
  setDocumentList,
  updateSettings,
} from './../../actions/documentSearchPageActionCreators';

class DocumentSearchPage extends Component {
  constructor(props) {
    super(props);

    const {
      initialData
    } = props;

    this.props.updateSettings({
      total: initialData.Total,
      query: initialData.Query ? initialData.Query : '',
      mainCategoryList: initialData.MainCategorySelectList ? initialData.MainCategorySelectList : [],
      subCategoryList: initialData.SubCategorySelectlist ? initialData.SubCategorySelectlist : [],
      productTypesList: initialData.ProductTypesSelectlist ? initialData.ProductTypesSelectlist : [],
      documentTypeList: initialData.DocumentTypeSelectList ? initialData.DocumentTypeSelectList : [],
      languageTypeList: initialData.LanguageTypeSelectlist ? initialData.LanguageTypeSelectlist : [],
      total: initialData.TotalMatching ? initialData.TotalMatching : 0,
      installationInstructionsOnly : initialData.InstallationInstructionsOnly ? initialData.InstallationInstructionsOnly : false,
      BIMObjectsOnly : initialData.BIMObjectsOnly ? initialData.BIMObjectsOnly : false,
    });
    
    this.props.setList(initialData.Documents);

  }

  onSearchClick = () => {
    const {
      search,
    } = this.props;

    search();
  }

  onLoadMore = () => {
    const {
      loadMore,
    } = this.props;

    loadMore();
  }

    clearFilters = () => {
        const {
            updateSettings,
            search,
        } = this.props;

        updateSettings({
            recordsSkip: 0,
            mainCategorySelectedOption: [],
            subCategorySelectedOptions: [],
            productTypesSelectedOptions: [],
            documentTypeSelectedOptions: [],
            languageTypeSelectedOptions: []
        });

        search();
    }      

  onQueryChange = (query) => {
    const { updateSettings } = this.props;

    updateSettings({
      query,
    });
  }

  mapOptions = (o) => ({
    label: o.Text,
    value: o.Value,
  })

  onLanguageTypeChange = (selectedOption) => {
    const {
      updateSettings,
      search,
    } = this.props;

    updateSettings({ 
      languageTypeSelectedOptions: selectedOption 
    });

    search();
  }

  onDocumentTypeChange = (selectedOption) => {
    const {
      updateSettings,
      search,
    } = this.props;

    updateSettings({ 
      documentTypeSelectedOptions: selectedOption 
    });

    search();
  }

  onSubCategoryChange = (selectedOption) => {
    const {
      updateSettings,
      search,
    } = this.props;

    updateSettings({ 
        subCategorySelectedOptions: selectedOption,
        productTypesSelectedOptions: [],
    });

    search();
    }

    onProductTypesChange = (selectedOption) => {
        const {
            updateSettings,
            search,
        } = this.props;

        updateSettings({
            productTypesSelectedOptions: selectedOption,
        });

        search();
    }

  onCategorySelect = (name) => {
    const {
      updateSettings,
      search,
    } = this.props;

    updateSettings({ 
      mainCategorySelectedOption: name,
      subCategorySelectedOptions: [],
      productTypesSelectedOptions: [],
    });

    search();
  }

  render() {
    
    const {
      documents,
      settings: {
        total,
        query,
        dirty,
        subCategoryList,
        productTypesList,
        mainCategoryList,
        documentTypeList,
        languageTypeList,
        mainCategorySelectedOption,
        subCategorySelectedOptions,
        productTypesSelectedOptions,
        documentTypeSelectedOptions,
        languageTypeSelectedOptions,
        isLoading,
        installationInstructionsOnly,
        BIMObjectsOnly,
      },
      labels,
      search,
    } = this.props;

    const categoryOptions = mainCategoryList.map(this.mapOptions);
    const subCategoryOptions = subCategoryList.map(this.mapOptions);
    const productTypesOptions = productTypesList.map(this.mapOptions);
    const documentTypeOptions = documentTypeList.map(this.mapOptions);
    const languageTypeOptions = languageTypeList.map(this.mapOptions);

    const regularSpecs = !installationInstructionsOnly && !BIMObjectsOnly;

    return (
      <div className="document-search-page">
        


        <Cloak active={isLoading}>
          <div className="search-filters">
                    <p className="search-filters__subheader">Select options to find your resources</p>
                    {regularSpecs ? (
            <MainCategorySelection 
              options={mainCategoryList} 
              selectedValue={mainCategorySelectedOption}
                            onSelect={this.onCategorySelect} />
                    ) : null}

            <div className="search-filters__selectors">

                        {!regularSpecs ? (
                            <Filter
                                label={labels.category}
                                options={categoryOptions} 
                                selectedOption={mainCategorySelectedOption}
                                onChange={this.onCategorySelect} />
                        ) : null}

              <Filter 
                label={labels.subCategory}
                options={subCategoryOptions}
                selectedOption={subCategorySelectedOptions}
                onChange={this.onSubCategoryChange}/>

                        <Filter
                            label={labels.productType}
                            options={productTypesOptions}
                            selectedOption={productTypesSelectedOptions}
                            onChange={this.onProductTypesChange} />

                        {regularSpecs ? (
                            <Filter
                                label={labels.documentType}
                                options={documentTypeOptions}
                                selectedOption={documentTypeSelectedOptions}
                                onChange={this.onDocumentTypeChange} />
                        ) : null}
              <Filter 
                label={labels.languageType}
                options={languageTypeOptions}
                selectedOption={languageTypeSelectedOptions}
                onChange={this.onLanguageTypeChange}/>
            </div>
                    <div className="clearFiltersButton">
                        <button onClick={this.clearFilters}>
                            Clear Filters
                        </button>
                    </div>
          </div>

          <Wrapper>
              {!documents.length && dirty ? (
                <p className="search-results__not-found">No results have been found for your request</p>
              ) : null }
              
              {!documents.length || 
                (
                  <Fragment>
                    <Stats total={total < 1000 ? total : `${total}+`} count={documents.length}/>                
                    <ListWrapper heading={["product", "document", "document files"]}>
                      {documents.map((item, i) => (
                        <DocumentFull 
                          key={i}
                          {...item} />
                      ))}
                    </ListWrapper>
                    {total > documents.length ? (
                      <LoadMoreButton onClickHandler={this.onLoadMore}/>
                    ) : null}
                  </Fragment>
                )}            
          </Wrapper>
        </Cloak>

      </div>
    );
  }
}

const mapProps = (state) => {
  return {
    documents: state.documentSearchPage.list,
    settings: state.documentSearchPage.settings,
  };
}

const mapDispatch = (dispatch) => {
  return {
    updateSettings: (settings) => dispatch(updateSettings(settings)),
    setList: (list) => dispatch(setDocumentList(list)),
    search: () => dispatch(search()),
    loadMore: () => dispatch(search(true)),
  };
}

DocumentSearchPage = connect(mapProps, mapDispatch)(DocumentSearchPage);

export default (props) => (
  <InproProvider>
    <DocumentSearchPage {...props}/>
  </InproProvider>
);