import React from 'react';
import Features from './Features';

export default ({
  name,
  children,
  features,
  description,
}) => (
  <div className="product-page-details">
    {features ? (
      <Features features={features}/>
    ) : null}
    <h1 className="product-page-details__name">{name}</h1>
    <div className="product-page-details__description" dangerouslySetInnerHTML={{__html: description}} />
    <div className="product-page-details__additional-content">
      {children}
    </div>
  </div>
);