import React from 'react';
import classNames from 'classnames';

import {
  fillTemplate,
} from '../../../utils';

const StockInfo = ({
  qty,
  inStock,
  leadTimeDays,
  labels,
}) => {

  const outOfStock = qty > inStock;
  const stockLabel = outOfStock ? labels.outOfStock : labels.inStock;

  const classes = classNames('cart-sku-content__stock', {
    'cart-sku-content__stock--out': outOfStock,
  })


  return (
    <div className={classes}>
          <p>{stockLabel}<br />

              {labels.available ? (<div>{labels.available}: {inStock}</div>) : null}

              
          </p>
      {outOfStock && (
        <p>{fillTemplate(labels.leadTime, {days: leadTimeDays})}</p>
      )}
    </div>
  );
}

export default StockInfo;