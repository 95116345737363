import React from 'react';
import NumberedContainer from './../../Common/NumberedContainer/';
import ColorPicker from './ColorPicker';
import Cloak from './../../Common/Cloak';

const Step = ({
  number,
  step,
  onChange,
  isLoading,
}) => (
  <NumberedContainer number={number} title={step.Title}>

    <Cloak active={isLoading} showSpinner>
      <ColorPicker colors={step.Options || []} onChange={onChange}/>
    </Cloak>

  </NumberedContainer>
);

export default Step;