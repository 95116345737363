import {
  UPDATE_SEMINAR_PAGE_SETTINGS,
} from '../../actions/types';

export default (state = {
  showFormTab: false,
  tabsContextId: '',
  formAnchor: '',
}, action) => {
  switch(action.type){
    case UPDATE_SEMINAR_PAGE_SETTINGS:
      return Object.assign({}, state, action.settings);
    default:
      return state;
  }

};