import { combineReducers } from 'redux';

import product from './productReducer';
import variants from './variantsReducer';
import config from './configReducer';
import settings from './settingsReducer';

export default combineReducers({
  config,
  product,
  variants,
  settings,
})