import React, { Component } from 'react';
import { connect } from 'react-redux';

import { 
  scrollTo,
  getWOWTab,
} from './../../../utils';

import {
  orderSample,
    setProductPageData,
    orderSampleOnAsset,
} from './../../../actions/productPageActionCreators';

import { addFabricToRecentlyViewed } from './../../../actions/cookieActionCreators';

import Assets from './Assets';
import Details from './Details';
import InproProvider from './../../InproProvider';
import Configuration from './../Configuration';
import { isNullOrUndefined } from 'util';
import Cloak from './../../Common/Cloak';

class Header extends Component {

  state = {
    currentAsset: null,
    currentAssetCode: '',
    currentAssetIndex: null,
  }

  componentDidMount() {

    const {
      initialData,
      setHeaderData,
    } = this.props;

    setHeaderData(initialData);

    const {
      AssociatedAssets,
      } = initialData;
      
      if (AssociatedAssets && AssociatedAssets.length) {

          let fabric = AssociatedAssets[0];
          let fabricCode = AssociatedAssets[0].Code;
          let fabricIndex = 0;

          if (!isNullOrUndefined(AssociatedAssets[0].IsSelected)) {
              fabric = AssociatedAssets.filter((e) => e.IsSelected === true)[0];
              fabricCode = AssociatedAssets.filter((e) => e.IsSelected === true)[0].Code;
              fabricIndex = AssociatedAssets.filter((e) => e.IsSelected === true)[0].CurrentIndex;
          }
             
          this.setState({
              currentAsset : fabric,
              currentAssetCode: fabricCode,
              currentAssetIndex: fabricIndex,
          });
       }
  }

  onCurrentAssetChange = (currentAssetIndex) => {
    const {
      initialData: {
        AssociatedAssets,
      },
    } = this.props;

    this.setState({
      currentAsset : AssociatedAssets[currentAssetIndex],
      currentAssetCode: AssociatedAssets[currentAssetIndex].Code,
      currentAssetIndex,
    });

    this.props.addFabricToRecentlyViewed(AssociatedAssets[currentAssetIndex].Code);
  }






  
  onOrderSampleClick = (e) => {
    e.preventDefault();

    const  {
      currentAsset,
    } = this.state;

    this.props.orderSample(currentAsset);

  }
  

  isCurrentAssetIsDiscontinued = () => {
    
    const {
      currentAssetCode,
    } = this.state;

    const {
      product,
    } = this.props;

    const currentAsset = product.AssociatedAssets.find(a => a.Code === currentAssetCode);

    return currentAsset ? currentAsset.IsDiscontinued : false;
  }

  render() {

    const {
      initialData: {
        Name,
        Features,
        AssociatedAssets,
        ShortDescription,
        canOrderSample,
        isAvailableForPurchase,
        isGuestUser,
        isDiscontinued,
        requestAQuoteLink,
        headerOptionLabels,
        showTitles,
      },
        product,
        settings,
      } = this.props;



    const assets = product.AssociatedAssets && product.AssociatedAssets.length ? 
      product.AssociatedAssets : AssociatedAssets && AssociatedAssets.length ? 
        AssociatedAssets : [];

      const hideRequestQuoteButton = !isGuestUser && isAvailableForPurchase;

    return (
        <header className="product-page-header">

            <div className="product-page-asset-gallery">

            <Cloak active={settings.isSampleOrdered} showSpinner>

                <Assets
                        assets={assets}
                        onCurrentAssetChange={this.onCurrentAssetChange}
                        currentAssetIndex={this.state.currentAssetIndex}
                        showNames={canOrderSample}
                        showTitles={showTitles}
                        orderSampleClickFunction={this.onOrderSampleClick}
                        isCurrentAssetDis={this.isCurrentAssetIsDiscontinued()}/>

                </Cloak>

            </div>

        <Details 
          name={Name} 
          description={ShortDescription} 
          features={Features}>
          
                
                {
              !hideRequestQuoteButton && !isDiscontinued && headerOptionLabels.requestAQuote && requestAQuoteLink ? (
              <a href={requestAQuoteLink} className="button primary">{headerOptionLabels.requestAQuote}</a>
            ) : null
          }



                {
                    isAvailableForPurchase ? (

                        <Configuration initialConfigurationData={this.props.initialConfigurationData}>

                        </Configuration>

                    ) : null
                }


                
            </Details>


      </header>
    );

  }
}


const mapProps = (state) => {
  return {
      product: state.productPage.product,
      settings: state.productPage.settings,
  };
}

const mapDispatch = (dispatch) => {
  return {
    setHeaderData: (data) => dispatch(setProductPageData(data)),
    orderSample: (asset) => dispatch(orderSampleOnAsset(asset)),
    addFabricToRecentlyViewed: (code) => dispatch(addFabricToRecentlyViewed(code)),
  };
}

Header = connect(mapProps, mapDispatch)(Header);

export default (props) => (
  <InproProvider>
    <Header {...props}/>
  </InproProvider>
);