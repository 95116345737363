import {
  SET_ORDER_HISTORY_PAGE_ORDERS,
} from '../../actions/types';;

export default (state = [], action) => {
  switch(action.type) {
    case SET_ORDER_HISTORY_PAGE_ORDERS:
      return action.list;
    default: 
      return state;
  }
}