import React from 'react';
import NumberedContainer from './../../Common/NumberedContainer/';
import ColorPicker from './ColorPicker';
import Cloak from './../../Common/Cloak';



const NewStep = ({
    number,
    step,
    onChange,
    isLoading,
    selectedColor,
    addColorSampleToCart,
    hideAddColorSampleToCartButton,
    title,
}) => (
    <NumberedContainer number={number} title={title}>

        <Cloak active={isLoading} showSpinner>
            <ColorPicker colors={step.Options || []} onChange={onChange} />
        </Cloak>


        {!hideAddColorSampleToCartButton ? (

            
                selectedColor ? (

                    <button className = "button" onClick = { addColorSampleToCart } > Add Color Sample To Cart</button>

                ) : <button className="button" disabled={true}>Add Color Sample To Cart</button>

        ): null}



        

    </NumberedContainer>

);

export default NewStep;