import {
  SET_SEMINAR_PAGE_ITEMS,
} from '../../actions/types';

export default (state = [], action) => {
  switch(action.type){
    case SET_SEMINAR_PAGE_ITEMS:
      return action.list;
    default:
      return state;
  }

};