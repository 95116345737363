import fetch from 'cross-fetch';

import {
  SET_ORDER_HISTORY_PAGE_ORDERS,
  UPDATE_ORDER_HISTORY_PAGE_SETTINGS,
} from './types';

export const setOrders = (list = []) => ({
  type: SET_ORDER_HISTORY_PAGE_ORDERS,
  list,
});

export const updateSettings = (settings = {}) => ({
  type: UPDATE_ORDER_HISTORY_PAGE_SETTINGS,
  settings,
});

export const searchByQuery = () => (dispatch, getState) => {
  const {
    orderHistoryPage: {
      settings: {
        query,
        endDate,
        startDate,
      }
    }
  } = getState();

  const body = {
    query,
  };

  dispatch(updateSettings({
    isLoading: true,
    dirty: true,
  }));

  fetch('/OfflineOrderHistoryPage/GetOrderByQuery/', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then(res => res.status === 200 ? res.json() : null).then(data => {
    if (data) {
      dispatch(setOrders(data));
    }
    dispatch(updateSettings({
      isLoading: false,
    }));
  })
}

export const searchByDateRange = () => (dispatch, getState) =>{
  const {
    orderHistoryPage: {
      settings: {
        endDate,
        startDate,
      }
    }
  } = getState();

  const body = {
    endDate: formatDate(endDate),
    startDate: formatDate(startDate),
  };

  dispatch(updateSettings({
    isLoading: true,
    dirty: true,
  }));

  fetch('/OfflineOrderHistoryPage/GetOrdersByDateRange/', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then(res => res.status === 200 ? res.json() : null).then(data => {
    if (data) {
      dispatch(setOrders(data));
    }
    dispatch(updateSettings({
      isLoading: false,
    }));
  });
}

export const searchByDefault = () => (dispatch) =>{

  dispatch(updateSettings({
    isLoading: true,
  }));

  fetch('/OfflineOrderHistoryPage/GetRecentOrders/', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => res.status === 200 ? res.json() : null).then(data => {
    if (data) {
      dispatch(setOrders(data));
    }
    dispatch(updateSettings({
      isLoading: false,
      query: '',
      startDate: '',
      endDate: '',
      dirty: false,
    }));
  });
}

const formatDate = (date) => {
  if (date) {
    return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
  }

  return null;
}