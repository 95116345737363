import React from 'react';

export default ({
  Code,
  DisplayName,
  ColorId,
  ProductUrl,
  ParentPalettes
}) => {
  const parentPalettes = Object.keys(ParentPalettes).map(function(key) {
    return {
      url: ParentPalettes[key],
      name: key,
    };
  });

  return (
    <div className="search-result__item">
      <p data-header="Name"><a href={ProductUrl + '?DisplayName=' + DisplayName}>{DisplayName}</a></p>
      <p data-header="Code"><a href={ProductUrl + '?DisplayName=' + DisplayName}>{ColorId}</a></p>
      <p data-header="Type" className="search-results__sample-parent-links">{parentPalettes.map((p, i) => <a key={i} href={p.url + '?DisplayName=' + DisplayName}>{p.name}</a>)}</p>
    </div>
  )
};