export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

export const scrollTo = (id) => {
  if (typeof document !== 'undefined') {
    window.$([document.documentElement, document.body]).animate({
      scrollTop: window.$("#"+id).offset().top
    }, 500);
  }
};

export const getWOWTab = (contextId, tabId) => {
  let tab;
  if (typeof document === 'undefined') return null;
  window.WOW.Components.Tabs.findAllInstances((instances = []) => {  
    try {
      tab = instances.items.find(t => t.tab[0].id === tabId);
    } catch {
      console.warn('can\'t find tab ' + tabId + ' in ' + contextId + ' context');
    }
  }, document.getElementById(contextId));

  return tab;
}

export function fillTemplate(templateString, templateVars){
  return templateString.replace(/{([^{}]*)}/g,
        function (a, b) {
            var r = templateVars[b];
            return typeof r === 'string' || typeof r === 'number' ? r : a;
        }
    );
}