import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import {
  purchaseQuote,
  duplicateQuote,
  hideEmailModal,
  showEmailModal,
    sendQuoteViaEmail,
    updateSettings,
    setActiveQuote
} from '../../../actions/quoteDetailsPageActionCreators';

import InproProvider from './../../InproProvider';
import Cloak from '../../Common/Cloak';
import EmailFormModal from '../../shared/QuoteEmailFormModal';

class QuoteDetailsActions extends Component {

  constructor(props) {
      super(props);

      this.state = {

          dupProjectName: ''

      }

      this.handleDupChange = this.handleDupChange.bind(this);

  }

  onDuplicateButtonClick = (e) => {
    e.preventDefault();

    const {
      quote,
      duplicateQuote,
      } = this.props;

      const {
          dupProjectName
      } = this.state;

      duplicateQuote(quote.QuoteId, dupProjectName);
  }

  onEmailButtonClick = (e) => {
    e.preventDefault();
    this.props.showEmailModal();
  }

  onEmailFormSubmit = (data) => {
    const {
      quote,
      sendQuote,
      hideEmailModal,
    } = this.props;
    sendQuote(quote.QuoteId, data);
    hideEmailModal();
  }

  onPurchaseQuoteClick = (e) => {
    e.preventDefault();

    const {
      quote = {},
      purchaseQuote,
    } = this.props;

      purchaseQuote(quote.QuoteId);
    }

    onSetActiveQuoteClick = (e) => {
        e.preventDefault();

        const {
            quote = {},
            setActiveQuote,
        } = this.props;

        setActiveQuote(quote.QuoteId, quote.ProjectName);
    }


    handleDupChange(event) {
        this.setState({ dupProjectName: event.target.value });
    }
    onCloseNotice = () => {

        const settings = {
            showAddToCartNotice: false,
        };
        const {
            updateTheSettings,
        } = this.props;

        updateTheSettings(settings);
    }
  render() {

    const {
      list,
      quote,
      labels,
      settings,
      hideEmailModal,
      initialData: {
        printPageUrl,
      }
      } = this.props;

      const {
          dupProjectName
      } = this.state;


    if (!quote) {
      return (
        <div></div>
      );
    }

    const variants = list.reduce((arr, p) => {
      return [
        ...arr,
        ...p.Variants,
      ]
    }, []);

    const actionsDisabled = quote.IsExpired;
      const availableForPurchase = quote.QuoteProductList.filter(v => v.HasNoColor).length == 0 && !actionsDisabled;           
      const hasNoColor = quote.QuoteProductList.filter(v => v.HasNoColor).length == 0;
      const message = 'Quote is duplicated. Click here to see quote.';
      const quoteURL = "/quote-details-page/?quoteId=" + settings.quoteId;

    return (
      <Fragment>
        <Cloak active={settings.isLoading} showSpinner>
                <div>                    
                    <div>
                        {!hasNoColor &&
                            <div>
                            <strong>                                    
                                 {labels.noColorMessage}
                            </strong>
                        </div>}
              <button 
                className="button button--wide button--solid shoppingCart__actionButton--checkout" 
                disabled={!availableForPurchase}
                onClick={availableForPurchase ? this.onPurchaseQuoteClick : (e) => {e.preventDefault()}}>{labels.purchaseButton}</button>
            </div>
            <div>
                <a                            
                className={`button button--wide quote-details-button ${actionsDisabled ? 'button--disabled' : ''}`}
                href={actionsDisabled ? '#' : `${printPageUrl}?quoteId=${quote.QuoteId}`} 
                onClick={actionsDisabled ? (e) => {e.preventDefault();} : () => {/*blank. default is taking control*/}}
                target="_Blank"
                >{labels.printButton}</a>
            </div>
            <div>
              <button 
                disabled={actionsDisabled}
                onClick={actionsDisabled ? (e) => {e.preventDefault()} : this.onEmailButtonClick} 
                className="button button--wide quote-details-button">{labels.emailButton}</button>
            </div>




                    <Popup trigger={<button className="button button--wide quote-details-button" disabled={!quote.IsEcommerceQuote}>Duplicate Quote</button>} modal>

                        {close => (
                            <div className="quoteModal">

                                <div className="quoteModelClose" onClick={close}>
                                    x close
                                </div>

                                <div className="quoteModelText">Enter Project Name</div>

                                <input type="text" value={dupProjectName} onChange={this.handleDupChange} />

                                <br />
                                <br />

                                <button onClick={(event) => { this.onDuplicateButtonClick(event); close(); }}>Duplicate Quote</button>

                            </div>

                        )}
                    </Popup>

                    <div><button onClick={(event) => { this.onSetActiveQuoteClick(event); }} class="button button--wide quote-details-button" disabled={quote.IsLocked}>Continue Shopping</button></div>

                    {settings.showAddToCartNotice ? (
                        <div

                            className="notice notice--success notice--label">

                            <a href={quoteURL}>

                                <div className="notice__label">{ message }</div>
                            </a>

                            <span onClick={this.onCloseNotice} className="notice__delete">+</span>

                        </div>
                    ) : null}




          </div>
        </Cloak>
        {/* Email Modal */}
        <EmailFormModal
          isOpen={settings.showEmailModal}
          emailModalQuoteNumber={settings.emailModalQuoteNumber}
          cancelLabel={labels.cancel}
          confirmLabel={labels.sendConfirmation}
          onClose={hideEmailModal}          
          onSubmit={this.onEmailFormSubmit}
        />
      </Fragment>
    );

  }

}

const mapProps = (state) => {
  return {
    quote: state.quoteDetailsPage.quote,
    list: state.quoteDetailsPage.list,
    settings: state.quoteDetailsPage.settings,
  };
}

const mapDispatch = (dispatch) => {
  return {
    duplicateQuote: (quoteId, projectName) => dispatch(duplicateQuote(quoteId, projectName)),
    sendQuote: (quoteNumber, data) => dispatch(sendQuoteViaEmail(quoteNumber, data)),
    hideEmailModal: () => dispatch(hideEmailModal()),
    showEmailModal: (quoteNumber) => dispatch(showEmailModal(quoteNumber)),
      purchaseQuote: (quoteNumber) => dispatch(purchaseQuote(quoteNumber)),
      updateTheSettings: (settings) => dispatch(updateSettings(settings)),
      setActiveQuote: (quoteId, quoteProjectName) => dispatch(setActiveQuote(quoteId, quoteProjectName))
  };
}

QuoteDetailsActions = connect(mapProps, mapDispatch)(QuoteDetailsActions);

export default (props) => (
  <InproProvider>
    <QuoteDetailsActions {...props}/>
  </InproProvider>
);