import React, { Component, Fragment } from 'react';
import RadioButton from './../Common/RadioButton';
import Checkbox from '../Common/Checkbox';

class Part extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    selectedOptionId: null,
  }

  onOptionSelect = (optionIndex) => {
    const { 
      onOptionSelect,
      partIndex
    } = this.props;

    onOptionSelect(partIndex, optionIndex);
  }

  onNotesChange = (e) => {
    const {
      onNotesChange,
      partIndex,
    } = this.props;

    onNotesChange(partIndex, e.currentTarget.value);
  }

  onSubOptionCheck = (optionIndex, subOptionIndex) => {

    const {
      partIndex,
      onSubOptionCheck,
    } = this.props;

    onSubOptionCheck(partIndex, optionIndex, subOptionIndex);
  }

  render() {

    const {
      partIndex,
      PartName,
      Variants,
      ShippingOptions,
      Notes,
    } = this.props;

    return (
      <li className="checkout-split-shipping__item">
        <h4 className="checkout-split-shipping__item__name">{PartName}</h4>
        <ul className="checkout-split-shipping__item__variant-list">
          {Variants && Variants.length ? Variants.map((v, i) => (
            <li 
              key={i}
              className="checkout-split-shipping__item__variant">{v}</li>
            )) : null}
        </ul>

        {ShippingOptions.map((o, oi) => {

          const hasSubOptionalSelected = o.Selected && o.SubOptions && o.SubOptions.length && o.SubOptions.find(so => so.Checked);
          const additionalCost = hasSubOptionalSelected ? o.SubOptions.reduce((total, so) => {
            if (so.Checked) {
              return total + so.Price;
            }

            return total;
          }, 0) : 0;

          const priceToShow = parseFloat(o.Price) + parseFloat(additionalCost);
          const showTransitTime = o.TransitTime != null && o.TransitTime != '';
          return (
            <Fragment key={oi}>
              <RadioButton              
                value={oi}
                selected={o.Selected}
                marginLess
                onChange={this.onOptionSelect}
                  >{o.DisplayName} ${priceToShow.toFixed(2)}<br></br> {showTransitTime && <p> Estimated Transit Time: {o.TransitTime} day(s) </p>}</RadioButton>
                {o.SubOptions && o.SubOptions.length && o.Selected ? (
                    <div>
                        {o.SubOptions.map((so, soi) => (
                          <Checkbox 
                            key={soi}
                            value={soi}
                            checked={so.Checked}
                            onChange={() => {
                              this.onSubOptionCheck(oi, soi);
                            }}
                            name={`shippingMethodCheckbox_${partIndex}_${oi}_${soi}`}>{so.DisplayName} ${so.Price.toFixed(2)}</Checkbox>
                        ))}
                    </div>
                  ) : null}
            </Fragment>
          );
        })}

        <p>Any Special Instructions?</p>
        <textarea value={Notes || undefined} onChange={this.onNotesChange}></textarea>
      </li>
    );
  }
}

export default Part;