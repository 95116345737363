import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    scrollTo,
    getWOWTab,
} from './../../../utils';

import {
    orderSample,
    setProductPageData,
    orderSampleOnAsset,
} from './../../../actions/productPageActionCreators';

import { addFabricToRecentlyViewed } from './../../../actions/cookieActionCreators';

import Assets from './Assets';
import Details from './Details';
import InproProvider from './../../InproProvider';
import Configuration from './../Configuration';
import { isNullOrUndefined } from 'util';
import Cloak from './../../Common/Cloak';
import NewConfiguration from './../Configuration/NewConfiguration.js';

class NewHeader extends Component {

    state = {
        currentAsset: null,
        currentAssetCode: '',
        currentAssetIndex: null,
        selectedConfigColorImage: null,
    }

    componentDidMount() {

        const {
            initialData,
            setHeaderData,
        } = this.props;

        setHeaderData(initialData);

        const {
            AssociatedAssets,
        } = initialData;

        if (AssociatedAssets && AssociatedAssets.length) {

            let fabric = AssociatedAssets[0];
            let fabricCode = AssociatedAssets[0].Code;
            let fabricIndex = 0;

            if (!isNullOrUndefined(AssociatedAssets[0].IsSelected)) {
                fabric = AssociatedAssets.filter((e) => e.IsSelected === true)[0];
                fabricCode = AssociatedAssets.filter((e) => e.IsSelected === true)[0].Code;
                fabricIndex = AssociatedAssets.filter((e) => e.IsSelected === true)[0].CurrentIndex;
            }

            this.setState({
                currentAsset: fabric,
                currentAssetCode: fabricCode,
                currentAssetIndex: fabricIndex,
            });
        }
    }

    onCurrentAssetChange = (currentAssetIndex) => {
        const {
            initialData: {
                AssociatedAssets,
            },
        } = this.props;

        this.setState({
            currentAsset: AssociatedAssets[currentAssetIndex],
            currentAssetCode: AssociatedAssets[currentAssetIndex].Code,
            currentAssetIndex,
        });

        this.props.addFabricToRecentlyViewed(AssociatedAssets[currentAssetIndex].Code);
    }

    onSelectedConfigColorChange = (imageVar) => {

        this.setState({
            selectedConfigColorImage: imageVar
        });


    }

    onOrderSampleClick = (e) => {
        e.preventDefault();

        const {
            currentAsset,
        } = this.state;

        this.props.orderSample(currentAsset);

    }


    isCurrentAssetIsDiscontinued = () => {

        const {
            currentAssetCode,
        } = this.state;

        const {
            product,
        } = this.props;

        const currentAsset = product.AssociatedAssets.find(a => a.Code === currentAssetCode);

        return currentAsset ? currentAsset.IsDiscontinued : false;
    }

    render() {

        const {
            initialData: {
                Name,
                Features,
                AssociatedAssets,
                ShortDescription,
                canOrderSample,
                isDiscontinued,
                requestAQuoteLink,
                headerOptionLabels,
                showTitles,
                isKitLayout,
            },
            product,
            settings,
        } = this.props;

        const {
            selectedConfigColorImage
        } = this.state;

        const assets = product.AssociatedAssets && product.AssociatedAssets.length ?
            product.AssociatedAssets : AssociatedAssets && AssociatedAssets.length ?
                AssociatedAssets : [];

        if (selectedConfigColorImage && isKitLayout) {
            assets[0].Image = selectedConfigColorImage;
        }

        return (
            <header className="new-product-page-header">

                <div className="product-page-asset-gallery">


                    <Details
                        name={Name}
                        description={ShortDescription}
                        features={Features}>




                    </Details>

                    

                        <Assets
                            assets={assets}
                            onCurrentAssetChange={this.onCurrentAssetChange}
                            currentAssetIndex={this.state.currentAssetIndex}
                            showNames={canOrderSample}
                            showTitles={showTitles}
                            orderSampleClickFunction={this.onOrderSampleClick}
                            isCurrentAssetDis={this.isCurrentAssetIsDiscontinued()} />

                   

                </div>

                {/*{
                    isAvailableForPurchase ? ( */}

                <NewConfiguration initialConfigurationData={this.props.initialConfigurationData} onSelectedConfigColorChange={this.onSelectedConfigColorChange }>

                        </NewConfiguration>

                {/* ) : null
                }*/}


            </header>
        );

    }
}


const mapProps = (state) => {
    return {
        product: state.productPage.product,
        settings: state.productPage.settings,
    };
}

const mapDispatch = (dispatch) => {
    return {
        setHeaderData: (data) => dispatch(setProductPageData(data)),
        orderSample: (asset) => dispatch(orderSampleOnAsset(asset)),
        addFabricToRecentlyViewed: (code) => dispatch(addFabricToRecentlyViewed(code)),
    };
}

NewHeader = connect(mapProps, mapDispatch)(NewHeader);

export default (props) => (
    <InproProvider>
        <NewHeader {...props} />
    </InproProvider>
);