import fetch from 'cross-fetch';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../Common/SearchResults/Wrapper';
import Stats from '../Common/SearchResults/Stats';
import ListWrapper from '../Common/SearchResults/ListWrapper';
import LoadMoreButton from '../Common/SearchResults/LoadMoreButton';
import SearchQuery from '../Common/SearchQuery';
import Sample from '../Common/SearchResults/Sample';
import { fillTemplate } from '../../utils';
import Cloak from '../Common/Cloak';

class ColorAndFabricSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      oldQuery: '',
      showAll: false,
      items: [],
      dirty: false,
      isLoading: false,
    }
  }

  onLoadMore = (e) => {
    e.preventDefault();

    this.setState({
      showAll: true,
    });
  }

  onQueryChange = (query) => {
    this.setState({
      query,
    });
  }

  onSearchClick = () => {

    const {
      maxNumberReturned,
    } = this.props;

    this.setState({
      isLoading: true,
    })

    fetch('/SearchPalette/Search', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productCode: this.state.query,
        maxNumberReturned,
      }),
    }).then(res => res.status === 200 ? res.json() : []).then(items => {
      this.setState({
        isLoading: false,
        oldQuery: this.state.query,
        items,
        showAll: false,
        dirty: true,
      });      
    })

  }

  render() {

    const {      
      initialNumberShown,
      labels,
    } = this.props;

    const {
      query,
      items,
      showAll,
      dirty,
      oldQuery,
    } = this.state;

    const count = showAll ? items.length : items.length < initialNumberShown ? items.length : initialNumberShown;
    const itemsToShow = showAll ? items : items.slice(0, initialNumberShown);
    const showLoadMoreButton = itemsToShow.length < items.length;

    return (
      <Fragment>
        <Cloak active={this.state.isLoading}>
          <SearchQuery
            placeholder={labels.searchFieldPlaceholder}
            label={labels.searchFieldLabel}
            buttonLabel={labels.searchFieldButtonLabel}
            query={query}
            wideLabel
            onQueryChange={this.onQueryChange}
            onButtonClick={this.onSearchClick}
          />
        </Cloak>
      
        <Cloak active={this.state.isLoading} showSpinner>
          <Wrapper>
              {!itemsToShow.length && dirty? (
                <p className="search-results__not-found">{labels.noResult}</p>
              ) : null }
              
              {!itemsToShow.length || 
                (
                  <Fragment>
                    <Stats 
                      total={items.length}
                      count={showAll ? items.length : count}
                      totalStringTemplate={labels.resultTotal} 
                      statsStringTemplate={labels.resultStats}
                      query={oldQuery}/>
                    <ListWrapper heading={["Name", "Code", "Type"]}>
                      {itemsToShow.map((item, i) => (
                        <Sample 
                          key={i} 
                          {...item} />
                      ))}
                    </ListWrapper>
                    {showLoadMoreButton ? (
                      <LoadMoreButton onClickHandler={this.onLoadMore}>
                        <div>{labels.loadMoreButtonLabel}</div>
                        <div>{fillTemplate(labels.loadMoreStats, { total: items.length, count: showAll ? items.length : count })}</div>
                      </LoadMoreButton>
                    ) : null}
                  </Fragment>
                )}            
          </Wrapper>
        </Cloak>
      </Fragment>
    );
  }
}

ColorAndFabricSearch.propTypes = {
  initialNumberShown: PropTypes.number,
  maxNumberReturned: PropTypes.number,
};

ColorAndFabricSearch.defaultProps = {
  initialNumberShown: 5,
  maxNumberReturned: 999,
};

export default ColorAndFabricSearch;