import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

const GalleryImages = ({

    list

}) => {

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    const getBigURLs = (list) => {

        if (list) {

            var bigURLs = [];

            list.map((image, i) => {
                if (image.BigImageURL) {
                    bigURLs.push(image.BigImageURL);
                }
                else {
                    //Use Thumbnail if Big Image can't be found
                    bigURLs.push(image.ImageURL);

                }
            })

            return bigURLs;
        }
        else {
            return [];
        }


    }

    return (
        <div className="galleryImages">
            {
                list && list.length ? (
                    list.map((image, i) => (
                        <div className="galleryImage">
                            <img onClick={() => openLightboxOnSlide(i + 1)} loading="lazy" src={image.ImageURL} alt={image.ImageAltText}></img>
                        </div>
                    ))

                ) : <div className="galleryNoImages">No Images Found</div>
            }
            {
                list ? (

                    <FsLightbox
                        toggler={lightboxController.toggler}
                        slide={lightboxController.slide}
                        sources={getBigURLs(list)}
                        key={list}
                    />


                ) : null

            }

        </div>

        
        
        );
};

export default GalleryImages;