import fetch from 'cross-fetch';

const objToQueryString = (obj) => Object.keys(obj).map(key => key + '=' + obj[key]).join('&');

export const commonPost = (url, body = {}) => fetch(url, {
  method: 'POST',
    headers: {
      'pragma': 'no-cache',
      'cache-control': 'no-cache',
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
}).then(res => res.status === 200 ? res.json() : null);

export const commonGet = (url, params = {}) => fetch(`${url}?${objToQueryString(params)}`, {
  method: 'GET',
  headers: {
    'pragma': 'no-cache',
    'cache-control': 'no-cache',
  },
}).then(res => res.status === 200 ? res.json() : null);
