import React, { Component, Fragment } from 'react';

import { fillTemplate } from './../../../utils';

import Row from './Row';
import THead from './THead';
import Footer from './Footer';
import Selections from './Selections';

import Select, { Option } from 'rc-select';

class ShopProductTable extends Component {

  render() {
    const {
      list,
      steps,
      labels,
      quoteList,
      updateVariant,
      showAddToQuote,
      isColorSelected,
      minimumPurchaseAmount,
        onChange,
        selectedVariantItem,
        marketSegments,
        activeQuoteId,
        activeQuoteProjectName,
        isAvailableForPurchase,
    } = this.props;

    const totalPrice = list.reduce((sum, i) => {
      const price = i.Price || 0;
      const qty = i.quantity || 0;
      const setupFees = i.SetupFees && qty && qty <= i.SetupFeeMaxQuantity ? i.SetupFees : 0;
      return sum + price * qty + setupFees;
    }, 0);

      const hasItemsInCart = list.filter(i => i.quantity).length;
      const allowedToAdd = selectedVariantItem ? isColorSelected || selectedVariantItem.HasNoColor : false
    const variants = list.filter(v => !v.IsComponent);
    const components = list.filter(v => v.IsComponent);

      const Selected = true;
      const quantity = 1;

    return (

      <div className="mobileTable">


            { variants.length || components.length ? (

                list.length == 1 ? (
                    
                    selectedVariantItem ? (

                         isAvailableForPurchase ? (

                            <div className="singleSKU">{selectedVariantItem ? selectedVariantItem.Specs + " - $" + selectedVariantItem.Price + " (" + selectedVariantItem.Code + ")" : ""}</div>
                        ) :

                            <div className="singleSKU">{selectedVariantItem ? selectedVariantItem.Specs + " (" + selectedVariantItem.Code + ")" : ""}</div>



                    ) : null
                ) : 
                 
                    <Select
                        onChange={(value) => {
                            const Code = value;
                            onChange({ Selected, Code, quantity });
                        }}
                        optionFilterProp="children"
                        optionLabelProp="children"
                        placeholder="Specs - Price (Part)"
                        showSearch={false}
                    showArrow={true}
                        value={selectedVariantItem ? selectedVariantItem.Specs + (isAvailableForPurchase ? " - $" + selectedVariantItem.Price : " ") + " (" + selectedVariantItem.Code + ")" : []}
                    >

                        {variants.map((option, i) => (
                            <Option
                                value={option.Code}
                                key={i} selected={option.Selected}>{option.Specs + (isAvailableForPurchase ? " - $" + option.Price : "") + " (" + option.Code + ")"}</Option>
                        ))}

                    {
                        components.length ? (
                            <Option value={""} disabled={true}>{"Components"}</Option>
                        ): null
                    }

                    {components.map((option, i) => (
                        <Option
                            value={option.Code}
                            key={i} selected={option.Selected}>{option.Specs + (isAvailableForPurchase ? " - $" + option.Price : "") + " (" + option.Code + ")"}</Option>
                    ))}

                    </Select>

                ) : null
            }

            {
                selectedVariantItem && isAvailableForPurchase ? (

                    <Row key={selectedVariantItem.Code} {...selectedVariantItem} updateVariant={updateVariant} isColorSelected={isColorSelected} leadTimeLabel={labels.leadTime} />

                ): null
            }



       
        {totalPrice > 0 && minimumPurchaseAmount ? (
            <div>
              {fillTemplate(labels.minimumPurchaseAmountWarning, { amount: minimumPurchaseAmount })}
            </div>
          ) : null}
        


        <Footer 
          totalPrice={totalPrice}
          hasItemsInCart={!!hasItemsInCart}
          allowedToAdd={allowedToAdd}  
          quoteList={quoteList}
          {...this.props}
          showAddToQuote={showAddToQuote}
          marketSegments={marketSegments}
          activeQuoteId={activeQuoteId}
                activeQuoteProjectName={activeQuoteProjectName}  
                isAvailableForPurchase={isAvailableForPurchase}
          />
      </div>
      
    );
  }
};

export default ShopProductTable;