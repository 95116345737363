import React, { cloneElement } from "react";
import classNames from "classnames";

const RadioGroup = (props) => {

  const {
    children,
    disabled,
    horizontal,
    marginLess,
  } = props;

  const groupClassName = classNames("inpro-radio-group", {
    'inpro-radio-group--disabled': disabled,
    'inpro-radio-group--horizontal': horizontal,
    'inpro-radio-group--margin-less': marginLess,

  });

  return (
    <div className={groupClassName}>
      {
        children.map((item, i) => cloneElement(item, {
          className: "inpro-radio-group__item",
          // eslint-disable-next-line
          disabled,
          onChange: props.onChange,
          key: i,
        }))
      }
      { props.helperText && !props.errorText &&
        <div className="inpro-radio-group__helper-text">
          { props.helperText }
        </div> }
      { props.errorText &&
        <div className="inpro-radio-group__error-text">
          { props.errorText }
        </div> }
    </div>
  );
};

RadioGroup.defaultProps = {
  onChange: () => {},
};

export default RadioGroup;