import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form'

import Modal from './../Common/Modal/Modal';
import ModalActions from './../Common/Modal/ModalActions';
import ModalForm, { ModalFormFieldset, ModalFormItem, ModalFormItemLabel } from './../Common/Modal/ModalForm';

let EmailForm = ({handleSubmit, onClose, cancelLabel, confirmLabel}) => (
  <ModalForm onSubmit={handleSubmit}>

    <ModalFormFieldset>
      <ModalFormItem required wide>
        <ModalFormItemLabel labelFor="to">To</ModalFormItemLabel>
        <Field name="to" component="input" type="email" required/>
      </ModalFormItem>
    </ModalFormFieldset>
    <ModalFormFieldset>
      <ModalFormItem wide>
        <ModalFormItemLabel labelFor="cc">CC</ModalFormItemLabel>
        <Field name="cc" component="input" type="email"/>
      </ModalFormItem>
    </ModalFormFieldset>
    <ModalFormFieldset>
      <ModalFormItem wide>
        <ModalFormItemLabel labelFor="note">Note</ModalFormItemLabel>
        <Field name="note" component="textarea" type="text" />
      </ModalFormItem>      
    </ModalFormFieldset>

    <ModalActions onSubmit={handleSubmit }>
      <button type="submit">{confirmLabel}</button>
      <button onClick={onClose}>{cancelLabel}</button>
    </ModalActions>
  </ModalForm>
)

EmailForm = reduxForm({
  form: 'quoteEmail'
})(EmailForm);

const EmailFormModal = ({
  isOpen,
  emailModalQuoteNumber,
  cancelLabel,
  confirmLabel,
  onClose,
  onSubmit,
}) => {

  return (
    <Modal isOpen={isOpen} size="md">
      <EmailForm
        onSubmit={onSubmit}
        onClose={onClose}
        cancelLabel={cancelLabel}
        confirmLabel={confirmLabel}
      />
    </Modal>
  );
}

export default EmailFormModal;