import {
  UPDATE_PRODUCT_PAGE_SETTINGS,
} from './../../actions/types';

const defaultState = {
  isColorStepLoading: false,
  isVariantsLoading: false,
  showAddToCartNotice: false,
  showAddToCartWarning: false,
  lastCartName: '',
  isSampleOrdered: false,
  showOrderSampleNotice: false,
  updatedQuoteList : [],
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case UPDATE_PRODUCT_PAGE_SETTINGS:
      return Object.assign({}, state, action.settings)
    default:
      return state;
  }
}