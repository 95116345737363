import React, { Component } from 'react';
import classNames from 'classnames';
import Icon from '../Common/Icon';

class HeadCell extends Component {

  onClick = () => {
    const {
      onClick,
      name,
      sortBy,
      sortDirectionDown,
    } = this.props;

    if (onClick) {
      onClick(name, sortBy === name ? !sortDirectionDown : false);
    }
  }

  render() {

    const {
      children,
      name,
      sortDirectionDown,
      sortBy,
      onClick,
      isInternational,
    } = this.props;

    const showArrow = name && name === sortBy;
    const isSortable = !!name && onClick;
    const iconType = sortDirectionDown ? 'arrow-down' : 'arrow-up';
    const theadClasses = classNames('order-history-table__head-cell', {
      'order-history-table__head-cell--sortable': isSortable,
    });
    return (
      <td className={theadClasses} onClick={this.onClick}>
        <div className="order-history-table__head-cell__inner-wrapper">

          {children}

          {showArrow ? (
            <Icon type={iconType}/>
            ) : null}
          {isSortable ? (          
            <Icon type="caret-down"/>
          ) : null}
        </div>
      </td>
    );
  }
}

const TableHead = ({
  mot,
  invoice,
  shipping,
  orderTotal,
  shipToName,
  orderNumber,
  exFactoryDate,
  purchaseOrder,
  consolidateOrder,
  sortBy,
  sortDirectionDown,
  onHeadingClick,
  isInternational,
}) => (
  <thead className="order-history-table__head">
    <tr>
        <HeadCell 
          name="OrderNumber"
          onClick={onHeadingClick}
          sortBy={sortBy}
          sortDirectionDown={sortDirectionDown}
          >{orderNumber}</HeadCell>
        <HeadCell
          name="PurchaseOrder"
          onClick={onHeadingClick}
          sortBy={sortBy}
          sortDirectionDown={sortDirectionDown}>{purchaseOrder}</HeadCell>
        <HeadCell
          name="ConsolidateOrder"
          onClick={onHeadingClick}
          sortBy={sortBy}
          sortDirectionDown={sortDirectionDown}>{consolidateOrder}</HeadCell>
        <HeadCell>{isInternational ? exFactoryDate : shipping}</HeadCell>
        {!isInternational ? (<HeadCell>{shipToName}</HeadCell>) : null}
        
        <HeadCell
          name="Invoice"
          onClick={onHeadingClick}
          sortBy={sortBy}
          sortDirectionDown={sortDirectionDown}>{invoice}</HeadCell>
        <HeadCell>{orderTotal}</HeadCell>
        {isInternational ? (<HeadCell>{mot}</HeadCell>) : null}
    </tr>
  </thead>
)

export default TableHead;
