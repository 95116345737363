import {
  UPDATE_ORDER_HISTORY_PAGE_SETTINGS,
} from '../../actions/types';;

const defaultState = {
  query: '',
  startDate: null,
  endDate: null,
  isLoading: false,
  dirty: false,
  sortBy: 'OrderNumber',
  sordDirectionDown: false,
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case '':
      case UPDATE_ORDER_HISTORY_PAGE_SETTINGS:
        return Object.assign({}, state, action.settings);
    default:
      return state;
  }
}