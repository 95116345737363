import {
  SET_QUOTE_DETAILS_PAGE_ITEMS,
  SET_QUOTE_DETAILS_PAGE_QUOTE,
  SET_QUOTE_DETAILS_PAGE_SETTINGS,
  SET_QUOTE_DETAILS_PAGE_ESTIMATED_FREIGHT,
} from './types';

import quotesApiService from '../services/quotesApiService';

const setList = (list = []) => ({
  type: SET_QUOTE_DETAILS_PAGE_ITEMS,
  list,
});

const setQuoteDetails = (quote = null) => ({
  type: SET_QUOTE_DETAILS_PAGE_QUOTE,
  quote,
})

export const updateSettings = (settings = {}) => ({
  type: SET_QUOTE_DETAILS_PAGE_SETTINGS,
  settings,
});

export const showEmailModal = (emailModalQuoteNumber) => updateSettings({
  showEmailModal: true,
  emailModalQuoteNumber,
});

export const hideEmailModal = () => updateSettings({
  showEmailModal: false,
  emailModalQuoteNumber: null,
});

export const showRenameForm = () => updateSettings({
  showRenameForm: true,
});

export const hideRenameForm = () => updateSettings({
  showRenameForm: false,
});

const setEstimatedFreight = (list = []) => ({
  type: SET_QUOTE_DETAILS_PAGE_ESTIMATED_FREIGHT,
  list,
})

export const setQuote = (quote = {}) => (dispatch, getState) => {
  const {Products, ...rest} = quote;
  
  dispatch(setList(Products));
  dispatch(setQuoteDetails(rest));
}

export const getQuote = () => async (dispatch, getState) => {
  const {
    quoteDetailsPage: {
      quote,
    }
  } = getState();

  dispatch(updateSettings({
    isLoading: true,
  }));
  
  const newQuote = await quotesApiService.getQuote(quote.QuoteId);
  
  dispatch(updateSettings({
    isLoading: false,
  }));

  if (newQuote) {
    dispatch(setQuote(newQuote));
  }
};

export const duplicateQuote = (quoteId, projectName) => async (dispatch, getState) => {
  dispatch(updateSettings({
    isLoading: true,
  }));

    const data = await quotesApiService.duplicateQuote(quoteId, projectName);
  
  //showNotification(data);
  window.WOW.MiniQuoteCart.refresh();
  dispatch(updateSettings({
      isLoading: false,
      showAddToCartNotice:true,
  }));

    if (data.Success.Item2) {
        dispatch(updateSettings({
            quoteId: data.Success.Item2,
        }));
    }
}

export const sendQuoteViaEmail = (quoteNumber, { to, cc, note }) => (dispatch, getState) => {
  const {
    quoteDetailsPage: {
      freight,
    },
  } = getState();

  quotesApiService.sendQuoteViaEmail(quoteNumber, to, cc, note, freight);
}

export const purchaseQuote = (quoteNumber) => async (dispatch, getState) => {
  dispatch(updateSettings({
    isLoading: true,
  }));

  const data = await quotesApiService.purchaseQuote(quoteNumber);

  //showNotification(data);
  //window.WOW.MiniCart.refresh();
  //dispatch(updateSettings({
  //  isLoading: false,
  //}));

  window.location.href = "/quote-checkout/";
}

export const setActiveQuote = (quoteId, quoteProjectName) => async (dispatch, getState) => {
    dispatch(updateSettings({
        isLoading: true,
    }));

    const data = await quotesApiService.setActiveQuote(quoteId, quoteProjectName);

    window.location.href = "/";
}

export const selectFreightOption = (partIndex, optionIndex) => (dispatch, getState) => {

  const {
    quoteDetailsPage: {
      freight,
    }
  } = getState();

  const newList = freight.map((p, pi) => {
    if (pi === partIndex) {
      return Object.assign({}, p, {
        ShippingOptions: p.ShippingOptions.map((o, oi) => {
          return Object.assign({}, o, {
            Selected: oi === optionIndex
          });
        })
      })
    }

    return p;
  });

  dispatch(setEstimatedFreight(newList));

}

export const selectFreightSubOption =  (partIndex, optionIndex, subOptionIndex) => (dispatch, getState) => {

  const {
    quoteDetailsPage: {
      freight,
    }
  } = getState();

  const newList = freight.map((p, pi) => {
    if (pi === partIndex) {
      return Object.assign({}, p, {
        ShippingOptions: p.ShippingOptions.map((o, oi) => {
          if (oi === optionIndex) {
            return Object.assign({}, o, {
              SubOptions: o.SubOptions.map((so, soi) => {
                if (soi === subOptionIndex) {
                  return Object.assign({}, so, {
                    Checked: !so.Checked,
                  });
                }

                return so;
              })
            })
          }

          return o;
        })
      })
    }

    return p;
  });

  dispatch(setEstimatedFreight(newList));
}

export const estimateFreight = (quoteId, zipCode) => async (dispatch, getState) => {
  dispatch(updateSettings({
    isLoading: true,
  }));

  const data = await quotesApiService.estimateFreight(quoteId, zipCode);

  if (data) {
    dispatch(setEstimatedFreight(data));
  }

  dispatch(updateSettings({
    isLoading: false,
  }));
}

export const updateItemQuantity = (quoteNumber, variantCode, colorCode, quantity, quoteId, variantId) => async (dispatch, getState) => {

  dispatch(updateSettings({
    isLoading: true,
  }));

  await quotesApiService.updateItemQuantity(quoteNumber, variantCode, colorCode, quantity, quoteId, variantId);

  dispatch(getQuote());
}

export const addItemToCart = (quoteNumber, lineItemId, quantity) => async (dispatch, getState) => {

  dispatch(updateSettings({
    isLoading: true,
  }));

  const list = await quotesApiService.addItemToCart(quoteNumber, lineItemId, quantity);

  if (list && list.length) {    
      window.WOW.MiniSwatchCart.refresh();
      window.WOW.MiniCart.refresh();
    
      window.notificationService.show(`${list.length} items added to cart.`, false, true);
  }

  dispatch(getQuote());
}

export const removeItem = (quoteNumber, lineItemId, quoteId) => async (dispatch, getState) => {

  dispatch(updateSettings({
    isLoading: true,
  }));

  await quotesApiService.removeItem(quoteNumber, lineItemId, quoteId);

  dispatch(getQuote());
}

export const renameProject = (quoteId, name) => async (dispatch) => {
  
  dispatch(updateSettings({
    isLoading: true,
  }));

  await quotesApiService.renameProject(quoteId, name);

    window.WOW.MiniQuoteCart.refresh();

  dispatch(getQuote());
}

export const editShippingAddress = (quoteId, shipToLine1, shipToLine2, shipToCity, shipToState, shipToZip, shipToCountry) => async (dispatch) => {

    dispatch(updateSettings({
        isLoading: true,
    }));

    await quotesApiService.editShippingAddress(quoteId, shipToLine1, shipToLine2, shipToCity, shipToState, shipToZip, shipToCountry);

    window.WOW.MiniQuoteCart.refresh();

    dispatch(getQuote());
}

export const editMarketSegment = (quoteId, marketSegment) => async (dispatch) => {

    dispatch(updateSettings({
        isLoading: true,
    }));

    await quotesApiService.editMarketSegment(quoteId, marketSegment);

    window.WOW.MiniQuoteCart.refresh();

    dispatch(getQuote());
}

const showNotification = (data) => {
  if (data) {
    window.notificationService.show(data.Message, !data.Success, true);
  }
}