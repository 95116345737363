import { 
  SET_PRODUCT_PAGE_CONFIG_STEPS,
  SET_PRODUCT_PAGE_CONFIG_COLORS,
  SET_PRODUCT_PAGE_CONFIG_PALETTES,
  UPDATE_PRODUCT_PAGE_CONFIG_COLORS,
  UPDATE_PRODUCT_PAGE_CONFIG_PALETTES_OPTIONS,
} from './../../actions/types';

const defaultState = {
  steps: null,
  colorStep: null,
  paletteStep: null,
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_PRODUCT_PAGE_CONFIG_STEPS:
      return Object.assign({}, state, {
        steps: action.list,
      });
    case SET_PRODUCT_PAGE_CONFIG_COLORS:
      return Object.assign({}, state, {
        colorStep: action.step,
      });
    case UPDATE_PRODUCT_PAGE_CONFIG_COLORS:
      return Object.assign({}, state, {
        colorStep: Object.assign({}, state.colorStep, {
          Options: action.colors,
        }),
      });
    case SET_PRODUCT_PAGE_CONFIG_PALETTES:
      return Object.assign({}, state, {
        paletteStep: action.step,
      });
    case UPDATE_PRODUCT_PAGE_CONFIG_PALETTES_OPTIONS:
      return Object.assign({}, state, {
        paletteStep: Object.assign({}, state.paletteStep, {
          Options: action.options,
        }),
      });
    default: 
      return state;
  }
};