import React, { Component, useState, Fragment } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { connect } from 'react-redux';
import Modal from './../Common/Modal/Modal';
import ModalActions from './../Common/Modal/ModalActions';
import Cloak from './../Common/Cloak';

import InproProvider from './../InproProvider';
import {
  getList,
  setList,
  createQuote,
  deleteQuote,
  duplicateQuote,
  showEmailModal,
  hideEmailModal,
  hideDeleteModal,
  showDeleteModal,
    sendQuoteViaEmail,
    purchaseQuote,
} from './../../actions/myQuotesActionCreators';

import Table from './Table';
import Thead from './Thead';
import Row from './Row';
import EmailFormModal from '../shared/QuoteEmailFormModal';

import {
  fillTemplate,
} from '../../utils';

class MyQuotesPage extends Component {

  constructor(props) {
    super(props);   

    const {
      initialData = {},
      setList,
    } = this.props;

    this.state = {
      detailsPageUrl: initialData.detailsPageUrl ? initialData.detailsPageUrl : '',
        printPageUrl: initialData.printPageUrl ? initialData.printPageUrl : '',
        projectName: '',
        zipCode: '',
        marketSegment: '',

    }

    setList(initialData.quotes ? initialData.quotes : []);

      this.handleChange = this.handleChange.bind(this);

      this.handleChangeZipCode = this.handleChangeZipCode.bind(this);

      this.handleChangeMarketSegment = this.handleChangeMarketSegment.bind(this);
  }

  onEmailFormSubmit = (data) => {
    const {
      settings,
      sendQuote,
      hideEmailModal,
    } = this.props;
    sendQuote(settings.emailModalQuoteNumber, data);
    hideEmailModal();
  }

  onDuplicateButtonClick = (quoteId, projectName) => {
    const {
      duplicateQuote,
    } = this.props;

    duplicateQuote(quoteId, projectName);
    }

    onPurchaseQuoteClick = (quoteId) => {
        const {
            purchaseQuote,
        } = this.props;

        purchaseQuote(quoteId);
    }

  onDeleteModalConfirm = () => {
    const {
      settings,
      deleteQuote,
      hideDeleteModal,
    } = this.props;

    deleteQuote(settings.deleteModalQuoteNumber);
    hideDeleteModal();
  }

  onCreateQuoteClick = (e) => {
    e.preventDefault();

      const {
          projectName,
          zipCode,
          marketSegment
      } = this.state;

      const {
          marketSegments
      } = this.props.initialData;

      this.props.createQuote(projectName, zipCode, marketSegment);

      this.setState({ projectName: '' });
      this.setState({ zipCode: '' });
      this.setState({ marketSegment: '' });

     
    }

    handleChange(event) {
        this.setState({ projectName: event.target.value });
    }
    handleChangeZipCode(event) {
        this.setState({ zipCode: event.target.value });
    }
    handleChangeMarketSegment(event) {
        this.setState({ marketSegment: event.target.value });
    }
  render() {
    const {
      labels,
      quotes,
      settings,
      showDeleteModal,
      hideDeleteModal,
      showEmailModal,
        hideEmailModal,
    } = this.props;

      const {
          projectName,
          zipCode,
          marketSegment
      } = this.state;

      const {
          marketSegments
      } = this.props.initialData;

      const quoteDisplayNumber = settings.deleteModalQuoteNumber ? settings.deleteModalQuoteNumber.split('_')[0] : settings.deleteModalQuoteNumber;


    return (
        <section className="my-quotes-wrapper">


            <Popup trigger={<button className="button">Add New Quote</button>} modal>

                {close => (
                <div className="quoteModal">

                        <div className="quoteModelClose" onClick={close}>
                            x close
                        </div>


                        <form onSubmit={(event) => { this.onCreateQuoteClick(event); close(); }}>

                            <label>Enter Project Name</label>
                            <br />
                            <input required type="text" value={projectName} onChange={this.handleChange} />
                            <br />
                            <br />

                            <label>Project Zip Code</label>
                            <br />
                            <input required type="text" value={zipCode} onChange={this.handleChangeZipCode} />
                            <br />
                            <br />

                            {marketSegments ? (
                                <div>

                                    <label>Enter Market Segment</label>
                                    <br />

                                    <select required value={marketSegment} onChange={this.handleChangeMarketSegment}>

                                        <option value ="">Please select Market Segment</option>

                                        {marketSegments.map((q) => (

                                            <option value={q.Text}>{q.Text}</option>

                                        ))}

                                    </select>

                                    

                                    <br />
                                    <br />

                                </div>


                            ): null}

                            <input type="submit" value="Add New Quote" />
                        </form>



                    </div>

                )}
            </Popup>



        <Cloak active={settings.isLoading} showSpinner>
          <Table>
            <Thead labels={labels}/>
            <tbody>
              {quotes.map((q, i) => (
                <Row 
                  labels={labels}
                  key={q.QuoteNumber}       
                  {...q}
                  openDeleteModal={showDeleteModal}
                  openEmailModal={showEmailModal}
                  duplicate={this.onDuplicateButtonClick}
                  detailsPageUrl={this.state.detailsPageUrl}
                  printPageUrl={this.state.printPageUrl}
                  purchaseQuote={this.onPurchaseQuoteClick}
                  />
              ))}
            </tbody>
          </Table>
        </Cloak>

        {/* Delete Modal */}
        <Modal 
           isOpen={settings.showDeleteModal}
        >
                <p>{fillTemplate(labels.deleteConfirmationTemplate, { quote: quoteDisplayNumber })}</p>
          <ModalActions>
            <button onClick={this.onDeleteModalConfirm}>{labels.deleteConfirmation}</button>
            <button onClick={hideDeleteModal}>{labels.cancel}</button>
          </ModalActions>
        </Modal>

        {/* Email Modal */}
        <EmailFormModal
          isOpen={settings.showEmailModal}
          emailModalQuoteNumber={settings.emailModalQuoteNumber}
          cancelLabel={labels.cancel}
          confirmLabel={labels.sendConfirmation}
          onClose={hideEmailModal}          
          onSubmit={this.onEmailFormSubmit}
        />

      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    quotes: state.myQuotesPage.list,
    settings: state.myQuotesPage.settings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getList: () => dispatch(getList()),
    setList: (list) => dispatch(setList(list)),
    sendQuote: (quoteId, data) => dispatch(sendQuoteViaEmail(quoteId, data)),
    createQuote: (projectName, zipCode, marketSegment) => dispatch(createQuote(projectName, zipCode, marketSegment)),
    deleteQuote: (quoteNumber) => dispatch(deleteQuote(quoteNumber)),
    duplicateQuote: (quoteId, projectName) => dispatch(duplicateQuote(quoteId, projectName)),
    hideEmailModal: () => dispatch(hideEmailModal()),
    showEmailModal: (quoteId) => dispatch(showEmailModal(quoteId)),
    hideDeleteModal: () => dispatch(hideDeleteModal()),
      showDeleteModal: (quoteNumber) => dispatch(showDeleteModal(quoteNumber)),
      purchaseQuote: (quoteNumber) => dispatch(purchaseQuote(quoteNumber)),
  }
}

MyQuotesPage = connect(mapStateToProps, mapDispatchToProps)(MyQuotesPage);

export default (props) => (
  <InproProvider>
    <MyQuotesPage {...props}/>
  </InproProvider>
);
