import React, { Component } from 'react';
import Cloak from '../Common/Cloak';
import GalleryImages from './GalleryImages.js';

const DOTS = '...';

const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};


const getImages = (folderCode, page, selectedOptions) => commonPost('/Gallery/GetImages',
    {
        folderCode,
        page,
        selectedOptions,
    }
);

const commonPost = (url, body = {}) => fetch(url, {
    method: 'POST',
    headers: {
        'pragma': 'no-cache',
        'cache-control': 'no-cache',
        "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
}).then(res => res.status === 200 ? res.json() : null);



class Gallery extends Component {
    constructor(props) {
        super(props);

        const {
            folderCode,
            page,
            allFacetTags,
        } = props.initialData;

        this.state = {
            imageFolderCode: folderCode,
            pageNumber: page,
            imageData: null,
            loading: true,
            facets: allFacetTags
        }

    }

    componentDidMount() {
        getImages(this.state.imageFolderCode, this.state.pageNumber, this.state.facets).then(data => {
            if (data) {
                this.setState({
                    imageData: data
                });
            }

            this.setState({
                loading: false
            });

           

        })
    }


    facetCheckboxChange = (event, facetGroup, facetOption) => {

        let newFacets = this.state.facets;

        newFacets[facetGroup].FacetTagOptions[facetOption].Selected = !newFacets[facetGroup].FacetTagOptions[facetOption].Selected;

        this.setState({
            facets: newFacets,
            loading: true,
            pageNumber: 1
        }, () => {

            getImages(this.state.imageFolderCode, this.state.pageNumber, this.state.facets).then(data => {
                if (data) {
                    this.setState({
                        imageData: data
                    });
                }

                this.setState({
                    loading: false
                });



            })


        });

        //update url
        
        var params = "";
        if (this.state.facets != null) {
            this.state.facets.map((tag, i) => {
                var tagGroup = "tag" + (i + 1) + "=";
                var tagValue = "";
                if (tag.FacetTagName) {
                    tag.FacetTagOptions.map((tagOption, a) => {

                        if (tagOption.Selected) {
                            if (tagValue.length === 0) {
                                tagValue = tagOption.FacetTagOptionName;
                            }
                            else {
                                tagValue = tagValue + "," + tagOption.FacetTagOptionName;
                            }
                        }

                    })
                }

                if (params.length === 0) {
                    if (tagValue.length > 0) {
                        params = "?" + params + tagGroup + tagValue;
                    }
                }
                else {
                    if (tagValue.length > 0) {
                        params = params + "&" + tagGroup + tagValue;
                    }
                    
                }
                
            })
        }
        if (window.history && window.history.replaceState) {
            if (params.length > 0) {
                window.history.replaceState(null, null, params);
            }
            else {
                var url = document.location.href;
                window.history.replaceState({}, "", url.split("?")[0]);
            }
           
        }
   
        window.scrollTo({ top: 0, behavior: 'smooth' });
       

    };

    facetCheckboxClear = (event) => {

        let newFacets = this.state.facets;

        //Clear Choices, make every selected false
        if (newFacets != null) {

            newFacets.map((tag, i) => {
                
                if (tag.FacetTagName) {
                    tag.FacetTagOptions.map((tagOption, a) => {

                        tagOption.Selected = false;

                    })
                }

            })

        }

        this.setState({
            facets: newFacets,
            loading: true,
            pageNumber : 1
        }, () => {

            getImages(this.state.imageFolderCode, this.state.pageNumber, this.state.facets).then(data => {
                if (data) {
                    this.setState({
                        imageData: data
                    });
                }

                this.setState({
                    loading: false
                });



            })


        }


        );


        //remove params from url
        if (window.history && window.history.replaceState) {
            var url = document.location.href;
            window.history.replaceState({}, "", url.split("?")[0]);

        }







    };


    pageNumberChange = (event, page) => {

        event.preventDefault()

        let newPageNumber = page;

        this.setState({
            loading: true,
            pageNumber: newPageNumber
        }, () => {

            //Add Url to Params


            getImages(this.state.imageFolderCode, this.state.pageNumber, this.state.facets).then(data => {
                if (data) {
                    this.setState({
                        imageData: data
                    });
                }

                this.setState({
                    loading: false
                });



            })

        }


        );

        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    getPaginationRange = () => {

        let array = [];

        if (this.state.imageData != null) {
            let currentPage= this.state.pageNumber;
            let total = this.state.imageData.totalPages;

            let siblingCount = 1;



            const totalPageCount = total;

            // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
            const totalPageNumbers = siblingCount + 5;

            /*
              If the number of pages is less than the page numbers we want to show in our
              paginationComponent, we return the range [1..totalPageCount]
            */
            if (totalPageNumbers >= totalPageCount) {
                return range(1, totalPageCount);
            }

            const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
            const rightSiblingIndex = Math.min(
                currentPage + siblingCount,
                totalPageCount
            );

            /*
              We do not want to show dots if there is only one position left 
              after/before the left/right page count as that would lead to a change if our Pagination
              component size which we do not want
            */
            const shouldShowLeftDots = leftSiblingIndex > 2;
            const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

            const firstPageIndex = 1;
            const lastPageIndex = totalPageCount;

            if (!shouldShowLeftDots && shouldShowRightDots) {
                let leftItemCount = 3 + 2 * siblingCount;
                let leftRange = range(1, leftItemCount);

                return [...leftRange, DOTS, totalPageCount];
            }

            if (shouldShowLeftDots && !shouldShowRightDots) {
                let rightItemCount = 3 + 2 * siblingCount;
                let rightRange = range(
                    totalPageCount - rightItemCount + 1,
                    totalPageCount
                );
                return [firstPageIndex, DOTS, ...rightRange];
            }

            if (shouldShowLeftDots && shouldShowRightDots) {
                let middleRange = range(leftSiblingIndex, rightSiblingIndex);
                return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
            }


            
        }
        else {
            return array;
        }

    };


    render() {

        

        let paginationRange = this.getPaginationRange();

        

        return (
            <div className="galleryContainer">

                <div className="galleryFacets">
                    {
                        this.state.facets != null ? (

                            <div className="galleryFacetsClear" onClick={(event) => this.facetCheckboxClear(event)}>Clear Options</div>

                        ): null
                    }
                    

                    {
                        this.state.facets != null ? (

                          

                            this.state.facets.map((tag, i) => (

                                tag.FacetTagName ? (

                                    <div className="galleryFacetSection">


                                        <h4 className="galleryFacetName">{tag.FacetTagName}</h4>

                                        <ul className="galleryFacetOptions">
                                        {
                                            tag.FacetTagOptions.map((tagOption, a) => (
                                                <li className="galleryFacetOption">
                                                    <label>

                                                        <input type="checkbox" checked={tagOption.Selected} onChange={(event) => this.facetCheckboxChange(event, i, a)} />

                                                    {tagOption.FacetTagOptionName}
                                                
                                                    </label>
                                                </li>
                                                
                                                ))
                                        }
                                        </ul>

                                    </div>

                                
                                ):null



                            ))
                           

                        ): null
                    }


                </div>

                <div className="galleryImagesContainer">

                    <Cloak active={this.state.loading} showSpinner>

                    {
                        this.state.imageData != null ? (
                            <GalleryImages list={this.state.imageData.galleryImages}>
                               
                        
                            </GalleryImages>

                            ) : null
                           

                    }
                    </Cloak>


                    {
                        this.state.imageData != null ? (

                            

                            <div className="pagination">

                                {
                                    this.state.pageNumber > 1 ? (
                                        //Show Previous Button if Current Page is more than 1
                                        <a href className="pagination-arrow" onClick={(event) => this.pageNumberChange(event, this.state.pageNumber - 1)}><i className="fa fa-angle-left"></i></a>
                                    ) : null
                                }


                                {paginationRange.map(pageNumber => {
                                    if (pageNumber === DOTS) {
                                        return <span class="pagination_dots">...</span>;
                                    }
                                    if (this.state.pageNumber == pageNumber) {
                                        return <a href className="active" onClick={(event) => this.pageNumberChange(event, pageNumber)}>{pageNumber}</a>;
                                    }
                                    else {
                                        return <a href onClick={(event) => this.pageNumberChange(event, pageNumber)}>{pageNumber}</a>;
                                    }
                                })}



                                {
                                    this.state.pageNumber < this.state.imageData.totalPages ? (
                                        ///Show Next Button if currrent page is less than total page
                                        <a href className="pagination-arrow" onClick={(event) => this.pageNumberChange(event, this.state.pageNumber + 1)}><i className="fa fa-angle-right"></i></a>
                                    ) : null
                                }


                            </div>



                        ) : null
                    }



                </div>


            
            </div>

        )

    }

}

export default Gallery;