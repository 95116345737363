import React, { Component, Fragment } from 'react';

import { fillTemplate } from './../../../utils';

import Row from './Row';
import THead from './THead';
import Footer from './Footer';
import Selections from './Selections';

import Select, { Option } from 'rc-select';
import NewFooter from './NewFooter.js';

class NewShopProductTable extends Component {

    onQtyChange = (e) => {
        e.preventDefault();

        const qty = e.currentTarget.value;
        const quantity = parseInt(qty);

        const {
            selectedVariantItem,
            updateVariant,
        } = this.props;

        const Code = selectedVariantItem.Code;
        updateVariant({
            quantity,
            Code,
        })

    }

    render() {
        const {
            list,
            steps,
            labels,
            quoteList,
            updateVariant,
            showAddToQuote,
            isColorSelected,
            minimumPurchaseAmount,
            onChange,
            selectedVariantItem,
            isDiscontinued,
            isAvailableForPurchase,
            internetPartNumberSymbol,
            disclaimerMessage,
            selectedColorPaletteName,
            selectedColorName,
            selectedColorId,
            selectedColorCode,
            marketSegments,
            activeQuoteId,
            activeQuoteProjectName,
        } = this.props;

        const totalPrice = list.reduce((sum, i) => {
            const price = i.Price || 0;
            const qty = i.quantity || 0;
            const setupFees = i.SetupFees && qty && qty <= i.SetupFeeMaxQuantity ? i.SetupFees : 0;
            return sum + price * qty + setupFees;
        }, 0);

        const hasItemsInCart = list.filter(i => i.quantity).length;
        const hasPrice = selectedVariantItem ? selectedVariantItem.Price > 0 : false;
        const allowedToAdd = hasPrice ? isColorSelected || selectedVariantItem.HasNoColor : false
        const variants = list.filter(v => !v.IsComponent);
        const components = list.filter(v => v.IsComponent);

        const Selected = true;
        const quantity = 1;

        const skuName = selectedVariantItem ? selectedVariantItem.Code : "";
        const finalSkuName = selectedVariantItem && selectedVariantItem.ParentSKU ? selectedVariantItem.ParentSKU : skuName;

        const colorCodeOrID = selectedVariantItem && selectedColorName && selectedColorId ? selectedColorId : "";
        const finalColorCodeOrID = selectedVariantItem && selectedColorName && selectedColorCode ? selectedColorCode : colorCodeOrID;

        return (

            <div className="mobileTable">

              
                {variants.length || components.length ? (

                    list.length == 1 ? (

                        selectedVariantItem ? (

                            selectedVariantItem.isSKUAvailableForPurchase && hasPrice && isAvailableForPurchase ? (

                                <div className="singleSKU">{selectedVariantItem ? selectedVariantItem.Specs + " - $" + selectedVariantItem.Price + " (" + selectedVariantItem.Code + ")" : ""}</div>
                            ) :

                                <div className="singleSKU">{selectedVariantItem ? selectedVariantItem.Specs + " (" + selectedVariantItem.Code + ")" : ""}</div>

                                

                        ) : null
                        
                    ) :

                        <Select
                            onChange={(value) => {
                                const Code = value;
                                onChange({ Selected, Code, quantity });
                            }}
                            optionFilterProp="children"
                            optionLabelProp="children"
                            placeholder="Specs - Price (Part)"
                            showSearch={false}
                            showArrow={true}
                            value={selectedVariantItem ? selectedVariantItem.Specs + (selectedVariantItem.isSKUAvailableForPurchase && hasPrice && isAvailableForPurchase ? " - $" + selectedVariantItem.Price : " ") + " (" + selectedVariantItem.Code + ")" : []}
                        >

                            {variants.map((option, i) => (
                                <Option
                                    value={option.Code}
                                    key={i} selected={option.Selected}>{option.Specs + (option.isSKUAvailableForPurchase && isAvailableForPurchase ? " - $" + option.Price : "")       + " (" + option.Code + ")"}</Option>
                            ))}

                            {
                                components.length ? (
                                    <Option value={""} disabled={true}>{"Components"}</Option>
                                ) : null
                            }

                            {components.map((option, i) => (
                                <Option
                                    value={option.Code}
                                    key={i} selected={option.Selected}>{option.Specs + (isAvailableForPurchase ? " - $" + option.Price : "") + " (" + option.Code + ")"}</Option>
                            ))}

                        </Select>

                ) : null
                }

                {selectedVariantItem && selectedColorName && finalColorCodeOrID && !selectedVariantItem.PermutatedSKU ? (
                    <div className="internetPartNumber">{"Internet Part Number: " + finalSkuName + internetPartNumberSymbol + finalColorCodeOrID}</div>
                ) : null}


                {disclaimerMessage ? (<div className="disclaimerMessage">{disclaimerMessage}
                </div>) : null}

                {
                    selectedVariantItem && selectedVariantItem.isSKUAvailableForPurchase && hasPrice && isAvailableForPurchase ? (

                        <Row key={selectedVariantItem.Code} {...selectedVariantItem} updateVariant={updateVariant} isColorSelected={isColorSelected} leadTimeLabel={labels.leadTime} />

                    ) : null
                }


                {
                    selectedVariantItem && selectedVariantItem.isSKUAvailableForPurchase && hasPrice && !isAvailableForPurchase ? (

                        <div className="speedShopProductColumn quantityNotAvailableForPurchase">

                            <label htmlFor={"quantity-" + selectedVariantItem.Code}>{"Quantity"}</label>

                            <input
                                id={"quantity-" + selectedVariantItem.Code}
                                type="text"
                                name="Quantity"
                                value={selectedVariantItem.quantity > 0 ? selectedVariantItem.quantity : ''}
                                min={selectedVariantItem.MinQuantity}
                                max={selectedVariantItem.MaxQuantity}
                                placeholder="0"
                                step="1"
                                className="width--input-narrow"
                                onChange={this.onQtyChange} />
                        </div>

                    ) : null
                }



                {
                    selectedVariantItem && !(selectedVariantItem.isSKUAvailableForPurchase && hasPrice) ? (

                        <div className="speedShopProductColumn quantityNotAvailableForPurchase">

                            <label htmlFor={"quantity-" + selectedVariantItem.Code}>{"Quantity"}</label>

                            <input
                                id={"quantity-" + selectedVariantItem.Code}
                                type="text"
                                name="Quantity"
                                value={selectedVariantItem.quantity > 0 ? selectedVariantItem.quantity : ''}
                                min={selectedVariantItem.MinQuantity}
                                max={selectedVariantItem.MaxQuantity}
                                placeholder="0"
                                step="1"
                                className="width--input-narrow"
                                onChange={this.onQtyChange} />
                        </div>

                    ) : null
                }




                {totalPrice > 0 && minimumPurchaseAmount ? (
                    <div>
                        {fillTemplate(labels.minimumPurchaseAmountWarning, { amount: minimumPurchaseAmount })}
                    </div>
                ) : null}



                <NewFooter
                    totalPrice={totalPrice}
                    hasItemsInCart={!!hasItemsInCart}
                    allowedToAdd={allowedToAdd}
                    quoteList={quoteList}
                    {...this.props}
                    showAddToQuote={showAddToQuote}
                    isDiscontinued={isDiscontinued}
                    selectedVariantItem={selectedVariantItem}
                    steps={steps}
                    selectedColorPaletteName={selectedColorPaletteName}
                    selectedColorName={selectedColorName}
                    selectedColorId={selectedColorId}
                    selectedColorCode={selectedColorCode}
                    isAvailableForPurchase={isAvailableForPurchase}
                    internetPartNumberSymbol={internetPartNumberSymbol}
                    activeQuoteId={activeQuoteId}
                    activeQuoteProjectName={activeQuoteProjectName}
                    marketSegments={marketSegments}
                />
            </div>

        );
    }
};

export default NewShopProductTable;