import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'

import productPage from './productPage';
import orderHistoryPage from './orderHistoryPage';
import documentSearchPage from './documentSearchPage';
import seminarPage from './seminarPage';
import myQuotesPage from './myQuotesPage';
import quoteDetailsPage from './quoteDetailsPage';

export default combineReducers({
  productPage,
  seminarPage,
  myQuotesPage,
  quoteDetailsPage,
  orderHistoryPage,
  documentSearchPage,
  form: formReducer,
});