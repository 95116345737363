import {
  SET_QUOTE_DETAILS_PAGE_SETTINGS,
} from './../../actions/types';

const defaultState = {
  isLoading: false,
  showDeleteModal: false,
  deleteModalQuoteNumber: '',
  showEmailModal: false,
  emailModalQuoteNumber: '',
    showRenameForm: false,
    showAddToCartNotice: false,
    quoteId: '',
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_QUOTE_DETAILS_PAGE_SETTINGS:
      return Object.assign({}, state, action.settings);
    default:
      return state;
  }
}