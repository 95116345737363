import React, { Component, Fragment } from 'react';
import CartGroupContent from '../Common/CartGroupContent';
import Variant from '../Common/CartGroupContent/Variant';

class QuotePrintPage extends Component {

  render() {
    const {
      quote,
      labels,
    } = this.props;

      if (quote) {          
          return (           
                  <div>
                  {quote.QuoteProductList.length ? (
                          <ul>
                          {quote.QuoteProductList.map((p) => (
                                  <Variant
                                      key={p.Code}
                                        {...p}
                                      readonly
                                      labels={labels}
                                  />
                              ))}
                          </ul>
                      ) : null}
                  </div>              
          )
    } else {
          return (              
                  <div>There is no selected quote</div>              
      );
    }

  }

}

export default QuotePrintPage;