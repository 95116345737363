import { getWOWTab, scrollTo, } from '../utils';

import {
  SET_SEMINAR_PAGE_ITEMS,
  UPDATE_SEMINAR_PAGE_SETTINGS,
} from './types';

export const setSeminarPageList = (list = []) => ({
  type: SET_SEMINAR_PAGE_ITEMS,
  list,
});

export const updateSeminarPageListItem = (item, props = {}, cb) => (dispatch, getState) => {
  const {
    seminarPage: { 
      list: items,
      settings: {
        formAnchor,
        tabsContextId,
      }
    },
  } = getState();

  const newItems = items.map(i => {
    if (i === item) {
      return Object.assign({}, i, props);
    }
    return i;
  });

  dispatch(setSeminarPageList(newItems));
  const selected = newItems.filter(s => s.selected);
  const hiddenInput = document.getElementById('Form_DocumentNames');
  if (hiddenInput) {
    hiddenInput.value = selected.map(i => i.title).join('`');
  }

  const showFormTab = !!selected.length;

  dispatch(updateSettings({
    showFormTab,
  }));

  const formTab = document.getElementById(tabsContextId);
  const formTabIsShown = formTab.style.display === 'block';
  if (showFormTab && !formTabIsShown) {
    formTab.style.display = 'block';
    scrollTo(formAnchor);
    setTimeout(() => {
      //Wait for jQuery animate on scroll
      const tab = getWOWTab(tabsContextId, formAnchor);
      if (tab) {
        tab.open();
      }
    }, 500);
  } else if (formTabIsShown && !showFormTab) {
    formTab.style.display = 'none';
    }

    if (showFormTab) {
        var i;
        var showDivisionOfInterestsBoolean = false;
        for (i = 0; i < selected.length; i++) {
            if (selected[i].showDivisionOfInterests) {
                showDivisionOfInterestsBoolean = true;
                break;
            }
        }

        if (document.getElementById("divisionOfInterestSection")) {
            if (showDivisionOfInterestsBoolean) {
                document.getElementById("divisionOfInterestSection").style.display = 'block';
            }
            else {
                document.getElementById("divisionOfInterestSection").style.display = 'none';
            }
        }


    }

};

export const updateSettings = (settings) => {
  return {
    type: UPDATE_SEMINAR_PAGE_SETTINGS,
    settings,
  }
}

export const initSeminarList = (list = []) => {
  return {
    type: SET_SEMINAR_PAGE_ITEMS,
    list: list.map(i => Object.assign({}, i, { selected: false })),
  };
};