import {
  SET_DOCUMENTS_SEARCH_PAGE_DOCUMENTS,
} from './../../actions/types';

export default (state = [], action) => {
  switch(action.type) {
    case SET_DOCUMENTS_SEARCH_PAGE_DOCUMENTS:
      return action.list;
    default:
      return state;
  }
}