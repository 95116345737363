import React, { Component } from 'react';
import Color from './Color';


class ColorPicker extends Component {

    state =
        {
            showAllColors: false
        }

    onClickShowAll = () => {
        this.setState({
            showAllColors: true
        });
    }

  render() {

    const {
      colors,
      onChange,
    } = this.props;

      const size = this.state.showAllColors ? colors.length : 16;

    return (

        <div className="color-section">

            <div className="product-color-step">
                {colors.slice(0, size).map((color, i) => (
                    <Color
                        {...color} key={color.Value}
                        onClick={e => {
                            e.preventDefault();
                            onChange(color.Value, color.Image, color.ColorAndSKUFilter, color.Label);
                        }} />
                ))}
            </div>

            { colors.length > 16 && !this.state.showAllColors ? (

                <div className="showAllColors" onClick={this.onClickShowAll}>Show All Colors</div>

                ): null


            }

            
            
        </div>


    );
  }
}

export default ColorPicker;