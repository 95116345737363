import React, { Component } from "react";
import classNames from 'classnames';

export default class RadioButton extends Component {
  onChange = (event) => {
    if (this.props.disabled) {
      event.preventDefault();
      return;
    }
    this.props.onChange(this.props.value);
  }
  render() {

    const {
      children,
      selected,
      marginLess,
      className,
    } = this.props;
    
    return (
      <button
        className={classNames('inpro-radio-button', className, {
          'inpro-radio-button--selected': selected,
          'inpro-radio-button--margin-less': marginLess
        })}
        onClick={this.onChange}
      >
        <span className="inpro-radio-button__icon"/>
        <span className="inpro-radio-button__text">
          { children }
        </span>
      </button>
    );
  }
}

RadioButton.defaultProps = {
  onChange: () => {},
};