import React, { Component } from 'react';
import Part from './Part';

class CheckoutShippingMethods extends Component {
  constructor(props) {
    super(props);    

    this.state = {
      ...this.props.shippingMethodsInitialData,
    };    

    if (typeof document != 'undefined') {
      this.populateHiddenInput();
    }
  }

  updateContinueButton = () => {

    const hasUnSelectedPart = this.state.Parts.find(p => {
      const selectedOption = p.ShippingOptions.find(so => so.Selected);

      return !selectedOption;
    });

    if (!hasUnSelectedPart) {
      const button = document.querySelector('.js-shipping-method-step-continue');
  
      if (button) {
        button.disabled = false;
      }
    }

  }

  onOptionSelect = (partIndex, optionIndex) => {

    
    const newParts = this.state.Parts.map((p, pi) => {
      if (pi === partIndex) {
        return Object.assign({}, p, {
          ShippingOptions: p.ShippingOptions.map((o, oi) => {
            return Object.assign({}, o, {
              Selected: oi === optionIndex
            });
          })
        })
      }
      
      return p;
    });
    
    this.setState({
      Parts: newParts,
    }, () => {
      this.populateHiddenInput();
      this.updateContinueButton();
    });

  }

  onNotesChange = (partIndex, notes) => {
    const newParts = this.state.Parts.map((p, pi) => {
      if (pi === partIndex) {
        return Object.assign({}, p, {
          Notes: notes,
        });
      }

      return p;
    });

    this.setState({
      Parts: newParts,
    }, () => {
      this.populateHiddenInput();
    });
  }

  onSubOptionCheck = (partIndex, optionIndex, subOptionIndex) => {
    const newParts = this.state.Parts.map((p, pi) => {
      if (pi === partIndex) {
        return Object.assign({}, p, {
          ShippingOptions: p.ShippingOptions.map((o, oi) => {
            if (oi === optionIndex) {
              return Object.assign({}, o, {
                SubOptions: o.SubOptions.map((so, soi) => {
                  if (soi === subOptionIndex) {
                    return Object.assign({}, so, {
                      Checked: !so.Checked,
                    });
                  }

                  return so;
                })
              })
            }

            return o;
          })
        })
      }

      return p;
    });

    this.setState({
      Parts: newParts,
    }, () => {
      this.populateHiddenInput();
    });
  }

  populateHiddenInput = () => {
    const hiddenInput = document.getElementById('SelectedMethod');

    if (hiddenInput) {
      hiddenInput.value = JSON.stringify({
        Parts: this.state.Parts,
      })
    }
  }
  
  render() {

    const {
      Parts = [],
    } = this.state;

    return (
      <ul className="checkout-split-shipping">
        {Parts.map((p, i) => (
          <Part 
            key={i} 
            partIndex={i}
            {...p} 
            onOptionSelect={this.onOptionSelect}
            onSubOptionCheck={this.onSubOptionCheck}
            onNotesChange={this.onNotesChange}/>
        ))}
      </ul>
    );
  }
}

export default CheckoutShippingMethods;