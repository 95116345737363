import React from 'react';
import classNames from 'classnames';

export default ({
  children,
  showSpinner,
  active,
  text,
}) => (
  <div className={classNames("cloak", {
    "cloak--active": active,
  })}>
    {active && (
        <span className="cloak__shadow"/>
    )}        
    {active && showSpinner && (
        <div className="cloak__spinner">
          <i className="fa fa-spinner"/>
                {text ? (<span>{text}</span>) : (<span>Loading...</span>)}
                
        </div>
    )}        
    {children}
  </div>
);