import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'

import reducers from './../reducers/';

const logger = createLogger({collapsed: true});
const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}


export default () => {

  return createStore(reducers, applyMiddleware(...middlewares));

}