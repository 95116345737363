import { combineReducers } from 'redux';

import list from './listReducer';
import quote from './quoteReducer';
import freight from './freightReducer';
import settings from './settingsReducer';

export default combineReducers({
  list,
  quote,
  freight,
  settings,
});