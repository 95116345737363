import {
  commonGet,
  commonPost,
} from '../apiHelper';

const apiMethods = {
  colors: '/api/Products/GetColors',
}

export const getColors = (paletteCode, productCode) => commonPost(apiMethods.colors, { paletteCode, productCode });

