import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import {
  renameProject,
  showRenameForm,
    hideRenameForm,
    editShippingAddress,
    editMarketSegment
} from '../../../actions/quoteDetailsPageActionCreators';

import InproProvider from './../../InproProvider';
import Cloak from '../../Common/Cloak';

const RenameForm = ({
  submit, 
  cancel,
  oldName,
}) => {
  let [name, setName] = useState(oldName);
  
  const onNameChange = (e) => {
    setName(e.target.value);
  }

  const onSubmitClick = (e) => {
    submit(name);
  };

  const onCancelClick = (e) => {
    cancel();
  };

  return (
    <form className="quote-details-page-header__rename-form">
      <input type="text" onChange={onNameChange} value={name ? name : ''}/>
      <button className="button button--link quote-details-page-header__rename-form__button" onClick={onSubmitClick}>rename</button>
      <button className="button button--link quote-details-page-header__rename-form__button" onClick={onCancelClick}>cancel</button>
    </form>
  );
};

const Lock = ({isLocked}) => {
  if (isLocked) {
    return (
      <svg style={{width: 25, height: 25}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock" className="svg-inline--fa fa-lock fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path></svg>
    );
  } else {
    return (
      <svg style={{width: 25, height: 25}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock-open" className="svg-inline--fa fa-lock-open fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M423.5 0C339.5.3 272 69.5 272 153.5V224H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48h-48v-71.1c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v80c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-80C576 68 507.5-.3 423.5 0z"></path></svg>
    );
  }
}

class QuoteDetailsHeader extends Component {

  constructor(props) {
      super(props);

      const {
          quote
      } = this.props.initialData;

      this.state = {

          quoteId: quote && quote.QuoteId ? quote.QuoteId : '',
          shipToLine1: quote && quote.ShipToLine1 ? quote.ShipToLine1: '',
          shipToLine2: quote && quote.ShipToLine2 ? quote.ShipToLine2 : '',
          shipToCity: quote && quote.ShipToCity ? quote.ShipToCity : '',
          shipToState: quote && quote.ShipToState ? quote.ShipToState : '',
          shipToZip: quote && quote.ShipToZip ? quote.ShipToZip : '',
          shipToCountry: quote && quote.ShipToCountry ? quote.ShipToCountry : '',

          marketSegment: quote && quote.MarketSegment ? quote.MarketSegment : ''

      }

      this.handleChangeShipToLine1 = this.handleChangeShipToLine1.bind(this);

      this.handleChangeShipToLine2 = this.handleChangeShipToLine2.bind(this);

      this.handleChangeShipToCity = this.handleChangeShipToCity.bind(this);

      this.handleChangeShipToState = this.handleChangeShipToState.bind(this);

      this.handleChangeShipToZip = this.handleChangeShipToZip.bind(this);

      this.handleChangeShipToCountry = this.handleChangeShipToCountry.bind(this);

      this.handleChangeMarketSegment = this.handleChangeMarketSegment.bind(this);

  }

    onEditShippingAddressClick = (e) => {
        e.preventDefault();

        const {
          quoteId,
          shipToLine1,
          shipToLine2,
          shipToCity,
          shipToState,
          shipToZip,
          shipToCountry
        } = this.state;

        this.props.editShippingAddress(
            quoteId,
            shipToLine1,
            shipToLine2,
            shipToCity,
            shipToState,
            shipToZip,
            shipToCountry
        );


    }

    onEditMarketSegmentClick = (e) => {
        e.preventDefault();

        const {
            quoteId,
            marketSegment
        } = this.state;


        this.props.editMarketSegment(quoteId, marketSegment);

    }

    handleChangeShipToLine1(event) {
        this.setState({ shipToLine1: event.target.value });
    }
    handleChangeShipToLine2(event) {
        this.setState({ shipToLine2: event.target.value });
    }
    handleChangeShipToCity(event) {
        this.setState({ shipToCity: event.target.value });
    }
    handleChangeShipToState(event) {
        this.setState({ shipToState: event.target.value });
    }
    handleChangeShipToZip(event) {
        this.setState({ shipToZip: event.target.value });
    }
    handleChangeMarketSegment(event) {
        this.setState({ marketSegment: event.target.value });
    }
    handleChangeShipToCountry(event) {



        this.setState({ shipToCountry: event.target.value });


        if (event.target.value) {
            if (event.target.value.toLowerCase() == "united states" || event.target.value.toLowerCase() == "canada") {

            }
            else {
                this.setState({ shipToState: "" });
            }
        }
    }

  render() {

    const {
      quote,
      labels,
      settings,
      showRenameForm,
      hideRenameForm,
      renameProject,
      initialData = {},
    } = this.props;

      const {
          shipToLine1,
          shipToLine2,
          shipToCity,
          shipToState,
          shipToZip,
          shipToCountry,
          marketSegment
      } = this.state;

      const {
          marketSegments
      } = this.props.initialData;

      const countries = initialData.countries;
      const USAStates = initialData.USAStates;
      const CANStates = initialData.CANStates;

      var finalStates = null;

      if (shipToCountry) {
          if (shipToCountry.toLowerCase() == "united states") {
              finalStates = USAStates;
          }
          else if (shipToCountry.toLowerCase() == "canada") {
              finalStates = CANStates;
          }
          else {
              finalStates = null;
          }
      }

    if (!quote) {
      return (
        <div></div>
      );
    }

    return (
      <Cloak active={settings.isLoading}>
        <div className="quote-details-page-header width--layout">
          <h1>Quote #{quote.QuoteNumber} <Lock isLocked={quote.IsLocked}/> </h1>
          <div className="quote-details-page-header__row">
            <div className="quote-details-page-header__column">
              <div>Project Name: 
                { settings.showRenameForm ? (
                <RenameForm 
                  oldName={quote.ProjectName}
                  cancel={hideRenameForm}
                  submit={(name) => {
                    renameProject(quote.QuoteId, name);
                    hideRenameForm();
                  }}/>
                            ) : (
                                    <span>
                                        {quote.IsLocked ? (<span>{quote.ProjectName}</span>) :

                                            (<span>{quote.ProjectName} <button className="button button--link" onClick={(e) => {
                                            e.preventDefault();
                                            showRenameForm();
                                        }}>rename</button></span>)}
                                    </span>
                )}
              </div>
            </div>
            <div className="quote-details-page-header__column">
              <div>Last Update: <span>{quote.LastModifiedText}</span></div>
            </div>
          </div>
          <div className="quote-details-page-header__row">
            <div className="quote-details-page-header__column">
              <div>Account: <span>{quote.AccountNumber}</span></div>
            </div>
            <div className="quote-details-page-header__column">
              <div>Created By: <span>{quote.AccountName}</span></div>
            </div>
          </div>

                <div className="quote-details-page-header__row">
                    <div className="quote-details-page-header__column">
                        <div>Status: <span>{quote.Status}</span></div>
                    </div>

                    <div className="quote-details-page-header__column">
                        <div>Market Segment: {quote.MarketSegment} </div>

                        {!quote.IsLocked ? (
                            <Popup trigger={<div className="quote-details-edit-market-segment-text">Edit Market Segment</div>} modal>

                                {close => (
                                    <div className="quoteModal">

                                        <div className="quoteModelClose" onClick={close}>
                                            x close
                                        </div>


                                        <form onSubmit={(event) => { this.onEditMarketSegmentClick(event); close(); }}>


                                            {marketSegments ? (
                                                <div>
                                                    <label>Market Segment</label>
                                                    <br />
                                                    <select value={marketSegment} onChange={this.handleChangeMarketSegment}>
                                                        {marketSegments.map((q) => (
                                                            <option value={q.Text}>{q.Text}</option>
                                                        ))}
                                                    </select>
                                                    <br />
                                                    <br />
                                                </div>
                                            ) : null}


                                            <input type="submit" value="Submit" />
                                        </form>



                                    </div>

                                )}
                            </Popup>
                        ) : null}
                    </div>



                </div>

               
                    <div className="quote-details-page-header__row">
                    <div className="quote-details-page-header__column">
                        <div>Shipping Address: <br></br> {quote.ShipToLine1} <br></br> {quote.ShipToLine2} {quote.shipToLine2 ? (<br></br>) : null} {quote.ShipToCity}, {quote.ShipToState} {quote.ShipToZip} <br></br> {quote.ShipToCountry} </div>

                        {!quote.IsLocked ? (
                                <Popup trigger={<div className="quote-details-edit-address-text">Edit Shipping Address</div>} modal>

                                    {close => (
                                        <div className="quoteModal">

                                            <div className="quoteModelClose" onClick={close}>
                                                x close
                                            </div>


                                        <form onSubmit={(event) => { this.onEditShippingAddressClick(event); close(); }}>

                                                <label>Address Line 1</label>
                                                <br />
                                                <input type="text" value={shipToLine1} onChange={this.handleChangeShipToLine1} />
                                                <br />
                                                <br />

                                                <label>Address Line 2</label>
                                                <br />
                                                <input type="text" value={shipToLine2} onChange={this.handleChangeShipToLine2} />
                                                <br />
                                                <br />

                                                <label>City</label>
                                                <br />
                                                <input type="text" value={shipToCity} onChange={this.handleChangeShipToCity} />
                                                <br />
                                                <br />

           

                                                {finalStates ? (
                                                    <div>
                                                        <label>State/Province</label>
                                                        <br />
                                                        <select value={shipToState} onChange={this.handleChangeShipToState}>
                                                            {finalStates.map((q) => (
                                                                <option value={q.Text}>{q.Text}</option>
                                                            ))}
                                                        </select>
                                                        <br />
                                                        <br />
                                                    </div>
                                                ) : null}


                                                <label>Zip</label>
                                                <br />
                                                <input required type="text" value={shipToZip} onChange={this.handleChangeShipToZip} />
                                                <br />
                                                <br />


                                                {countries ? (
                                                    <div>
                                                        <label>Country</label>
                                                        <br />
                                                        <select value={shipToCountry} onChange={this.handleChangeShipToCountry}>
                                                            {countries.map((q) => (
                                                                <option value={q.Text}>{q.Text}</option>
                                                            ))}
                                                        </select>
                                                        <br />
                                                        <br />
                                                    </div>
                                                ) : null}


                                                <input type="submit" value="Submit" />
                                            </form>



                                        </div>

                                    )}
                                </Popup>
                        ) : null}

                        </div>


                    </div>
                    

                    
                    



        </div>
      </Cloak>
    );

  }

}

const mapProps = (state) => {
  return {
    quote: state.quoteDetailsPage.quote,
    settings: state.quoteDetailsPage.settings,
  };
}

const mapDispatch = (dispatch) => {
  return {
    showRenameForm: () => dispatch(showRenameForm()),
    hideRenameForm: () => dispatch(hideRenameForm()),
    renameProject: (quoteId, name) => dispatch(renameProject(quoteId, name)),
      editShippingAddress: (quoteId, shipToLine1, shipToLine2, shipToCity, shipToState, shipToZip, shipToCountry) => dispatch(editShippingAddress(quoteId, shipToLine1, shipToLine2, shipToCity, shipToState, shipToZip, shipToCountry)),
      editMarketSegment: (quoteId, marketSegment) => dispatch(editMarketSegment(quoteId, marketSegment))
  };
}

QuoteDetailsHeader = connect(mapProps, mapDispatch)(QuoteDetailsHeader);

export default (props) => (
  <InproProvider>
    <QuoteDetailsHeader {...props}/>
  </InproProvider>
);