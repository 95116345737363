import {
  UPDATE_DOCUMENTS_SEARCH_PAGE_SETTINGS,
} from './../../actions/types';

const defaultState = {
  query: '',
  dirty: false,
  page: 1,
  total: 0,
  mainCategoryList: [],
  subCategoryList: [],
  productTypesList: [],
  documentTypeList: [],
  languageTypeList: [],
  mainCategorySelectedOption: [],
  subCategorySelectedOptions: [],
  productTypesSelectedOptions: [],
  documentTypeSelectedOptions: [],
  languageTypeSelectedOptions: [],
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case UPDATE_DOCUMENTS_SEARCH_PAGE_SETTINGS:
      return Object.assign({}, state, action.settings);
    default:
      return state;
  }
}