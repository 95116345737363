import React, { Component } from 'react';

class AddToCartButton extends Component {
  constructor(props) {
    super(props);
  }

  onButtonClick = (e) => {
    e.preventDefault();
  
    const {
      lineItemId,
      quantity,
      onAddClick,
      disabled,
    } = this.props;

    if (!disabled) {
      onAddClick(lineItemId, quantity);
    }

  }

  render() {

    const {
      disabled,
    } = this.props;

    return (
      <button 
      href="#" 
      className="cart-sku-content__add-to-cart-button"
      onClick={this.onButtonClick}
      disabled={disabled}>
        Add to Cart
      </button>
    );
  }
}

export default AddToCartButton;